import { createReducer, on } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import * as fromAuthActions from './auth.actions';
import * as fromAuthState from './auth.state';

const isAuthenticated = localStorage.getItem(`${environment.APP_PREFIX}auth`);

export const initialState: fromAuthState.IAuthState = {
  isAuthenticated: !!isAuthenticated,
  isResetMailSent: false
};

export const authReducer = createReducer(
  initialState,
  on(fromAuthActions.loginComplete, (state, { payload }) => {
    return {
      ...state,
      isAuthenticated: true,
      tokenData: { ...payload.tokenData }
    };
  }),
  on(fromAuthActions.loginError, (state) => {
    return { ...state, isAuthenticated: false, tokenData: undefined };
  }),
  on(fromAuthActions.unsetForgotUser, (state) => {
    return { ...state, isResetMailSent: false };
  }),
  on(fromAuthActions.loginForgotUserComplete, (state) => {
    return { ...state, isResetMailSent: true };
  }),
  on(fromAuthActions.resetComplete, (state) => {
    return { ...state, isReset: true, isResetMailSent: false };
  }),
  on(fromAuthActions.clearReset, (state) => {
    return { ...state, isReset: false };
  }),
  on(fromAuthActions.logoutComplete, (state) => {
    return { ...initialState, isAuthenticated: false, tokenData: undefined };
  })
);

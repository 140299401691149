import { MdcDialog } from '@angular-mdc/web/dialog';
import { isPlatformBrowser, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { CoreConfigService } from '@core/config/config.service';
import { ICoreConfig } from '@core/interfaces/config.interface';
import { CoreLoadingScreenService } from '@core/services';
import { ResizeService } from '@core/services/resize.service';
import { Store } from '@ngrx/store';
import { filter, map, Subscription } from 'rxjs';
import * as fromAuthStore from 'src/app/auth/store';
import { Destinations } from './models/destinations.model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
// changeDetection: ChangeDetectionStrategy.OnPush
export class LayoutComponent implements OnDestroy, AfterViewInit {
  @ViewChild('wrapper', { static: true })
  private _wrapper!: ElementRef<HTMLElement>;

  public config!: ICoreConfig;
  private _configSub!: Subscription;
  private _dialogSub!: Subscription;
  private _swUpdateSub!: Subscription;

  public route!: string;
  title = 'intern';

  public appData = { version: '', changelog: '' };

  public get isOnLogin(): boolean {
    return this.route === '/account/login' || this.route === undefined;
  }

  @ViewChild('newVersionDialog', { static: false }) private _newVersionDialog!: MdcDialog;
  @ViewChild('container', { static: true }) public container!: ElementRef<HTMLElement>;

  constructor(
    public loadingScreenService: CoreLoadingScreenService,
    public resizeService: ResizeService,
    private _cdRef: ChangeDetectorRef,
    private _coreConfigService: CoreConfigService,
    private _location: Location,
    private _router: Router,
    private _title: Title,
    private _swUpdate: SwUpdate,
    private _storeAuth: Store<fromAuthStore.IAuthStore>,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this._configSub = this._coreConfigService.config.subscribe((config: ICoreConfig) => {
      this.config = config;
    });
    if (!isPlatformBrowser(this._platformId)) return;
    this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this._coreConfigService.onSearch.next('');
        let route: string;
        if (this._router.url.indexOf('account/login') === -1)
          window.scrollTo({
            top: 0
          });
        if (this._location.path() !== '') {
          route = this._location.path();
          if (route.indexOf('/vorschau') > -1) route = '/splash/vorschau';
          if (route.indexOf('splash/editor') > -1 && route.indexOf('/vorschau') === -1)
            route = '/splash/editor';
          if (route.indexOf('bestellen/abschluss') > -1) route = '/bestellen/abschluss';
          if (route.indexOf('account/reset') > -1) route = '/account/reset';
        } else {
          route = 'Home';
        }
        this.route = route;

        if (this.route.indexOf('/kunde/') > -1) return;

        this._title.setTitle(
          Destinations.titles[route] ? Destinations.titles[route] : Destinations.titleBase
        );
        this._cdRef.detectChanges();
      }
    });

    if (!this._swUpdate.isEnabled) return;
    this._swUpdateSub = this._swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion
        }))
      )
      .subscribe((event) => {
        this.appData = event.available.appData as {
          version: string;
          changelog: string;
        };
        this._newVersionDialog.open();
      });
  }

  public updateApp(): void {
    if (!this._swUpdate.isEnabled) {
      location.reload();
      return;
    }
    this._swUpdate.activateUpdate().then(async () => {
      this._storeAuth.dispatch(fromAuthStore.resetStores());

      const registrations = await navigator.serviceWorker.getRegistrations();

      for (const registration of registrations) {
        registration.unregister();
      }
      location.reload();
    });
  }

  ngAfterViewInit(): void {
    this._dialogSub = this._newVersionDialog.afterClosed.subscribe(() => {
      this.updateApp();
    });
  }

  ngOnDestroy(): void {
    this._dialogSub.unsubscribe();
    this._configSub.unsubscribe();
    if (this._swUpdateSub) this._swUpdateSub.unsubscribe();
  }
}

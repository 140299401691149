import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  HostBinding,
  ViewEncapsulation
} from '@angular/core';

@Directive({
  selector: '[mdcSelectIcon]',
  exportAs: 'mdcSelectIcon'
})
export class MdcSelectIcon {
  @HostBinding('class') class = 'mdc-select__icon';
}

@Component({
  selector: 'mdc-select-helper',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcSelectHelperText {
  @HostBinding('class') class = 'mdc-select-helper-text';
}

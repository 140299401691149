export const tableStateName = 'table';

export const tableState: ITableStoreState = {};

export interface ITableStore {
  table: ITableStoreState;
}

export interface ITableStoreState {
  [key: string]: ITableState;
}

export interface ITableState {
  enabledColumns: number[];
  enabledConditions: number[];
  presetColumns: ICustomColumn[];
  zoom: number;
  length: number;
}

export interface ITableStatePartial {
  enabledColumns?: number[];
  presetColumns?: ICustomColumn[];
}

export interface ITableSearch {
  filter: any[];
  limit: number;
  order: string;
  page: number;
  search: string;
}

export interface ICustomColumn {
  name: string;
  isDefault: boolean;
  enabledColumns: number[];
}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { MDCTopAppBar, MDCTopAppBarBaseFoundation } from '@material/top-app-bar';
import { MdcTabBar } from '../tab-bar';

@Component({
  selector: 'mdc-top-app-bar, [mdc-top-app-bar]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./top-app-bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MdcTopAppBar implements AfterViewInit, OnDestroy {
  @HostBinding('class') class = 'mdc-top-app-bar';

  private _mdcTopAppBar!: MDCTopAppBar;
  private _platform = inject(Platform);

  @HostBinding('class.mdc-top-app-bar--dense')
  @Input()
  get dense(): boolean {
    return this._dense;
  }
  set dense(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._dense) this._dense = newValue;
  }
  private _dense: boolean = false;

  @Input()
  get short(): boolean {
    return this._short;
  }
  set short(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._short) this._short = newValue;
  }
  private _short: boolean = false;

  @HostBinding('class.mdc-top-app-bar--short')
  @HostBinding('class.mdc-top-app-bar--short-collapsed')
  @Input()
  get shortCollapsed(): boolean {
    return this._shortCollapsed;
  }
  set shortCollapsed(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._shortCollapsed) this._shortCollapsed = newValue;
  }
  private _shortCollapsed: boolean = false;

  @HostBinding('class.mdc-top-app-bar--fixed')
  @Input()
  get fixed(): boolean {
    return this._fixed;
  }
  set fixed(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._fixed) this._fixed = newValue;
  }
  private _fixed: boolean = false;

  @HostBinding('class.mdc-top-app-bar--prominent')
  @Input()
  get prominent(): boolean {
    return this._prominent;
  }
  set prominent(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._prominent) this._prominent = newValue;
  }
  private _prominent: boolean = false;

  @HostBinding('class.cd-layout--top-app-bar--floating')
  @Input()
  get floating(): boolean {
    return this._floating;
  }
  set floating(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._floating === newValue) return;
    this._floating = newValue;
  }

  private _floating: boolean = false;

  @HostBinding('class.cd-layout--top-app-bar--static')
  @Input()
  get static(): boolean {
    return this._static;
  }
  set static(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._static === newValue) return;
    this._static = newValue;
  }

  private _static: boolean = false;

  @Input()
  get scrollTarget(): any {
    return this._scrollTarget;
  }
  set scrollTarget(target: any) {
    if (target === this._scrollTarget) return;
    this._scrollTarget = target ? target : this._platform?.isBrowser ? window : undefined;
    if (this._mdcTopAppBar) this.setScrollTarget(this._scrollTarget);
  }
  private _scrollTarget: any = this._platform?.isBrowser ? this.scrollTarget || window : undefined;

  public get root(): Element {
    return this._mdcTopAppBar.root;
  }

  @Output()
  readonly naviSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(public elementRef: ElementRef) {}

  //#region Lifecycle
  ngAfterViewInit(): void {
    if (this.short) this.elementRef.nativeElement.classList.add('mdc-top-app-bar--short');
    this._mdcTopAppBar = MDCTopAppBar.attachTo(this.elementRef.nativeElement);
    this.setScrollTarget(this.scrollTarget);

    this._mdcTopAppBar.listen('MDCTopAppBar:nav', () => {
      this.naviSelected.emit();
    });
  }

  ngOnDestroy(): void {
    if (this._mdcTopAppBar) this._mdcTopAppBar.destroy();
  }
  //#endregion

  //#region MDCTopAppBar methodes
  public getDefaultFoundation(): MDCTopAppBarBaseFoundation {
    return this._mdcTopAppBar.getDefaultFoundation();
  }

  public setScrollTarget(target: EventTarget): void {
    this._mdcTopAppBar.setScrollTarget(target);
  }
  //#endregion
}

@Component({
  selector: 'mdc-top-app-bar-section, [mdcTopAppBarSection]',
  template: ` <ng-content></ng-content>
    <span class="mdc-top-app-bar__title" *ngIf="title">{{ title }}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MdcTopAppBarSection implements AfterContentInit {
  @HostBinding('attr.role') role = 'toolbar';
  @HostBinding('class') class = 'mdc-top-app-bar__section';
  @HostBinding('class.mdc-top-app-bar__section--align-start') alignStart!: boolean;
  @HostBinding('class.mdc-top-app-bar__section--align-end') alignEnd!: boolean;

  @Input() title?: string;
  @Input() align?: string;
  @ContentChild(MdcTabBar) private _mdcTabBar!: MdcTabBar;

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  ngAfterContentInit(): void {
    if (this._mdcTabBar) this.elementRef.nativeElement.style.padding = '0px';
    if (this.align === 'start') this.alignStart = true;
    if (this.align === 'end') this.alignEnd = true;
  }
}

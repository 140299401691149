import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injectable,
  Injector
} from '@angular/core';
import { take } from 'rxjs/operators';
import { ISnackbarConfig, MdcSnackbarComponent } from './snackbar.component';

@Injectable({ providedIn: 'root' })
export class MdcSnackbar {
  private _openedSnackbars: MdcSnackbarComponent[] = [];
  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _defaultInjector: Injector,
    private _appRef: ApplicationRef
  ) {}

  public open(message: string = '', config: Partial<ISnackbarConfig> = {}): MdcSnackbarComponent {
    this._openedSnackbars.forEach((snackbar, index) => {
      if (snackbar.config.timeoutMs === -1) {
        snackbar.elementRef.nativeElement.style.display = 'none';
      } else {
        snackbar.close('destroy');
        this._openedSnackbars.splice(index, 1);
      }
    });

    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(MdcSnackbarComponent);

    const componentRef = componentFactory.create(this._defaultInjector);
    const dom = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    componentRef.instance.config = config as ISnackbarConfig;
    componentRef.instance.config.message = message;

    document.body.appendChild(dom);
    this._appRef.attachView(componentRef.hostView);

    componentRef.instance.afterClosed.pipe(take(1)).subscribe((value: string) => {
      this._openedSnackbars.forEach((snackbar) => {
        if (snackbar.config.timeoutMs === -1)
          snackbar.elementRef.nativeElement.style.display = 'flex';
      });
      this._appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    });
    this._openedSnackbars.push(componentRef.instance);
    return componentRef.instance;
  }
}

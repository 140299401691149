import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  Corner,
  MDCMenuDistance,
  MDCMenuSurface,
  MDCMenuSurfaceFoundation
} from '@material/menu-surface';

export type AnchorCorners =
  | 'TOP_LEFT'
  | 'BOTTOM_LEFT'
  | 'TOP_RIGHT'
  | 'BOTTOM_RIGHT'
  | 'TOP_START'
  | 'BOTTOM_START'
  | 'TOP_END'
  | 'BOTTOM_END';

@Component({
  selector: 'mdc-menu-surface',
  template: '<ng-content></ng-content>',
  styleUrls: ['menu-surface.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcMenuSurface implements AfterViewInit {
  @HostBinding('class') class = 'mdc-menu-surface';

  private _mdcMenuSurface!: MDCMenuSurface;

  private _initialized = false;

  @Input()
  get open(): boolean {
    return this._open;
  }
  set open(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue === this._open) return;
    this._open = coerceBooleanProperty(value);
    if (this._open) this._mdcMenuSurface.open();
    else this._mdcMenuSurface.close();
  }
  private _open = false;

  @Input()
  get anchorElement(): HTMLElement {
    return this._anchorElement;
  }
  set anchorElement(value: HTMLElement) {
    this._anchorElement = value;
  }
  private _anchorElement!: HTMLElement;

  @Input()
  get hoistToBody(): boolean {
    return this._hoistToBody;
  }
  set hoistToBody(value: boolean) {
    this._hoistToBody = coerceBooleanProperty(value);
  }
  private _hoistToBody = false;

  @Input()
  get anchorCorner(): AnchorCorners {
    return this._anchorCorner;
  }
  set anchorCorner(value: AnchorCorners) {
    this._anchorCorner = value;
    if (this._initialized) this.setAnchorCorner(Corner[value]);
  }
  private _anchorCorner: AnchorCorners = 'TOP_LEFT';

  @Input()
  get quickOpen(): boolean {
    return this._quickOpen;
  }
  set quickOpen(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._initialized && newValue !== this._quickOpen)
      this._mdcMenuSurface.quickOpen = newValue;

    this._quickOpen = newValue;
  }
  private _quickOpen!: boolean;

  @Input()
  get anchorMargin(): AnchorMargin {
    return this._anchorMargin;
  }
  set anchorMargin(value: AnchorMargin) {
    this._anchorMargin = value;
    if (this._initialized) this.setAnchorMargin(this._anchorMargin);
  }
  private _anchorMargin: AnchorMargin = {};

  @Input()
  get fixed(): boolean {
    return this._fixed;
  }
  set fixed(value: boolean) {
    this._fixed = coerceBooleanProperty(value);
    if (this._fixed) this.elementRef.nativeElement.classList.add('mdc-menu-surface--fixed');
    else this.elementRef.nativeElement.classList.remove('mdc-menu-surface--fixed');

    if (this._initialized) this.setFixedPosition(this._fixed);
  }
  private _fixed: boolean = false;

  @Output()
  public closed: EventEmitter<void> = new EventEmitter();

  constructor(public elementRef: ElementRef<HTMLElement>, private _platform: Platform) {}

  ngAfterViewInit(): void {
    this._mdcMenuSurface = MDCMenuSurface.attachTo(this.elementRef.nativeElement);
    this._initialized = true;
    this._mdcMenuSurface.listen('MDCMenuSurface:closed', (event: any) => {
      this.open = false;
      this.closed.emit();
    });
    this._mdcMenuSurface.anchorElement = this.anchorElement;
    if (this.hoistToBody) this.setHoistToBody();

    this.setAnchorMargin(this._anchorMargin);
    this.setFixedPosition(this._fixed);
    this._mdcMenuSurface.quickOpen = this.quickOpen;
    this.setAnchorCorner(Corner[this.anchorCorner]);
  }

  destroy(): void {
    this._mdcMenuSurface.destroy();
  }

  private setHoistToBody(): void {
    if (!this._platform.isBrowser) return;

    const parentEl = this.elementRef.nativeElement.parentElement;
    if (!parentEl) return;
    document.body!.appendChild(parentEl.removeChild(this.elementRef.nativeElement));
    this._mdcMenuSurface.setIsHoisted(true);
  }

  get isOpen(): boolean {
    return this._mdcMenuSurface.isOpen();
  }

  close(skipRestoreFocus?: boolean): void {
    this._mdcMenuSurface.close(skipRestoreFocus);
  }

  setIsHoisted(isHoisted: boolean): void {
    this._mdcMenuSurface.setIsHoisted(isHoisted);
  }
  setMenuSurfaceAnchorElement(element: Element): void {
    this._mdcMenuSurface.setMenuSurfaceAnchorElement(element);
  }

  setFixedPosition(isFixed: boolean): void {
    this._mdcMenuSurface.setFixedPosition(isFixed);
  }

  setAbsolutePosition(x: number, y: number): void {
    this._mdcMenuSurface.setAbsolutePosition(x, y);
  }

  setAnchorCorner(corner: Corner): void {
    this._mdcMenuSurface.setAnchorCorner(corner);
  }
  setAnchorMargin(margin: Partial<MDCMenuDistance>): void {
    this._mdcMenuSurface.setAnchorMargin(margin);
  }
  getDefaultFoundation(): MDCMenuSurfaceFoundation {
    return this._mdcMenuSurface.getDefaultFoundation();
  }
}

interface AnchorMargin {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

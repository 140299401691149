import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
// import { IContact } from 'src/app/main/utils';
import { ITokenDataLogin } from '../interfaces/token.interface';

export const login = createAction(
  '[Auth] - Login',
  props<{ payload: { username: string; password: string; email?: string } }>()
);
export const loginComplete = createAction(
  '[Auth] - Login Complete',
  props<{ payload: { tokenData: ITokenDataLogin } }>()
);
export const loginError = createAction(
  '[Auth] - Login Error',
  props<{ payload: { error: HttpErrorResponse } }>()
);
export const logout = createAction('[Auth] - Logout');
export const logoutComplete = createAction('[Auth] - Logout Complete');

export const loginForgotUser = createAction(
  '[Auth] - Forgot user',
  props<{ payload: { email: string; username?: string } }>()
);
export const loginForgotUserComplete = createAction(
  '[Auth] - Forgot user Success',
  props<{ payload: { message: string } }>()
);

export const reset = createAction(
  '[Auth] - Reset',
  props<{
    payload: {
      password: string;
      passwordRepeat: string;
      token: string;
    };
  }>()
);
export const resetComplete = createAction(
  '[Auth] - Reset Complete',
  props<{
    payload: {
      message: string;
    };
  }>()
);
export const unsetForgotUser = createAction('[Auth] - Unset Reset');
export const clearReset = createAction('[Auth] - Clear Reset');

export const redirect = createAction(
  '[Auth] - Redirect',
  props<{ payload: { path: string; reload?: boolean } }>()
);

export const resetStores = createAction('[Auth] - Reset Stores');

<div
  class="mdc-select__anchor"
  role="button"
  aria-haspopup="listbox"
  aria-disabled="disabled"
  aria-required="required"
  aria-expanded="false"
  aria-labelledby="placeholder"
  [ngClass]="{ 'mdc-select--small': small }"
>
  <span class="mdc-select__ripple" *ngIf="!outlined"></span>
  <span id="demo-label" class="mdc-floating-label" *ngIf="!outlined && placeholder">{{
    placeholder
  }}</span>
  <span class="mdc-notched-outline" *ngIf="outlined">
    <span class="mdc-notched-outline__leading"></span>
    <span class="mdc-notched-outline__notch">
      <span
        id="outlined-select-label"
        class="mdc-floating-label"
        [ngClass]="{ small }"
        *ngIf="placeholder"
        >{{ placeholder }}</span
      >
    </span>
    <span class="mdc-notched-outline__trailing"></span>
  </span>
  <ng-content select="mdc-icon"></ng-content>
  <span class="mdc-select__selected-text-container">
    <span id="demo-selected-text" class="mdc-select__selected-text"></span>
  </span>
  <span class="mdc-select__dropdown-icon">
    <svg class="mdc-select__dropdown-icon-graphic" viewBox="7 10 10 5" focusable="false">
      <polygon
        class="mdc-select__dropdown-icon-inactive"
        stroke="none"
        fill-rule="evenodd"
        points="7 10 12 15 17 10"
      ></polygon>
      <polygon
        class="mdc-select__dropdown-icon-active"
        stroke="none"
        fill-rule="evenodd"
        points="7 15 12 10 17 15"
      ></polygon>
    </svg>
  </span>
  <span class="mdc-line-ripple" *ngIf="!outlined"></span>
</div>
<ng-content select="mdc-menu"></ng-content>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MdcIconButtonModule } from '../icon-button';
import { MdcDrawer, MdcDrawerHeader } from './drawer';
import {
  MdcDrawerAppContent,
  MdcDrawerContent,
  MdcDrawerSubtitle,
  MdcDrawerTitle
} from './drawer-directives';

const DRAWER_DECLARATIONS = [
  MdcDrawer,
  MdcDrawerAppContent,
  MdcDrawerContent,
  MdcDrawerHeader,
  MdcDrawerSubtitle,
  MdcDrawerTitle
];

@NgModule({
  imports: [CommonModule, MdcIconButtonModule],
  exports: [DRAWER_DECLARATIONS],
  declarations: [DRAWER_DECLARATIONS]
})
export class MdcDrawerModule {}

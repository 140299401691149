<div #pageWrapper id="pageWrapper" class="cd-layout--page-wrapper">
  <app-menu
    style="z-index: 5"
    *ngIf="
      !config.layout.menu.hidden &&
      resizeService.windowWidth > resizeService.breakpoint.mobile &&
      resizeService.windowHeight > resizeService.breakpoint.mobile
    "
  ></app-menu>
  <div
    #container
    class="cd-layout--content-wrapper cd-layout--width-100"
    style="align-self: flex-start"
  >
    <app-navbar
      #navbar
      *ngIf="!isOnLogin && config && !config.layout.search.hidden"
      [ngClass]="{
        'cd-layout--hidden-menu':
          !config.layout.menu.hidden &&
          resizeService.windowWidth < resizeService.breakpoint.mobile &&
          resizeService.windowHeight < resizeService.breakpoint.mobile,
        isMobile: resizeService.isMobile
      }"
    ></app-navbar>
    <main
      #wrapper
      class="main-wrapper"
      [ngClass]="{
        'cd-layout--margin-top-0': config.layout.search.hidden,
        'cd-layout--margin-bottom-60':
          (resizeService.windowWidth <= resizeService.breakpoint.mobile ||
            resizeService.windowHeight <= resizeService.breakpoint.mobile) &&
          !config.layout.menu.hidden &&
          !config.queries.isIphone,
        'cd-layout--margin-bottom-74': config.queries.isIphone && !config.layout.menu.hidden
      }"
      [ngStyle]="{
        'max-width': config.layout.content.maxWidth,
        'min-width': config.layout.content.minWidth
      }"
    >
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
  <app-bottom-menu
    id="bottomMenu"
    *ngIf="
      !config.layout.menu.hidden &&
      (resizeService.windowWidth <= resizeService.breakpoint.mobile ||
        resizeService.windowHeight <= resizeService.breakpoint.mobile)
    "
  ></app-bottom-menu>
</div>

<mdc-dialog #newVersionDialog>
  <mdc-dialog-title>Neue Version verfügbar!</mdc-dialog-title>
  <mdc-dialog-content>
    <p>
      Es ist eine neue Version unserer Online-Verwaltung verfügbar. Um fortzufahren bitte
      aktualisieren.
    </p>
    <br />
    <p class="cd-layout--font-size-14">App Version: {{ appData.version }}</p>
    <br />
    <p class="cd-layout--font-size-14">Was hat sich geändert?</p>
    <p class="cd-layout--font-size-14" [innerHTML]="appData.changelog"></p>
  </mdc-dialog-content>
  <mdc-dialog-actions>
    <button mdc-button mdcDialogAction="accept">Aktualisieren</button>
  </mdc-dialog-actions>
</mdc-dialog>

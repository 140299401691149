import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'mdc-icon, [mdcIcon]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./icon.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MdcIcon implements AfterContentInit {
  @HostBinding('class.material-icons') mdcIcons!: boolean;

  @HostBinding('class.cd-icons')
  @Input()
  get custom(): boolean {
    return this._custom;
  }
  set custom(value: boolean) {
    this._custom = coerceBooleanProperty(value);
  }
  private _custom: boolean = false;

  @HostBinding('class.material-icons-outlined')
  @Input()
  get outlined(): boolean {
    return this._outlined;
  }
  set outlined(value: boolean) {
    this._outlined = coerceBooleanProperty(value);
  }
  private _outlined: boolean = false;

  @HostBinding('class.material-icons-two-tone')
  @Input()
  get twoTone(): boolean {
    return this._twoTone;
  }
  set twoTone(value: boolean) {
    this._twoTone = coerceBooleanProperty(value);
  }
  private _twoTone: boolean = false;

  @HostBinding('class.material-icons-round')
  @Input()
  get round(): boolean {
    return this._round;
  }
  set round(value: boolean) {
    this._round = coerceBooleanProperty(value);
  }
  private _round: boolean = false;

  @HostBinding('class.material-icons-sharp')
  @Input()
  get sharp(): boolean {
    return this._sharp;
  }
  set sharp(value: boolean) {
    this._sharp = coerceBooleanProperty(value);
  }
  private _sharp: boolean = false;

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  ngAfterContentInit(): void {
    if (!this.custom && !this.outlined && !this.twoTone && !this.round && !this.sharp)
      this.mdcIcons = true;
  }
}

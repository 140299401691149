import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import * as fromAuthSelectors from 'src/app/auth/store/auth.selectors';
import * as fromAuthState from 'src/app/auth/store/auth.state';
import { ITokenData } from '../interfaces/token.interface';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  private _router = inject(Router);
  private _store = inject(Store<fromAuthState.IAuthState>);

  constructor() {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._store.select(fromAuthSelectors.selectTokenData).pipe(
      switchMap((tokenData) => of(this.checkCanActivate(tokenData!, state))),
      take(1)
    );
  }

  private checkCanActivate(tokenData: ITokenData, state: RouterStateSnapshot): boolean {
    const currentRoute = decodeURIComponent(state.url);
    if (currentRoute === '/dev' && !tokenData?.role_type.includes('superadmin')) {
      this._router.navigate(['/uebersicht']);
      return false;
    }
    if (currentRoute === '/kosten' && !tokenData?.role_type.includes('admin')) {
      this._router.navigate(['/uebersicht']);
      return false;
    }
    return true;
  }
}

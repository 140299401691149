export class ModelBase {
  [key: string]: any;

  constructor(modelName: string, allowedKeys: string[], data: any) {
    this.setProperties(data, allowedKeys);
    this.logNotAllowedKeys(data, modelName, allowedKeys);
  }

  private setProperties(data: any, allowedKeys: string[]): void {
    // set properties
    allowedKeys.forEach((key) => {
      this[key] = data[key];
    });
  }

  private logNotAllowedKeys(data: any, modelName: string, allowedKeys: string[]): void {
    // log keys that aren't allowed to be set
    const notAllowedKeys = Object.keys(data).filter((key) => !allowedKeys.includes(key));

    if (notAllowedKeys.length) {
      console.log(`--- ${modelName} ---`);
      notAllowedKeys.forEach((key) => {
        console.log(`key: ${key} | value: ${data[key]}`);
      });
      console.log(`----${Array(modelName.length).join('-')}----`);
    }
  }
}

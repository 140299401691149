import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MdcBannerComponent } from './banner';

@NgModule({
    imports: [CommonModule],
    exports: [MdcBannerComponent],
    declarations: [MdcBannerComponent]
})
export class MdcBannerModule {}

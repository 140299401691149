import { MdcMenuSurfaceModule } from '@angular-mdc/web/menu-surface';
import { NgModule } from '@angular/core';
import { MdcMenu } from './menu';
import { MDCMenuIcon, MdcMenuSelectionGroup, MdcMenuSelectionGroupIcon } from './menu-directives';

const MENU_DECLARATIONS = [MdcMenu, MdcMenuSelectionGroup, MdcMenuSelectionGroupIcon, MDCMenuIcon];

@NgModule({
  imports: [MdcMenuSurfaceModule],
  exports: [...MENU_DECLARATIONS, MdcMenuSurfaceModule],
  declarations: [MENU_DECLARATIONS]
})
export class MdcMenuModule {}

import { Chat } from 'src/app/main/utils/enums/chat.enum';

export const profileStateName = 'user';

export const profileState: IProfileState = {
  email: '',
  firstName: '',
  lastName: '',
  nickname: '',
  role: '',
  pictureUrl: '',
  selectedTheme: 'system',
  chatLayout: 'grid',
  fabSize: { h: 0, w: 0 },
  fabOpen: false,
  expansionPanels: {},
  menuCollapse: false,
  themeOptions: {
    disableBoxShadow: false,
    disableBorderRadius: false
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _id: ''
};

export interface IProfileState {
  [key: string]: any;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  role: string;
  pictureUrl: string;
  chatLayout: Chat.TLayout;
  fabSize: { h: number; w: number };
  fabOpen: boolean;
  expansionPanels: { [key: string]: boolean };
  selectedTheme: 'light' | 'dark' | 'system';
  menuCollapse: boolean;
  themeOptions: {
    [key: string]: boolean;
    disableBorderRadius: boolean;
    disableBoxShadow: boolean;
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _id: string;
}

export interface IProfileStore {
  user: IProfileState;
}

import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private _availableThemes: { [key: string]: any } = {
    lightSorglos: 'themeSi_light.css',
    lightGodspot: 'themeGs_light.css',
    darkGodspot: 'themeGs_dark.css',
    darkSorglos: 'themeSi_dark.css'
  };

  public activeTheme!: IActiveTheme['name'];
  public isLoaded = new BehaviorSubject(false);

  public get isDarkMode(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  public change: EventEmitter<'light' | 'dark'> = new EventEmitter();

  constructor() {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (change) => {
      if (change.matches) this.change.emit('dark');
      else this.change.emit('light');
    });
  }

  public setActiveTheme(theme: ITheme['name'], useMat = false): void {
    if (this.activeTheme === this._availableThemes[theme]) return;
    this.activeTheme = this._availableThemes[theme];
    if (theme.startsWith('light')) this.change.emit('light');
    else this.change.emit('dark');

    // this is only temp while we are running MDC and Angular Material parallel
    if (useMat) {
      const matThemeLink = document.getElementById('mat-theme-link');
      if (matThemeLink) {
        matThemeLink.setAttribute('href', 'mat_' + this.activeTheme);
      } else {
        document.getElementsByTagName('head')[0].appendChild(
          Object.assign(document.createElement('link'), {
            id: 'mat-theme-link',
            rel: 'stylesheet',
            href: 'mat_' + this.activeTheme
          })
        );
      }
    }

    const themeLink = document.getElementById('theme-link');
    if (themeLink) {
      themeLink.setAttribute('href', this.activeTheme);
    } else {
      document
        .getElementsByTagName('head')[0]
        .appendChild(
          Object.assign(document.createElement('link'), {
            id: 'theme-link',
            rel: 'stylesheet',
            href: this.activeTheme
          })
        )
        .addEventListener('load', () => {
          this.isLoaded.next(true);
        });
    }
  }
}

export interface ITheme {
  name: 'lightSorglos' | 'lightGodspot' | 'darkGodspot' | 'darkSorglos';
}
interface IActiveTheme {
  name: 'themeSi_light.css' | 'themeGs_light.css' | 'themeGs_dark.css' | 'themeSi_dark.css';
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MdcList,
  MdcListGroup,
  MdcListGroupSubheader,
  MdcListItem,
  MdcListItemGraphic,
  MdcListItemMeta,
  MdcListItemSecondary,
  MdcListItemText
} from './list';
import { MdcListDivider } from './list-divider';

const LIST_DECLARATIONS = [
  MdcList,
  MdcListDivider,
  MdcListGroup,
  MdcListGroupSubheader,
  MdcListItem,
  MdcListItemGraphic,
  MdcListItemMeta,
  MdcListItemSecondary,
  MdcListItemText
];

@NgModule({
  imports: [CommonModule],
  exports: [LIST_DECLARATIONS],
  declarations: [LIST_DECLARATIONS]
})
export class MdcListModule {}

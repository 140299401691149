import { MdcSnackbar } from '@angular-mdc/web/snackbar';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { IProfileStore, profileReset } from 'src/app/main/store/profile';
import { AuthenticationService } from '../auth.service';
import * as fromAuthActions from './auth.actions';
import * as fromAuthState from './auth.state';

@Injectable()
export class AuthEffects {
  [key: string]: any;

  public login$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.login),
      switchMap(({ payload }) =>
        this._authService.loginUser(payload.username, payload.password, payload.email).pipe(
          take(1),
          map((data) => fromAuthActions.loginComplete({ payload: { tokenData: data } })),
          catchError((error: any) => of(fromAuthActions.loginError({ payload: { error: error } })))
        )
      )
    )
  );

  public loginComplete$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(fromAuthActions.loginComplete),
        tap(() => {
          this._router.navigate(['/']);
        })
      );
    },
    { dispatch: false }
  );

  public loginForgotUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.loginForgotUser),
      switchMap(({ payload }) =>
        this._authService.forgotUser(payload.email, payload.username).pipe(
          take(1),
          map((message: string) => {
            return fromAuthActions.loginForgotUserComplete({ payload: { message: message } });
          }),
          catchError((error: any) => of(fromAuthActions.loginError({ payload: { error: error } })))
        )
      )
    )
  );

  public loginError$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(fromAuthActions.loginError),
        tap(({ payload }) => {
          this._snackbar.open((payload.error.error as string).replace('<br />', ' - '));
        })
      );
    },
    { dispatch: false }
  );

  public reset$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.reset),
      switchMap(({ payload }) =>
        this._authService
          .resetPassword(payload.password, payload.passwordRepeat, payload.token)
          .pipe(
            take(1),
            map((message: string) => {
              return fromAuthActions.resetComplete({ payload: { message: message } });
            }),
            catchError((error: any) =>
              of(fromAuthActions.loginError({ payload: { error: error } }))
            )
          )
      )
    )
  );

  public resetComplete$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(fromAuthActions.resetComplete),
        tap(() => {
          setTimeout(() => {
            this._storeAuth$.dispatch(fromAuthActions.clearReset());
            this._router.navigate(['/account/login']);
          }, 5000);
        })
      );
    },
    { dispatch: false }
  );

  public logout$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(fromAuthActions.logout),
      switchMap(() =>
        of(
          fromAuthActions.logoutComplete(),
          fromAuthActions.redirect({ payload: { path: '/account/login', reload: true } })
        )
      )
    );
  });

  public logoutComplete$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(fromAuthActions.logoutComplete),
        tap(() => {
          this.resetStores();
        })
      );
    },
    { dispatch: false }
  );

  public redirect$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(fromAuthActions.redirect),
        tap(({ payload }) => {
          this._router.navigate([payload.path]);
        })
      );
    },
    { dispatch: false }
  );

  private resetStores(): void {
    ([['Profile', profileReset]] as [string, () => TypedAction<any>][]).forEach((storeEntry) => {
      this['_store' + storeEntry[0]].dispatch(storeEntry[1]());
    });
  }

  constructor(
    private _actions$: Actions,
    private _storeAuth$: Store<fromAuthState.IAuthStore>,
    // These stores are used by resetStores but not called directly
    private _storeProfile: Store<IProfileStore>,
    // --------------
    private _router: Router,
    private _authService: AuthenticationService,
    private _snackbar: MdcSnackbar
  ) {}
}

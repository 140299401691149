import { createAction, props } from '@ngrx/store';

export const updateEnabledColumns = createAction(
  '[Table] Update Enabled Cols',
  props<{ columns: number[]; tableName: string }>()
);
export const updateEnabledConditions = createAction(
  '[Table] Update Enabled Conditions',
  props<{ conditions: number[]; tableName: string }>()
);

export const applyPreset = createAction(
  '[Table] Apply Preset',
  props<{ index: number; tableName: string }>()
);

export const storeAsPreset = createAction(
  '[Table] Store As Preset',
  props<{ tableName: string; name: string }>()
);

export const deletePreset = createAction(
  '[Table] Delete Preset',
  props<{ index: number; tableName: string }>()
);

export const setupTable = createAction(
  '[Table] Setup Table',
  props<{
    tableName: string;
    enabledColumns: number[];
    enabledConditions: number[];
    zoom: number;
    length: number;
  }>()
);

export const updateZoom = createAction(
  '[Table] Update Zoom',
  props<{ zoom: number; tableName: string }>()
);

export const updateLength = createAction(
  '[Table] Update Length',
  props<{ length: number; tableName: string }>()
);

<mdc-top-app-bar
  #navTopAppBar
  fixed
  [floating]="allowedFloating"
  class="mdc-theme--surface-bg"
  [mdcElevation]="2"
  [ngClass]="{
    'cd-layout--floating-navbar': allowedFloating,
    'cd-layout--floating-navbar-collapse': menuCollapse,
    'cd-layout--banner-animation': resizeService.isMobile
  }"
  [style.top]="
    isBannerOpen && !navTopAppBar.root.classList.contains('mdc-top-app-bar--fixed-scrolled')
      ? 'auto'
      : ''
  "
>
  <mdc-top-app-bar-row>
    <mdc-top-app-bar-section align="start" style="flex: auto">
      <mdc-icon
        class="mdc-theme--secondary cd-layout--margin__center-vertical cd-layout--padding-4 cd-layout--margin-right-4 cd-layout--margin-left-16"
        [ngStyle]="{ display: config.layout.search.allowed ? 'block' : 'none' }"
      >
        search</mdc-icon
      >
      <div mdcMenuSurfaceAnchor #menuAnchor class="cd-layout--width-100">
        <div
          class="cd-layout--search"
          [ngStyle]="{ display: config.layout.search.allowed ? 'flex' : 'none' }"
        >
          <input
            class="cd-layout--search-input cd-layout--no-outline cd-layout--width-100 cd-layout--text-overflow"
            name="search"
            type="text"
            autocomplete="off"
            [placeholder]="config.layout.search.placeholder"
            ngModel
            #searchInput
            #searchInputModel="ngModel"
          />
          <button
            mdc-icon-button
            small
            icon="clear"
            [ngClass]="{ 'cd-layout--margin-right-32': resizeService.isMobile }"
            *ngIf="searchInput.value.length > 0"
            (click)="searchInputModel.reset('')"
          ></button>
        </div>
        <mdc-menu
          #menuSurface
          [anchorElement]="menuAnchor"
          [anchorCorner]="'BOTTOM_START'"
          [anchorMargin]="{ top: 0, bottom: 8, left: 0, right: 0 }"
          class="cd-layout--margin-top-8"
          [ngClass]="{
            'cd-layout--none':
              (config.layout.search.allowedMenu ?? true) && navbarService.searchMenu.list.length < 1
          }"
        >
          <mdc-list>
            <mdc-list-item
              *ngFor="let listEl of navbarService.searchMenu.list"
              (click)="selectMenu(listEl)"
              [disabled]="
                (config.layout.search.allowedMenu ?? false) &&
                navbarService.searchMenu.list.length === 1
              "
            >
              {{ listEl }}
            </mdc-list-item>
            <ng-container *ngIf="navbarService.searchMenu.count">
              <li mdcListDivider></li>
              <mdc-list-item
                disabled
                style="width: auto; justify-content: center"
                class="cd-color-sub-text--on-surface cd-layout--font-size-12 cd-layout--height-12 cd-layout--flex__content-center"
              >
                <p class="cd-layout--version-menu">
                  {{ navbarService.searchMenu.list.length }} von
                  {{ navbarService.searchMenu.count }}
                </p>
              </mdc-list-item>
            </ng-container>
          </mdc-list>
        </mdc-menu>
      </div>
    </mdc-top-app-bar-section>
    <mdc-top-app-bar-section align="end">
      <div class="cd-layout--vertical-divider cd-layout--margin-right-12"></div>
      <div
        mdcMenuSurfaceAnchor
        #themeAnchor
        class="cd-layout--margin__center-vertical cd-layout--margin-right-12"
      >
        <button
          mdc-icon-button
          small
          (click)="themeMenu.open = !themeMenu.open"
          *ngIf="selectedTheme$ | async as selectedTheme"
        >
          <ng-container *ngFor="let theme of themes">
            <mdc-icon
              class="theme-icon mdc-theme--secondary"
              *ngIf="selectedTheme === theme.value"
              >{{ theme.icon }}</mdc-icon
            >
          </ng-container>
        </button>
        <mdc-menu
          #themeMenu
          [anchorElement]="themeAnchor"
          [anchorCorner]="'BOTTOM_START'"
          [anchorMargin]="{ top: 0, bottom: 16, left: 0, right: 0 }"
        >
          <mdc-list>
            <mdc-list-item (click)="setTheme(theme.value)" *ngFor="let theme of themes">
              <mdc-icon mdcListItemGraphic>{{ theme.icon }}</mdc-icon>
              {{ theme.label }}
            </mdc-list-item>
          </mdc-list>
        </mdc-menu>
      </div>

      <ng-container *ngIf="profileData$ | async as profileData">
        <div
          class="cd-layout--align-self-center cd-pointer cd-layout--padding-right-8"
          (click)="headerMenu.open = !headerMenu.open"
        >
          <p
            class="mdc-theme--secondary cd-layout--font-size-14 cd-layout--width-max cd-typography--strong cd-layout--line-height cd-layout--margin__left"
          >
            {{ profileData.nickname }}
          </p>
          <p
            class="mdc-theme--secondary cd-layout--font-size-11 cd-layout--width-max cd-typography--text-end-last cd-layout--margin__left"
          >
            {{ profileData.role }}
          </p>
        </div>

        <div
          mdcMenuSurfaceAnchor
          #headerAnchor
          class="cd-layout--margin__center-vertical cd-layout--margin-right-16"
        >
          <div
            class="cd-pointer cd-layout--profile-button"
            [style]="{
              background: 'url(' + profileData.pictureUrl + ') 50% 50% / cover no-repeat'
            }"
            (click)="headerMenu.open = !headerMenu.open"
          ></div>
          <mdc-menu
            #headerMenu
            fixed
            [anchorElement]="headerAnchor"
            [anchorCorner]="'BOTTOM_START'"
            [anchorMargin]="{ top: -64, bottom: 0, left: 0, right: 0 }"
          >
            <mdc-list>
              <ng-container *ngFor="let area of userIconMenu">
                <mdc-list-item [routerLink]="area.url">
                  <mdc-icon mdcMenuIcon>{{ area.icon }}</mdc-icon>
                  <mdc-list-item-text>{{ area.name }}</mdc-list-item-text>
                </mdc-list-item>
              </ng-container>
            </mdc-list>
          </mdc-menu>
        </div>
      </ng-container>
    </mdc-top-app-bar-section>
  </mdc-top-app-bar-row>
  <mdc-top-app-bar-row *ngIf="resizeService.isMobile && navbarService.tabs">
    <mdc-top-app-bar-section>
      <mdc-tab-bar
        [focusOnActivate]="false"
        [list]="navbarService.tabs"
        (activated)="navbarService.activeTabIndex = $event.index"
        [activeTabIndex]="navbarService.activeTabIndex"
      ></mdc-tab-bar>
    </mdc-top-app-bar-section>
  </mdc-top-app-bar-row>
</mdc-top-app-bar>

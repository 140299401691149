import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoreCommonModule } from '@core/core-common.module';
import { firstValueFrom } from 'rxjs';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-find-customer-dialog',
  standalone: true,
  imports: [
    CoreCommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  templateUrl: './find-customer-dialog.component.html',
  styleUrls: ['./find-customer-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FindCustomerDialogComponent {
  public customerNumber: string = '';

  public foundValidCustomer: boolean = false;
  public targetId!: string;

  constructor(
    public dialogRef: MatDialogRef<FindCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string },
    private _customerService: CustomerService,
    private _snackbar: MatSnackBar
  ) {}

  public async searchCustomer(): Promise<void> {
    const { status, message, body } = await firstValueFrom(
      this._customerService.getCustomerId(this.customerNumber)
    );
    this._snackbar.open(message, undefined, {
      duration: 3000
    });
    if (status === 'success') {
      this.foundValidCustomer = true;
      this.targetId = body!;
    } else {
      this.foundValidCustomer = false;
      this.targetId = '';
    }
  }
}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { MDCTabBar, MDCTabBarActivatedEventDetail, MDCTabBarFoundation } from '@material/tab-bar';

export type MdcTabScrollerAlignment = 'start' | 'center' | 'end' | null;

export interface MDCTabList {
  label?: string;
  icon?: string;
  icons?: string[];
  customIcon?: boolean;
  route?: string;
  hidden?: boolean;
}

@Directive({
  selector: 'tab-row-container, [tab-row-container]',
  exportAs: 'mdcTabRowContainer'
})
export class MdcTabRowContainer {
  @HostBinding('class') class = 'tab-row-container';
  @Output() public selectedTab: EventEmitter<number> = new EventEmitter<number>();

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Component({
  selector: '[mdcTabBar], mdc-tab-bar',
  templateUrl: './tab-bar.html',
  styleUrls: ['./tab-bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MdcTabBar implements AfterViewInit, OnDestroy {
  @HostBinding('class') class = 'mdc-tab-bar';
  @HostBinding('attr.role') role = 'tablist';
  // #region MDCTabBar properties
  @Input()
  set focusOnActivate(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcTabBar) this._mdcTabBar.focusOnActivate = newValue;
    else this._focusOnActivate = newValue;
  }

  @Input()
  set useAutomaticActivation(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcTabBar) this._mdcTabBar.useAutomaticActivation = newValue;
    else this._useAutomaticActivation = newValue;
  }
  //#endregion

  @Input()
  get scrollEnabled(): boolean {
    return this._scrollEnabled;
  }
  set scrollEnabled(value: boolean) {
    this._scrollEnabled = coerceBooleanProperty(value);
  }
  private _scrollEnabled: boolean = false;

  @Input()
  get onlyIcon(): boolean {
    return this._onlyIcon;
  }
  set onlyIcon(value: boolean) {
    this._onlyIcon = coerceBooleanProperty(value);
  }
  private _onlyIcon: boolean = false;

  public get root(): Element {
    return this._mdcTabBar.root;
  }

  @Input()
  get activeTabIndex(): number {
    return this._activeTabIndex;
  }
  set activeTabIndex(value: number) {
    if (this.activeTabIndex === value) return;
    this._activeTabIndex = value;
    if (this._mdcTabBar) this.activateTab(this._activeTabIndex);
    else this.changeDetectorRef.markForCheck();
  }

  @Input()
  get iconIndicator(): string | null {
    return this._iconIndicator;
  }
  set iconIndicator(value: string | null) {
    this._iconIndicator = value;
    this.showIconIndicator = coerceBooleanProperty(value);
  }

  @Input()
  get align(): MdcTabScrollerAlignment | null {
    return this._align;
  }
  set align(value: MdcTabScrollerAlignment | null) {
    this._align = value || 'start';
    this.changeDetectorRef.detectChanges();
  }

  @Input()
  get fixed(): boolean {
    return this._fixed;
  }
  set fixed(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._fixed) this._fixed = newValue;
  }

  @Input()
  get stacked(): boolean {
    return this._stacked;
  }
  set stacked(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue === this._stacked) return;
    this._stacked = newValue;
    this.changeDetectorRef.markForCheck();
  }

  @Input()
  get fade(): boolean {
    return this._fade;
  }
  set fade(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue === this._fade) return;
    this._fade = newValue;
    this.changeDetectorRef.markForCheck();
  }

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    public changeDetectorRef: ChangeDetectorRef,
    @Optional() private _mdcTabRowContainer: MdcTabRowContainer
  ) {}
  private _mdcTabBar!: MDCTabBar;

  @ViewChild('ScrollerArea', { static: true })
  private _tabScrollerArea!: ElementRef<HTMLElement>;
  @ViewChild('Scroller', { static: true })
  private _tabScroller!: ElementRef<HTMLElement>;

  @Input()
  public set list(v: MDCTabList[]) {
    if (this.list && v.length === this.list.length) return;
    this._list = v;
    if (this._mdcTabBar) {
      this.ngAfterViewInit();
    }
  }

  public get list(): MDCTabList[] {
    return this._list;
  }

  private _list!: MDCTabList[];

  private _focusOnActivate: boolean = true;
  private _useAutomaticActivation: boolean = true;
  private _activeTabIndex: number = 0;
  private _iconIndicator: string | null = null;

  public showIconIndicator!: boolean;
  private _align: MdcTabScrollerAlignment | null = null;
  private _fixed: boolean = false;

  private _stacked: boolean = false;
  private _fade: boolean = false;

  @Output()
  readonly activated: EventEmitter<MDCTabBarActivatedEventDetail> = new EventEmitter<MDCTabBarActivatedEventDetail>();

  @ViewChildren('mdcTab') public mdcTabs!: QueryList<ElementRef<HTMLButtonElement>>;

  disableTab(index: number, disabled: boolean): void {
    if (this._tabScroller?.nativeElement.children.length > 0) {
      const childern = this._tabScroller?.nativeElement.children?.item(index);
      if (disabled) childern?.classList.add('ngx-mdc-tab--disabled');
      else childern?.classList.remove('ngx-mdc-tab--disabled');
    }
  }

  //#region Lifecycle
  ngAfterViewInit(): void {
    if (this._mdcTabBar) {
      this._mdcTabBar.destroy();
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.initMdcTabBar();
      }, 0);
    } else this.initMdcTabBar();
  }

  public initMdcTabBar(): void {
    this._mdcTabBar = MDCTabBar.attachTo(this._elementRef.nativeElement);
    this.useAutomaticActivation = this._useAutomaticActivation;
    this.stacked = this._stacked;
    this.activateTab(this.activeTabIndex);
    this._mdcTabBar.listen(
      'MDCTabBar:activated',
      ($event: CustomEvent<MDCTabBarActivatedEventDetail>) => {
        $event.stopImmediatePropagation();
        this.activated.emit($event.detail);
        this.activeTabIndex = $event.detail.index;
      }
    );
  }

  ngOnDestroy(): void {
    this._mdcTabBar.destroy();
  }
  //#endregion

  //#region MDCTabBar methodes
  public scrollIntoView(index: number): void {
    this._mdcTabBar.scrollIntoView(index);
  }

  public activateTab(index: number): void {
    this._mdcTabBar.activateTab(index);
    this.changeDetectorRef.detectChanges();
    this.mdcTabs.find((tab, i) => i === index)?.nativeElement.blur();
  }

  public getDefaultFoundation(): MDCTabBarFoundation {
    return this._mdcTabBar.getDefaultFoundation();
  }
  //#endregion

  public selectTab(index: number): void {
    this._mdcTabRowContainer?.selectedTab.emit(index);
  }
}

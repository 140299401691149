import { MdcDialogModule } from '@angular-mdc/web/dialog';
import { MdcIconModule } from '@angular-mdc/web/icon';
import { MdcMenuModule } from '@angular-mdc/web/menu';
import { MdcMenuSurfaceModule } from '@angular-mdc/web/menu-surface';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BottomNavComponent } from './bottom-nav';

@NgModule({
  exports: [BottomNavComponent],
  imports: [CommonModule, MdcMenuModule, MdcIconModule, MdcMenuSurfaceModule, MdcDialogModule],
  declarations: [BottomNavComponent]
})
export class MdcBottomNavModule {}

<div class="mdc-icon-button__ripple"></div>
<mdc-icon [outlined]="outlined" [custom]="custom" *ngIf="icon" class="{{ iconClass }}">{{
  icon
}}</mdc-icon>
<mdc-icon
  [outlined]="outlined"
  [custom]="custom"
  mdcIconOn
  *ngIf="!!onIcon && on"
  class="{{ iconClass }}"
>
  {{ onIcon }}
</mdc-icon>
<mdc-icon [outlined]="outlined" [custom]="custom" *ngIf="!!offIcon && !on" class="{{ iconClass }}">
  {{ offIcon }}</mdc-icon
>
<ng-container *ngIf="on && icons.length !== 1">
  <ng-container *ngTemplateOutlet="onIconTemp"></ng-container>
</ng-container>
<ng-container *ngIf="!on && icons.length !== 1">
  <ng-container *ngTemplateOutlet="offIconTemp"></ng-container>
</ng-container>
<ng-container *ngIf="icons.length === 1">
  <ng-container *ngTemplateOutlet="onIconTemp"></ng-container>
  <ng-container *ngTemplateOutlet="offIconTemp"></ng-container>
</ng-container>

<ng-template #offIconTemp>
  <ng-content select=":not([mdcIconOn])"></ng-content>
</ng-template>
<ng-template #onIconTemp>
  <ng-content select="[mdcIconOn]"></ng-content>
</ng-template>
<ng-content></ng-content>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as fromAuthStore from 'src/app/auth/store';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {
  constructor(private _router: Router, private _store: Store<fromAuthStore.IAuthState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkUser();
  }

  canLoad(state: Route): Observable<boolean> {
    return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    return this._store.select(fromAuthStore.selectIsAuthenticated).pipe(
      map((isAuthenticated: boolean) => {
        if (!isAuthenticated) this._router.navigate(['/account/login']);
        return isAuthenticated;
      }),
      take(1)
    );
  }
}

import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[mdcTopAppBarFixedAdjust]',
  exportAs: 'mdcTopAppBarFixedAdjust'
})
export class MdcTopAppBarFixedAdjust {
  @HostBinding('class') class = 'mdc-top-app-bar--fixed-adjust';
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: 'mdc-top-app-bar-row, [mdcTopAppBarRow]',
  exportAs: 'mdcTopAppBarRow'
})
export class MdcTopAppBarRow {
  @HostBinding('class') class = 'mdc-top-app-bar__row';
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: '[mdcTopAppBarNavIcon], mdc-icon[mdcTopAppBarNavigationIcon]',
  exportAs: 'mdcTopAppBarNavigationIcon'
})
export class MdcTopAppBarNavigationIcon {
  @HostBinding('attr.role') role = 'button';
  @HostBinding('class') class = 'mdc-top-app-bar__navigation-icon';

  private _root!: Element;

  constructor(public elementRef: ElementRef<HTMLElement>) {
    this._root = elementRef.nativeElement;
  }
}

@Directive({
  selector: 'mdc-top-app-bar-title, [mdcTopAppBarTitle]',
  exportAs: 'mdcTopAppBarTitle'
})
export class MdcTopAppBarTitle {
  @HostBinding('class') class = 'mdc-top-app-bar__title';

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeDirective } from './badge';

@NgModule({
  exports: [BadgeDirective],
  imports: [CommonModule],
  declarations: [BadgeDirective]
})
export class MdcBadgeModule {}

import { IDrawerData, IDrawerDataBottomBar } from 'src/app/layout/interfaces';

const destinations: { [key: string]: any } = {
  home: {
    href: '/',
    label: 'Home',
    icon: 'dashboard',
    perm: 'route.dashboard' // Everyone
  },
  dashboard: {
    href: '/uebersicht',
    label: 'Dashboard',
    icon: 'dashboard',
    perm: 'route.dashboard' // Admin
  },
  // package: {
  //   href: '/bestellen/paket',
  //   label: 'Nutzungspaket',
  //   icon: 'update',
  //   perm: 'route.order'
  // },
  // product: {
  //   href: '/bestellen/box',
  //   label: APP_NAME === 'gs' ? 'godspot' : 'sorglosbox',
  //   icon: 'router',
  //   perm: 'route.order'
  // },
  // check: {
  //   href: '/bestellen/kasse',
  //   label: 'Kasse',
  //   icon: 'point_of_sale',
  //   badge: { variable: 'all' },
  //   perm: 'route.order'
  // },
  // profile: { href: '/profil', label: 'Profil', icon: 'person' }, // Everyone but in top thingy
  splashOverview: {
    href: '/splash/uebersicht',
    label: 'Splash-Seite',
    icon: 'web',
    perm: 'route.splashOverview'
  },
  // logout: { href: '/account/logout', label: 'Abmelden', icon: 'exit_to_app' },
  customersBox: {
    href: '/kunden',
    label: 'Kunden',
    icon: 'groups',
    perm: 'route.customers'
  },
  // customersBoxGs: {
  //   href: '/customers/gs',
  //   label: 'Godspot',
  //   icon: 'customer_gs_logo',
  //   perm: 'route.customers'
  // },
  monitoring: {
    href: '/monitoring',
    label: 'Monitoring',
    icon: 'monitor_heart',
    perm: 'route.monitoring'
  },
  logistics: {
    href: '/logistik',
    label: 'Logistik',
    icon: 'local_shipping',
    perm: 'route.logistics'
  },
  servers: {
    href: '/server',
    label: 'Server',
    icon: 'catching_pokemon',
    custom: true,
    perm: 'route.servers'
  },
  actioncodes: {
    href: '/aktionscode',
    label: 'Aktionscode',
    icon: 'card_giftcard',
    perm: 'route.actioncodes'
  },
  invoices: {
    href: '/rechnungen',
    label: 'Rechnungen',
    icon: 'receipt',
    perm: 'route.invoices'
  },
  costs: {
    href: '/kosten',
    label: 'Kosten',
    icon: 'attach_money',
    perm: 'route.costs'
  },
  dev: {
    href: '/dev',
    label: 'Dev',
    icon: 'code',
    perm: 'route.dev'
  }
};

export abstract class Destinations {
  public static sidebar: IDrawerData[] = [
    destinations.dashboard,
    destinations.customersBox,
    {
      label: 'Infrastruktur',
      hasChildren: true,
      href: '/it',
      children: [destinations.monitoring, destinations.servers],
      perm: 'route.order'
    },
    destinations.logistics,
    {
      label: 'Finanzen',
      hasChildren: true,
      href: '/finance',
      children: [destinations.invoices, destinations.costs, destinations.actioncodes]
    },
    destinations.splashOverview,
    destinations.dev
  ];

  public static bottombar: IDrawerDataBottomBar[] = [
    destinations.dashboard,
    destinations.customersBox,
    destinations.logistics,
    {
      icon: 'more_vert',
      label: 'mehr',
      isMenu: true,
      role: '9f08c4b6295b',
      children: [
        destinations.monitoring,
        destinations.servers,
        destinations.invoices,
        destinations.costs,
        destinations.actioncodes,
        destinations.splashOverview
      ]
    }
  ];

  public static titleBase = 'intern | ';

  public static titles = Object.keys(destinations).reduce(
    (acc, key) => {
      if (destinations[key].label) acc[destinations[key].href] = destinations[key].label;
      return acc;
    },
    { '/profil': 'Profil' } as { [key: string]: string }
  );

  public static userIconMenu: {
    type: string;
    icon: string;
    name: string;
    url: string;
  }[] = [
    {
      type: 'setting',
      icon: 'person',
      name: 'Profil',
      url: '/profil'
    },
    {
      type: 'logout',
      icon: 'exit_to_app',
      name: 'Logout',
      url: '/account/logout'
    }
  ];
}

import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Corner, cssClasses, DefaultFocusState, MDCMenu, MDCMenuFoundation } from '@material/menu';
import { MDCMenuSurface } from '@material/menu-surface';
import { MDCMenuDistance } from '@material/menu-surface/types';

export type AnchorCorners =
  | 'TOP_LEFT'
  | 'BOTTOM_LEFT'
  | 'TOP_RIGHT'
  | 'BOTTOM_RIGHT'
  | 'TOP_START'
  | 'BOTTOM_START'
  | 'TOP_END'
  | 'BOTTOM_END';

export class MdcMenuSelectedEvent {
  constructor(public index: number, public item: Element) {}
}

let nextUniqueId = 0;
@Component({
  selector: 'mdc-menu',
  template: '<ng-content></ng-content>',
  styleUrls: ['menu.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcMenu implements AfterViewInit, OnDestroy {
  @HostBinding('class') class = 'mdc-menu mdc-menu-surface';

  private _mdcMenu!: MDCMenu;

  private _initialized = false;

  private _uniqueId: string = `${cssClasses.ROOT}-${++nextUniqueId}`;

  @HostBinding('id')
  @Input()
  id: string = this._uniqueId;

  // #region MDCMenu properties
  @Input()
  get open(): boolean {
    return this._mdcMenu ? this._mdcMenu.open : coerceBooleanProperty(this._open);
  }

  set open(open: boolean) {
    const newValue = coerceBooleanProperty(open);
    if (this._mdcMenu) this._mdcMenu.open = newValue;
    else this._open = newValue;
  }

  private _open!: boolean;
  @Input()
  get checkBox(): boolean {
    return this._checkBox;
  }

  set checkBox(open: boolean) {
    this._checkBox = coerceBooleanProperty(open);
  }

  private _checkBox = false;

  @Input()
  get quickOpen(): boolean {
    return this._mdcMenu ? this._mdcMenu.quickOpen : coerceBooleanProperty(this._quickOpen);
  }
  set quickOpen(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcMenu) this._mdcMenu.quickOpen = newValue;
    else this._quickOpen = newValue;
  }

  private _quickOpen!: boolean | null;

  @Input()
  get wrapFocus(): boolean {
    return this._mdcMenu ? this._mdcMenu.wrapFocus : coerceBooleanProperty(this._wrapFocus);
  }
  set wrapFocus(value: boolean) {
    if (this._mdcMenu) this._mdcMenu.wrapFocus = value;
    else this._wrapFocus = value;
  }

  private _wrapFocus!: boolean | null;

  @Input()
  get hasTypeahead(): boolean {
    return this._mdcMenu ? this._mdcMenu.hasTypeahead : coerceBooleanProperty(this._hasTypeahead);
  }
  set hasTypeahead(value: boolean) {
    if (this._mdcMenu) this._mdcMenu.hasTypeahead = value;
    else this._hasTypeahead = value;
  }

  private _hasTypeahead!: boolean | null;
  //#endregion

  @Input()
  get anchorElement(): Element {
    return this._anchorElement;
  }
  set anchorElement(value: Element) {
    this._anchorElement = value;
  }

  private _anchorElement!: Element;

  @Input()
  get anchorCorner(): AnchorCorners {
    return this._anchorCorner;
  }
  set anchorCorner(value: AnchorCorners) {
    this._anchorCorner = value;
    if (this._initialized) this.setAnchorCorner(Corner[value]);
  }
  private _anchorCorner: AnchorCorners = 'TOP_LEFT';

  @Input()
  get anchorMargin(): Partial<MDCMenuDistance> {
    return this._anchorMargin;
  }
  set anchorMargin(value: Partial<MDCMenuDistance>) {
    if (!value) return;
    const newValue = {
      top: coerceNumberProperty(value.top) || 0,
      bottom: coerceNumberProperty(value.bottom) || 0,
      left: coerceNumberProperty(value.left) || 0,
      right: coerceNumberProperty(value.right) || 0
    };
    this._anchorMargin = newValue;
    if (this._initialized) this.setAnchorMargin(newValue);
  }

  private _anchorMargin!: Partial<MDCMenuDistance>;

  @Input()
  get fixed(): boolean {
    return this._fixed;
  }
  set fixed(value: boolean) {
    this._fixed = coerceBooleanProperty(value);
    if (this._initialized) this.setFixedPosition(this._fixed);
  }
  private _fixed: boolean = false;

  @HostBinding('class.mdc-select__menu')
  @Input()
  get selectMenu(): boolean {
    return this._selectMenu;
  }
  set selectMenu(value: boolean) {
    this._selectMenu = coerceBooleanProperty(value);
  }
  private _selectMenu = false;

  @Input()
  get hoistToBody(): boolean {
    return this._hoistToBody;
  }
  set hoistToBody(value: boolean) {
    this._hoistToBody = coerceBooleanProperty(value);
  }
  private _hoistToBody = false;

  @Output()
  readonly selectedItem: EventEmitter<MdcMenuSelectedEvent> = new EventEmitter<MdcMenuSelectedEvent>();

  @Output()
  readonly surfaceOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  readonly surfaceClosing: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  readonly surfaceClosed: EventEmitter<void> = new EventEmitter<void>();

  public root!: HTMLElement;

  constructor(public elementRef: ElementRef<HTMLElement>, private _platform: Platform) {
    this.root = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this._mdcMenu = MDCMenu.attachTo(this.elementRef.nativeElement);
    this._initialized = true;
    this.setAnchorElement(this.anchorElement);
    this.setFixedPosition(this._fixed);
    this._quickOpen = this.quickOpen;
    this.anchorCorner = this._anchorCorner;
    this.anchorMargin = this._anchorMargin;
    if (this.hoistToBody) this.setHoistToBody();

    const surface = this._mdcMenu['menuSurface'] as MDCMenuSurface;

    surface.listen('MDCMenuSurface:opened', () => {
      this.surfaceOpened.emit();
    });
    surface.listen('MDCMenuSurface:closing', () => {
      this.surfaceClosing.emit();
    });
    surface.listen('MDCMenuSurface:closed', () => {
      this.surfaceClosed.emit();
    });

    this._mdcMenu.listen('MDCMenu:selected', ($event: CustomEvent<MdcMenuSelectedEvent>) => {
      this.selectedItem.emit($event.detail);
    });
  }

  ngOnDestroy(): void {
    this._mdcMenu?.destroy();
  }

  //#region MDCMenu methodes
  layout(): void {
    this._mdcMenu.layout();
  }

  getDefaultFoundation(): MDCMenuFoundation {
    return this._mdcMenu.getDefaultFoundation();
  }

  setAnchorElement(element: Element): void {
    this._mdcMenu.setAnchorElement(element);
  }

  setEnabled(index: number, isEnabled: boolean): void {
    this._mdcMenu.setEnabled(index, isEnabled);
  }

  setDefaultFocusState(focusState: DefaultFocusState): void {
    this._mdcMenu.setDefaultFocusState(focusState);
  }

  setIsHoisted(isHoisted: boolean): void {
    this._mdcMenu.setIsHoisted(isHoisted);
  }

  setSelectedIndex(index: number): void {
    this._mdcMenu?.setSelectedIndex(index);
  }

  setFixedPosition(isFixed: boolean): void {
    this._mdcMenu.setFixedPosition(isFixed);
  }

  setAbsolutePosition(x: number, y: number): void {
    this._mdcMenu.setAbsolutePosition(x, y);
  }

  setAnchorMargin(margin: Partial<MDCMenuDistance>): void {
    this._mdcMenu.setAnchorMargin(margin);
  }

  setAnchorCorner(corner: Corner): void {
    this._mdcMenu.setAnchorCorner(corner);
  }

  typeaheadMatchItem(nextChar: string): number {
    return this._mdcMenu.typeaheadMatchItem(nextChar);
  }

  getPrimaryTextAtIndex(index: number): string {
    return this._mdcMenu.getPrimaryTextAtIndex(index);
  }

  getOptionByIndex(index: number): Element | null {
    return this._mdcMenu.getOptionByIndex(index);
  }
  //#endregion

  private setHoistToBody(): void {
    if (!this._platform.isBrowser) return;

    const parentEl = this.elementRef.nativeElement.parentElement;
    if (!parentEl) return;
    document.body!.appendChild(parentEl.removeChild(this.elementRef.nativeElement));
    this._mdcMenu.setIsHoisted(true);
  }
}

import { coreConfigSorglos } from '@core/config/si/app-config';
import { ICoreConfig } from '@core/interfaces/config.interface';

declare let appName: 'si' | 'gs';

const VAPID_PUBLIC_KEY =
  'BBoE0ceUW5EBkGNYpojGIsydzlbCdX50buvoDmpyM2zNSC27fe-gjkXuxgS7SqBFMq0BtF-OjHYKvt188_6jsQk';

export const APP_NAME: 'si' | 'gs' = appName;

export const coreConfig: ICoreConfig = {
  ...coreConfigSorglos,
  VAPID_PUBLIC_KEY
};

export const breakpoint = {
  tablet_large: 1194,
  tablet: 840,
  tablet_small: 640,
  mobile_large: 540,
  mobile: 480
};

export const mwsts = [0, 19];

<ng-container *ngIf="isFullscreen; else elseTemplate">
  <mdc-top-app-bar style="position: relative" [scrollTarget]="dialogContent" fixed>
    <mdc-top-app-bar-row>
      <mdc-top-app-bar-section #title align="start">
        <!-- <button
          class="mdc-dialog__title--abort mdc-icon-button"
          (click)="handleClose()"
          *ngIf="!disabledBack"
        >
          <i class="material-icons mdc-icon-button__icon">arrow_back</i>
          <i class="material-icons mdc-icon-button__icon mdc-icon-button__icon--on">arrow_back</i>
        </button> -->
        <button
          mdc-icon-button
          class="mdc-dialog__title--abort"
          (click)="handleClose()"
          *ngIf="!disabledBack"
          icon="arrow_back"
        ></button>
        <p class="cd-layout--text-overflow">
          <ng-container *ngTemplateOutlet="elseTemplate"></ng-container>
        </p>
      </mdc-top-app-bar-section>
      <mdc-top-app-bar-section align="end" #actionSection>
        <div mdcMenuSurfaceAnchor #dialogMenuAnchor>
          <button
            mdc-icon-button
            icon="more_vert"
            (click)="dialogQuickMenu.open = !dialogQuickMenu.open"
          ></button>
          <mdc-menu
            #dialogQuickMenu
            [anchorElement]="dialogMenuAnchor"
            [anchorCorner]="'BOTTOM_START'"
          >
          </mdc-menu>
        </div>
      </mdc-top-app-bar-section>
    </mdc-top-app-bar-row>
  </mdc-top-app-bar>
</ng-container>
<ng-template #elseTemplate>
  <ng-content></ng-content>
</ng-template>

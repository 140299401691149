<div #surface class="mdc-snackbar__surface" role="status" aria-relevant="additions">
  <div class="mdc-snackbar__label" aria-atomic="false">
    <p class="cd-layout--snackbar-headline" *ngIf="config.headline">{{ config.headline }}</p>
    <span [innerHtml]="config.message" class="cd-layout--snackbar-content"> </span>
    <a
      *ngIf="config.link"
      class="cd-layout--snackbar-link"
      [routerLink]="[config.link.router]"
      routerLinkActive="router-link-active"
      >{{ config.link.label }}</a
    >
  </div>

  <div class="mdc-snackbar__actions" aria-atomic="true">
    <ng-container *ngFor="let action of config.actions; let i = index">
      <button
        mdc-button
        class="mdc-snackbar__action"
        [ngClass]="{ 'cd-layout--customAction': config.customAction, isDecline: i === 0 }"
        [ngStyle]="{ 'margin-right': i === 0 ? '8px' : '' }"
        (click)="action.action(); onActionClick($event)"
      >
        {{ action.label }}
      </button>
    </ng-container>

    <button
      mdc-button
      class="mdc-snackbar__action"
      [ngClass]="{ 'cd-layout--customAction': config.customAction }"
      (click)="onActionClick($event)"
      *ngIf="config.action !== ''"
    >
      {{ config.action }}
    </button>
    <button
      mdc-icon-button
      class="mdc-snackbar__dismiss"
      icon="close"
      (click)="onActionIconClick($event)"
      *ngIf="config.dismiss"
    ></button>
  </div>
</div>

<h1 mat-dialog-title>{{ data.title || 'Finde Kunde' }}</h1>
<div mat-dialog-content>
  <span class="cd-layout--find-customer">
    <mat-form-field appearance="outline">
      <mat-label>Kundennummer</mat-label>
      <input matInput [(ngModel)]="customerNumber" cdkFocusInitial />
    </mat-form-field>
    <button mat-icon-button (click)="searchCustomer()">
      <mat-icon>search</mat-icon>
    </button>
  </span>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="dialogRef.close()">Abbrechen</button>
  <button mat-button [mat-dialog-close]="targetId" [disabled]="!foundValidCustomer">Ok</button>
</div>

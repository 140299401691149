import { MdcListItem } from '@angular-mdc/web/list';
import { Component, ViewChild, inject } from '@angular/core';
import { MDCList } from '@material/list';
import { Store } from '@ngrx/store';
import { APP_NAME } from 'src/app/app-config';
import * as fromAuthStore from 'src/app/auth/store';
import { Destinations } from 'src/app/layout/models/destinations.model';
import { IProfileState, selectProfileRole } from 'src/app/main/store/profile';
import { environment } from 'src/environments/environment';
import { MenuBase } from '../menu.base';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottomMenu.component.html',
  styleUrls: ['./bottomMenu.component.scss']
})
export class BottomMenuComponent extends MenuBase {
  private _store = inject(Store<fromAuthStore.IAuthState>);
  private _profileStore = inject(Store<IProfileState>);

  public destinations = Destinations.bottombar;
  public version = environment.VERSION;
  public appName = APP_NAME;

  public selectedIndex!: number;

  public role$ = this._profileStore.select(selectProfileRole);

  @ViewChild('menuListItem')
  private _menuListItem!: MdcListItem;

  @ViewChild('menuList')
  private _menuList!: MDCList;

  constructor() {
    super();
  }

  public selectedItem($event: any): void {
    if ($event.index > Number(this._menuListItem?.id)) this._menuListItem.activated = true;
    else this._menuListItem.activated = false;
  }

  public isMenuSelected(): boolean {
    if (
      this._menuList &&
      this._menuList.listElements.findIndex((el) =>
        el.classList.contains('mdc-deprecated-list-item--selected')
      ) !== -1 &&
      this.selectedIndex !== -1
    )
      return true;
    return false;
  }

  public setMeuIndex($event: any): void {
    if (this.findIndex() !== -1) this.selectedIndex = $event.index;
  }

  public openMenu(): void {
    this.selectedIndex = this.findIndex();
  }

  public handleLogoutDialog(result: 'accept' | 'close'): void {
    if (result === 'accept') this._store.dispatch(fromAuthStore.logout());
    if (result === 'close') this._menuList.selectedIndex = this.selectedIndex;
  }

  private findIndex(): number {
    if (!this._menuList) return -1;
    return this._menuList.listElements.findIndex(
      (el) =>
        el.getAttribute('routerlinkactive') &&
        el.classList.contains('mdc-deprecated-list-item--selected')
    );
  }
}

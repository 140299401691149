import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Delta } from 'jsondiffpatch';
import { catchError, map, Observable, of } from 'rxjs';
import { CustomerEnum } from '../utils';
import { IResponse } from '../utils/interfaces/api.interface';
import { DebitModel, IBicInstituteData, ICustomer } from '../utils/interfaces/customer.interface';
import { ISearchCustomerNumbers } from './customer-store.service';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private _http: HttpClient) {}

  public putCustomer(value: PutCustomer.RequestBody): Observable<PutCustomer.Response> {
    return this._http.put<PutCustomer.Response>(CustomerEnum.customer, value);
  }

  public getCustomer(
    id: string
  ): Observable<{ status: 'success' | 'error'; data: ICustomer; message?: string }> {
    return this._http
      .get<{ status: 'success' | 'error'; data: ICustomer; message?: string }>(
        CustomerEnum.getCustomer.replace(':id', id)
      )
      .pipe(
        map((res) => {
          if (res.data.address.debit) {
            res.data.address.debit = res.data.address.debit.map((item) => {
              if (Object.keys(item).length !== 0) return new DebitModel(item);
              return new DebitModel({ ...item, name: '!!LEERER EINTRAG!!', IBAN: ' ', BIC: ' ' });
            });
          }
          return res;
        })
      );
  }

  public getCustomerId(customerNumber: string): Observable<IResponse<string>> {
    return this._http.get<IResponse<string>>(
      CustomerEnum.getCustomerId.replace(':customerNumber', customerNumber)
    );
  }

  public getNotes(customerId: string): Observable<any> {
    return this._http.get<any>(CustomerEnum.getNotes.replace(':customerId', customerId));
  }

  public delNote(
    timestamp: string,
    customerId: string
  ): Observable<{ message: string; status: string }> {
    return this._http.delete<{
      message: string;
      status: string;
    }>(CustomerEnum.removeNote, {
      body: {
        timestamp: timestamp,
        id: customerId
      }
    });
  }

  public putNotes(
    note: { event: string; text: string },
    id: string
  ): Observable<{ message: string; status: string }> {
    return this._http.put<{ message: string; status: string }>(CustomerEnum.putNotes, {
      id: id,
      note: note
    });
  }

  public patchCustomer(
    delta: Delta,
    customerId: string
  ): Observable<{
    message: string;
    status: string;
    historie: { timestamp: Date; userName: string; event: string; delta: Delta };
  }> {
    return this._http.patch<{
      message: string;
      status: string;
      historie: { timestamp: Date; userName: string; event: string; delta: Delta };
    }>(`${CustomerEnum.customer}/${customerId}`, delta);
  }

  public validateIBAN(
    iban: string
  ): Observable<{ status: 'success' | 'error'; message: string } & IBicInstituteData> {
    return this._http.get<{ status: 'success' | 'error'; message: string } & IBicInstituteData>(
      CustomerEnum.validateIBAN + `?iban=${iban.replace(/ /g, '')}`
    );
  }

  public deleteCustomer(customerId: string): Observable<{ message: string; status: string }> {
    return this._http.delete<{
      message: string;
      status: string;
    }>(CustomerEnum.deleteCustomer, {
      body: {
        id: customerId
      }
    });
  }

  public searchCustomerNumbers(customerNumber: string): Observable<ISearchCustomerNumbers> {
    return this._http
      .get<IResponse<ISearchCustomerNumbers>>(
        CustomerEnum.getCustomerNumbers.replace(':customerNumber', customerNumber)
      )
      .pipe(
        map((response) => {
          if (response.status !== 'success') return { count: 0, list: [response.message] };
          return response.body as ISearchCustomerNumbers;
        }),
        catchError((error: any): Observable<ISearchCustomerNumbers> => {
          console.log(`searchCustomerNumbers failed: ${error.message}`);
          return of({
            count: 0,
            list: ['Keine Kunden gefunden']
          } as ISearchCustomerNumbers);
        })
      );
  }

  public getGenerateCustomerNumber(
    customerId: string
  ): Observable<IResponse<{ customerNumber: string }>> {
    return this._http.get<IResponse<{ customerNumber: string }>>(
      CustomerEnum.getGenerateCustomerNumber.replace(':id', customerId)
    );
  }

  public getSendCustomerForgotMail(customerId: string): Observable<IResponse<undefined>> {
    return this._http.get<IResponse<undefined>>(
      CustomerEnum.getSendCustomerForgotMail.replace(':id', customerId)
    );
  }
}

export namespace PutCustomer {
  export interface RequestBody {
    isGs: boolean;
    email: string;
    address: {
      contact: {
        company?: string;
        firstName?: string;
        lastName?: string;
        street?: string;
        houseNumber?: string;
        plz?: string;
        city?: string;
        country?: string;
        landline?: string;
        mobile?: string;
      };
    };
  }

  export interface Response {
    status: 'error' | 'success';
    id: string;
  }
}

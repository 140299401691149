import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MDCDialog } from '@material/dialog';
import { SubscriptionLike } from 'rxjs';
import { MdcDialogActions, MdcDialogContent, MdcDialogTitle } from './dialog.helpers';

let nextUniqueId = 0;

@Component({
  selector: 'mdc-dialog',
  templateUrl: './dialog.html',
  styleUrls: ['./dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcDialog implements OnDestroy {
  @HostBinding('class') class = 'mdc-dialog';

  @HostBinding('id')
  get id(): string {
    return this._uniqueId;
  }

  @Output()
  public beforeOpened: EventEmitter<void> = new EventEmitter();
  @Output()
  public afterOpened: EventEmitter<void> = new EventEmitter();
  @Output()
  public beforeClosed: EventEmitter<'scrim' | string> = new EventEmitter();
  @Output()
  public afterClosed: EventEmitter<'scrim' | string> = new EventEmitter();

  private _dialog!: MDCDialog;

  private _root!: HTMLElement;

  public get root(): HTMLElement {
    return this._root;
  }

  public isOpen = false;
  public isOpened = false;
  public isFirstOpend = false;

  @Input()
  public get fullscreen(): boolean {
    return this._fullscreen;
  }
  public set fullscreen(value: boolean) {
    this._fullscreen = coerceBooleanProperty(value);
  }
  private _fullscreen: boolean = false;

  @Input()
  public get fullscreenResolution(): number {
    return this._fullscreenResolution;
  }
  public set fullscreenResolution(value: number) {
    this._fullscreenResolution = coerceNumberProperty(value);
  }
  private _fullscreenResolution: number = 480;

  public get isFullscreen(): boolean {
    return (
      this.fullscreen &&
      (window.outerWidth <= this.fullscreenResolution ||
        window.outerHeight <= this.fullscreenResolution)
    );
  }

  @Input()
  public get autoFocus(): boolean {
    return this._autoFocus;
  }
  public set autoFocus(value: boolean) {
    this._autoFocus = coerceBooleanProperty(value);
  }
  private _autoFocus: boolean = false;

  @Input()
  public get surfaceStyle(): string {
    return !this.isFullscreen ? this._surfaceStyle : '';
  }
  public set surfaceStyle(value: string) {
    this._surfaceStyle = value;
  }
  private _surfaceStyle: string = '';

  @Input()
  public get containerStyle(): string {
    return !this.isFullscreen ? this._containerStyle : '';
  }
  public set containerStyle(value: string) {
    this._containerStyle = value;
  }
  private _containerStyle: string = '';

  private _uniqueId: string = `mdc-dialog-${++nextUniqueId}`;

  private _appRootElement!: HTMLElement;

  private _parentNode!: Node;

  @ContentChildren(MdcDialogActions, { descendants: false })
  private _actions!: QueryList<MdcDialogActions>;

  @ContentChild(MdcDialogTitle)
  public title!: MdcDialogTitle;
  @ContentChild(MdcDialogContent)
  public content!: MdcDialogContent;

  @ViewChild('dialogSurface', { static: true })
  private _dialogSurface!: ElementRef<HTMLElement>;

  private _listenersAttached = false;

  private _history = window.history;
  private _historyListener!: SubscriptionLike;

  public get dialogMenuList(): HTMLElement {
    return this._dialog.root.querySelector('mdc-dialog-menu-list') as HTMLElement;
  }

  constructor(
    private _elementRef: ElementRef,
    private _cdRef: ChangeDetectorRef,
    private _location: Location
  ) {
    this._root = this._elementRef.nativeElement;
    this._appRootElement = document.querySelector('.main-wrapper') as HTMLElement;
  }

  ngOnDestroy(): void {
    if (this._historyListener) this._historyListener.unsubscribe();
  }

  private attachListeners(): void {
    this._listenersAttached = true;

    this._dialog.listen('MDCDialog:opening', ($event) => {
      if (!this.isFirstOpend) this.isFirstOpend = true;
      if ((this._root.parentNode as HTMLElement).id !== 'mdc-dialog-wrapper')
        this._parentNode = this._root.parentNode as Node;
      let dialogWrapper = document.getElementById('mdc-dialog-wrapper');
      if (!dialogWrapper) {
        dialogWrapper = document.createElement('div');
        dialogWrapper.id = 'mdc-dialog-wrapper';
        document.body.appendChild(dialogWrapper);
      }

      dialogWrapper.appendChild(this._root);
      this.beforeOpened.emit();
      setTimeout(() => {
        if (this.isFullscreen && this._actions.first) this.activateFullscreenTitle();
        if (!this.isFullscreen && this.dialogMenuList) this.dialogMenuList.style.display = 'none';
      }, 0);

      this.historyHandler($event.type);
    });

    this._dialog.listen('MDCDialog:opened', () => {
      if (document.activeElement && !this.autoFocus) {
        (document.activeElement as HTMLElement).blur();
      }
      this.isOpened = true;
      this.afterOpened.emit();
      this._elementRef.nativeElement.focus();
    });

    this._dialog.listen('MDCDialog:closing', ($event: Event & { detail: { action: string } }) => {
      this.beforeClosed.emit($event.detail.action);
      this.historyHandler($event.type);
    });
    this._dialog.listen('MDCDialog:closed', ($event: Event & { detail: { action: string } }) => {
      this._parentNode.appendChild(this._root);
      const dialogWrapper = document.getElementById('mdc-dialog-wrapper');
      // wait for animation
      setTimeout(() => {
        if (dialogWrapper && !dialogWrapper.hasChildNodes()) dialogWrapper.remove();
        else document.body.classList.add('mdc-dialog-scroll-lock');
        if (this.isFullscreen && this._actions.first) {
          this.deactivateFullscreenTitle();
          document.body.classList.remove('mdc-dialog-scroll-lock');
        }
      }, 250);
      this.isOpen = false;
      this.isOpened = false;
      this._cdRef.markForCheck();
      this.afterClosed.emit($event.detail.action);
      this._dialog.destroy();
    });
  }

  public open(): MdcDialog {
    this.isOpen = true;
    this._cdRef.markForCheck();
    if (this.isFullscreen) {
      this._root.classList.add('mdc-dialog--fullscreen');
      this._appRootElement?.classList.add('fullscreen-open');
    } else {
      this._root.classList.remove('mdc-dialog--fullscreen');
    }

    this.attachTo(this._root);
    this._dialog.open();

    return this;
  }

  private attachTo(element: HTMLElement): void {
    this._dialog = MDCDialog.attachTo(element);
    if (!this.autoFocus) {
      (this._dialog as any).focusTrap.trapFocus = () => {};
    }

    this._dialog.escapeKeyAction = '';
    this._dialog.autoStackButtons = false;

    if (!this._listenersAttached) this.attachListeners();
  }

  private activateFullscreenTitle(): void {
    this.title.actionSection.elementRef.nativeElement.appendChild(this._actions.first.root);
    if (this._actions.first.root.firstElementChild) {
      this._actions.first.root.firstElementChild.setAttribute('fullscreen', 'true');
      if (this._actions?.first?.mdcButtons?.length > 0) {
        this._actions.first.mdcButtons.forEach((button) => {
          const tempButton = button.elementRef.nativeElement;
          if (tempButton.attributes['mdcdialogaction' as any]?.value !== 'accept') {
            tempButton.style.display = 'none';
          } else button.raised = true;
        });
      }
    }

    if (this.dialogMenuList) {
      this.title.dialogQuickMenu.elementRef.nativeElement.appendChild(this.dialogMenuList);
      if (this.title.dialogMenuAnchor) {
        this.title.actionSection.elementRef.nativeElement.appendChild(
          this.title.dialogMenuAnchor.nativeElement
        );
      }
    } else {
      this.title.dialogMenuAnchor.nativeElement.style.display = 'none';
    }
  }

  private deactivateFullscreenTitle(): void {
    this._dialogSurface.nativeElement.appendChild(this._actions.first.root);
  }

  public close(action?: string): void {
    if (!this.isFullscreen) return this._dialog.close(action);
    this._appRootElement?.classList.add('animate-close');
    this._root.classList.add('animate-close');

    // Wait animation time to remove classes again
    setTimeout(() => {
      this._appRootElement?.classList.remove('fullscreen-open');
      this._appRootElement?.classList.remove('animate-close');
      this._root.classList.remove('animate-close');
      this._dialog.close(action);
    }, 250);
  }

  public scrimClose($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.close('scrim');
  }

  private historyHandler(mode: string): void {
    switch (mode) {
      case 'MDCDialog:opening':
        this._historyListener = this._location.subscribe(() => {
          if (this._history.state?.type === 'dialog') {
            if (this._history.state.open.indexOf(this.id) === -1) this.close();
          } else {
            this.close();
          }
        });

        if (this._history.state?.type !== 'dialog')
          return this._history.pushState({ type: 'dialog', open: [this.id] }, 'dialog' + this.id);
        this._history.state.open.push(this.id);
        this._history.pushState(this._history.state, 'dialog' + this.id);
        break;
      case 'MDCDialog:closing':
        if (this._history.state?.type === 'dialog')
          if (this._history.state.open.indexOf(this.id) > -1) this._history.back();
        this._historyListener.unsubscribe();
        break;
    }
  }
}

import { createAction, props } from '@ngrx/store';
import { Chat } from 'src/app/main/utils/enums/chat.enum';
import { IProfileState } from '.';

export const getProfileData = createAction('[Profile] - Get Profile Data');

export const getProfileDataComplete = createAction(
  '[Profile] - Get Profile Data Complete',
  props<Partial<IProfileState>>()
);

export const editProfileData = createAction(
  '[Profile] - Set Profile Data',
  props<{ data: IProfileState }>()
);

export const putProfileData = createAction(
  '[Profile] - Put Profile Data',
  props<Partial<IProfileState>>()
);

export const setSelectedChatLayout = createAction(
  '[Profile] - Set Chat Layout',
  props<{ layout: Chat.TLayout }>()
);

export const setFabSize = createAction(
  '[Profile] - Set FabSize',
  props<{ h: number; w: number }>()
);

export const setFabOpen = createAction('[Profile] - Set FabOpen', props<{ open: boolean }>());

export const setSelectedTheme = createAction(
  '[Profile] - Set SelectedTheme',
  props<{ theme: 'light' | 'dark' | 'system' }>()
);

export const setExpansionPanel = createAction(
  '[Profile] - Set Expansion Panel State',
  props<{ id: string; expanded: boolean }>()
);

export const profileReset = createAction('[Profile] - Reset');

export const setMenuCollapse = createAction(
  '[Profile] - Set Menu Collapse',
  props<{ collapse: boolean }>()
);

export const setThemeOptions = createAction(
  '[Profile] - Set Theme Options',
  props<{ options: { [key: string]: boolean } }>()
);

import { NgModule } from '@angular/core';
// import { CustomerStoreModule } from './customer';
import { ProfileStoreModule } from './profile';
import { TableStoreModule } from './table/table-store.module';
// import { AddressStoreModule } from './address/address-store.module';
// import { SettingsStoreModule } from './box-settings/box-settings-store.module';
// import { BoxesStoreModule } from './box-view/box-view-store.module';
// import { CartStoreModule } from './cart/cart-store.module';
// import { InvoiceStoreModule } from './invoice/invoice-store.module';
// import { ProfileStoreModule } from './profile/profile-store.module';
// import { SplashStoreModule } from './splash/splash-store.module';

@NgModule({
  imports: [ProfileStoreModule, TableStoreModule]
  // imports: [
  //   AddressStoreModule,
  //   BoxesStoreModule,
  //   InvoiceStoreModule,
  //   ProfileStoreModule,
  //   SettingsStoreModule,
  //   SplashStoreModule,
  //   CartStoreModule
  // ]
})
export class StoreModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay, take } from 'rxjs';
import { IProfileState } from '.';
import { AuthEnum, UserEnum } from '../../utils';

@Injectable()
export class ProfileService {
  private _userData$!: Observable<IProfileState>;

  constructor(private _http: HttpClient) {}

  public getUserData(): Observable<IProfileState> {
    if (!this._userData$) {
      this._userData$ = this._http
        .get<IProfileState>(UserEnum.getUserData)
        .pipe(take(1), shareReplay({ bufferSize: 1, refCount: true }));
    }
    return this._userData$;
  }

  public putProfileData(
    saveData: Partial<IProfileState>
  ): Observable<{ message: string; status: string; pictureSaved?: string }> {
    return this._http.put<{ message: string; status: string; pictureSaved?: string }>(
      UserEnum.putUserData,
      saveData
    );
  }

  public editProfilePassword(data: {
    oldPassword: string;
    newPassword: string;
    newRepeat: string;
  }): Observable<{ status: 'success' | 'error'; message: string }> {
    return this._http.post<{ status: 'success' | 'error'; message: string }>(
      AuthEnum.updatePassword,
      data
    );
  }

  // public requestTypeStats(type: 'si' | 'gs'): Observable<any> {
  //   return new Observable((subscriber) => {
  //     subscriber.next({
  //       customers: Math.floor(Math.random() * (6000 - 1000) + 1000),
  //       box: Math.floor(Math.random() * (6000 - 1000) + 1000),
  //       boxPro: Math.floor(Math.random() * (6000 - 1000) + 1000),
  //       box5G: Math.floor(Math.random() * (6000 - 1000) + 1000),
  //       boxPro5G: Math.floor(Math.random() * (6000 - 1000) + 1000),
  //       appliance: Math.floor(Math.random() * (6000 - 1000) + 1000),
  //       appliance1Wifi: Math.floor(Math.random() * (6000 - 1000) + 1000),
  //       appliance2Wifi: Math.floor(Math.random() * (6000 - 1000) + 1000),
  //       rack: Math.floor(Math.random() * (6000 - 1000) + 1000)
  //     });
  //     subscriber.complete();
  //   });

  //   // return this._http.get<any>(CustomerEnum.getCustomerData.replace(':type', type));
  // }
}

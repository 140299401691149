import { ITokenData } from '../interfaces/token.interface';

export const authStateName = 'auth';

export interface IAuthStore {
  [authStateName]: IAuthState;
}

export interface IAuthState {
  isAuthenticated?: boolean;
  isResetMailSent?: boolean;
  isReset?: boolean;
  tokenData?: ITokenData;
}

export interface IPermission {
  route: {
    dashboard: boolean;
    // account: false,
    order: boolean;
    profile: boolean;
    splashOverview: boolean;
    splashEditor: boolean;
  };
  dashboard: {
    normal: {
      schedules: boolean;
      wifi: boolean;
      speed: boolean;
      splashPage: boolean;
      sync: boolean;
    };
    expert: { allowed: boolean };
    menu: { rename: boolean; statistic: boolean; notes: boolean };
    permMenu: boolean;
    extend: boolean;
  };
}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MdcButton } from '../button';
import { MdcMenu } from '../menu';
import { MdcTopAppBarSection } from '../top-app-bar';
import { MdcDialog } from './dialog';

//#region Helper Components

@Directive({
  selector: 'mdc-dialog-menu-list'
})
export class MdcDialogMenuList {
  @HostBinding('class') class = 'mdc-dialog__menu_list';
  constructor() {}
}
@Component({
  selector: 'mdc-dialog-title',
  templateUrl: './dialog-headline.html',
  encapsulation: ViewEncapsulation.None
})
export class MdcDialogTitle {
  @Input()
  public set disabledBack(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    this._disabledBack = newValue;
  }

  public get disabledBack(): boolean {
    return this._disabledBack;
  }

  private _disabledBack: boolean = false;

  @HostBinding('class') class = 'mdc-dialog__title';
  public get root(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  @ViewChild('actionSection', { static: false })
  public actionSection!: MdcTopAppBarSection;

  @ViewChild('dialogMenuAnchor', { static: false })
  public dialogMenuAnchor!: ElementRef<HTMLElement>;

  @ViewChild('dialogQuickMenu', { static: false })
  public dialogQuickMenu!: MdcMenu;

  public get isFullscreen(): boolean {
    return this._dialog?.isFullscreen || false;
  }

  private _contentElement!: HTMLElement;

  public get dialogContent(): HTMLElement {
    if (!this._contentElement) this._dialog.root.querySelector('mdc-dialog-content') as HTMLElement;

    return this._contentElement;
  }

  constructor(private _elementRef: ElementRef, @Optional() private _dialog: MdcDialog) {}

  public handleClose(): void {
    if (this._dialog) this._dialog.close('abort');
  }
}

@Component({
  selector: 'mdc-dialog-subtitle',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None
})
export class MdcDialogSubtitle {
  constructor(private _elementRef: ElementRef, @Optional() private _dialog: MdcDialog) {}
}

@Component({
  selector: 'mdc-dialog-content',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None
})
export class MdcDialogContent {
  @HostBinding('class') class = 'mdc-dialog__content';

  @HostListener('scroll') private onScroll(): void {
    const scrollTop = this.elementRef.nativeElement.scrollTop;
    if (scrollTop === 0) {
      this._dialog.root.classList.remove('mdc-dialog-scroll-down');
    } else {
      this._dialog.root.classList.add('mdc-dialog-scroll-down');
    }
  }

  public get root(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(public elementRef: ElementRef, @Optional() private _dialog: MdcDialog) {}
}

@Component({
  selector: 'mdc-dialog-actions',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None
})
export class MdcDialogActions {
  @HostBinding('class') class = 'mdc-dialog__actions';

  @ContentChildren(MdcButton) public mdcButtons!: QueryList<MdcButton>;

  public get root(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  constructor(private _elementRef: ElementRef) {}
}
//#endregion

//#region Action Directive
@Directive({
  selector: '[mdcDialogAction]'
})
export class MdcDialogAction {
  @HostBinding('class') class = 'mdc-dialog__button';

  @Input()
  public mdcDialogAction!: 'accept' | 'close' | 'closeMenu' | 'info' | 'cancel';

  @Input()
  public get closeAccept(): boolean {
    return this._closeAccept;
  }

  public set closeAccept(value: boolean) {
    this._closeAccept = coerceBooleanProperty(value);
    this._cdRef.markForCheck();
  }

  private _closeAccept: boolean = true;

  @HostListener('click', ['$event']) onClick($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.mdcDialogAction === 'closeMenu') {
      this._dialog.title.dialogQuickMenu.open = false;
      return;
    }
    if (this.mdcDialogAction === 'info') return;
    if (this.mdcDialogAction !== 'accept') this._dialog.close(this.mdcDialogAction);
    else if (this.closeAccept) this._dialog.close(this.mdcDialogAction);
  }

  constructor(private _cdRef: ChangeDetectorRef, @Optional() private _dialog: MdcDialog) {}
}
//#endregion

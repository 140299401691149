import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ResizeService } from '@core/index';
import { Store } from '@ngrx/store';
import { Subscription, map } from 'rxjs';
import { APP_NAME, coreConfig } from 'src/app/app-config';
import { IAuthState, logout } from 'src/app/auth/store';
import { Destinations } from 'src/app/layout/models/destinations.model';
import { DashboardService } from 'src/app/main/services/dashboard.service';
import {
  IProfileState,
  selectMenuCollapse,
  selectProfileRole,
  setMenuCollapse
} from 'src/app/main/store/profile';
import { environment } from 'src/environments/environment';
import { MenuBase } from '../menu.base';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends MenuBase implements OnInit, OnDestroy {
  public destinations = Destinations.sidebar;
  public version = environment.VERSION;
  public versionLib = environment.VERSION_LIB;
  public versionApi$ = this._dashboardService
    .requestApiVersion()
    .pipe(map((res) => res.data.version));
  public assets = coreConfig.assets;
  public appName = APP_NAME === 'gs' ? 'godspot' : 'sorglos';

  public get isTablet(): boolean {
    return this.resize.windowWidth <= this.resize.breakpoint.tablet_large;
  }

  private _menuCollapse$ = this._profileStore.select(selectMenuCollapse);
  private _menuCollapseSubscription!: Subscription;

  public role$ = this._profileStore.select(selectProfileRole);
  public menuCollapse = false;

  constructor(
    private _store: Store<IAuthState>,
    private _profileStore: Store<IProfileState>,
    public resize: ResizeService,
    public elementRef: ElementRef<HTMLElement>,
    private _cdRef: ChangeDetectorRef,
    private _dashboardService: DashboardService
  ) {
    super();
  }

  ngOnInit(): void {
    this._menuCollapseSubscription = this._menuCollapse$.subscribe((collapse) => {
      this.menuCollapse = collapse;
      this._cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this._menuCollapseSubscription) this._menuCollapseSubscription.unsubscribe();
  }

  public logoutUser(): void {
    this._store.dispatch(logout());
  }

  public setMenuCollapse($event: boolean): void {
    this._profileStore.dispatch(setMenuCollapse({ collapse: $event }));
  }
}

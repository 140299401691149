import { MdcSnackbarModule } from '@angular-mdc/web/snackbar';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreConfigModule } from './config';
import { CORE_CUSTOM_CONFIG } from './config/config.service';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { ICoreConfig } from './interfaces/config.interface';
import { CoreLoadingScreenService } from './services';

@NgModule({
  imports: [CommonModule, MdcSnackbarModule, CoreConfigModule],
  declarations: [],
  providers: [
    CoreLoadingScreenService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
  ]
})
export class CoreModule {
  constructor(private _loadingScreenService: CoreLoadingScreenService) {}

  static forRoot(config: ICoreConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: CORE_CUSTOM_CONFIG,
          useValue: config
        }
      ]
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseEnum, DashboardEnum, WebsocketEnum } from '../utils';
import { IResponse } from '../utils/interfaces/api.interface';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private _http: HttpClient) {}

  public getConnections(): Observable<{
    status: string;
    users: IUser[];
  }> {
    return this._http.get<{
      status: string;
      users: IUser[];
    }>(WebsocketEnum.getConnections);
  }

  public requestApiVersion(): Observable<IRequestApiVersion> {
    return this._http.get<{ status: 'error' | 'success'; data: { version: string } }>(
      BaseEnum.getVersionApi
    );
  }

  public getNewBoxesByRange(
    start: string,
    end: string,
    groupBy: 'year' | 'month' | 'day'
  ): Observable<IResponse<INamedSeries[]>> {
    return this._http.get<IResponse<INamedSeries[]>>(
      DashboardEnum.getNewBoxesByRange
        .replace(':start', start)
        .replace(':end', end)
        .replace(':groupBy', groupBy)
    );
  }

  public getBoxesByOnlineStatus(): Observable<
    IResponse<{
      online: number;
      offline: number;
    }>
  > {
    return this._http.get<IResponse<{ online: number; offline: number }>>(
      DashboardEnum.getBoxesByOnlineStatus
    );
  }

  public getNewCustomersByRange(
    start: string,
    end: string,
    groupBy: 'year' | 'month' | 'day'
  ): Observable<IResponse<INamedSeries[]>> {
    return this._http.get<IResponse<INamedSeries[]>>(
      DashboardEnum.getNewCustomersByRange
        .replace(':start', start)
        .replace(':end', end)
        .replace(':groupBy', groupBy)
    );
  }

  public getInvoiceRevenueByRange(
    start: string,
    end: string
  ): Observable<
    IResponse<{
      length: number;
      invoices: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _id: string;
        total: number;
      }[];
    }>
  > {
    return this._http.get<
      IResponse<{
        length: number;
        invoices: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _id: string;
          total: number;
        }[];
      }>
    >(
      DashboardEnum.getInvoiceRevenueByRange
        .replace(':start', start)
        .replace(':end', end)
        .replace(':groupBy', 'month')
    );
  }

  public getUnpaidInvoices(): Observable<
    IResponse<{
      length: number;
      totalNetto: number;
    }>
  > {
    return this._http.get<
      IResponse<{
        length: number;
        totalNetto: number;
      }>
    >(DashboardEnum.getUnpaidInvoices);
  }

  public getBoxesBySuspend(): Observable<
    IResponse<{
      suspend: number;
      notSuspend: number;
    }>
  > {
    return this._http.get<
      IResponse<{
        suspend: number;
        notSuspend: number;
      }>
    >(DashboardEnum.getBoxesBySuspend);
  }

  public getProfitByRange(start: string, end: string): Observable<IResponse<INamedSeries[]>> {
    return this._http.get<IResponse<INamedSeries[]>>(
      DashboardEnum.getProfitByRange.replace(':start', start).replace(':end', end)
    );
  }

  public getSalesNettoByRange(
    start: string,
    end: string,
    groupBy: 'year' | 'month' | 'day'
  ): Observable<IResponse<INamedSeries[]>> {
    return this._http.get<IResponse<INamedSeries[]>>(
      DashboardEnum.getSalesNettoRange
        .replace(':start', start)
        .replace(':end', end)
        .replace(':groupBy', groupBy)
    );
  }

  public getCustomersGrowthByRange(
    start: string,
    end: string,
    groupBy: 'year' | 'month' | 'day'
  ): Observable<IResponse<INamedSeries[]>> {
    return this._http.get<IResponse<INamedSeries[]>>(
      DashboardEnum.getCustomersGrowthByRange
        .replace(':start', start)
        .replace(':end', end)
        .replace(':groupBy', groupBy)
    );
  }
  public getBoxGrowthByRange(
    start: string,
    end: string,
    groupBy: 'year' | 'month' | 'day'
  ): Observable<IResponse<INamedSeries[]>> {
    return this._http.get<IResponse<INamedSeries[]>>(
      DashboardEnum.getBoxGrowthByRange
        .replace(':start', start)
        .replace(':end', end)
        .replace(':groupBy', groupBy)
    );
  }
}

export interface IUser {
  // id: string;
  displayName: string;
  name: string;
  id: string | 'default';
  pictureUrl: string;
  initials?: string;
  customers: IUserCustomer[];
  isCustomer: boolean;
  customerPictureUrls: string[];
}

export interface IUserCustomer {
  id: string;
  customerNumber: string;
  company?: string;
  firstName?: string;
  lastName?: string;
}

export interface IRequestApiVersion {
  status: 'error' | 'success';
  data: { version: string };
}

export interface INewBoxesByRange {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _id: string;
  total: number;
  kinds: { kind: string; total: number }[];
}

export interface INewCustomersByRange {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _id: string;
  total: number;
  types: { type: 'si' | 'gs'; total: number }[];
}

export interface INamedSeries {
  name: string;
  data: { x: string; y: number }[];
}

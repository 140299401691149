/* eslint-disable @typescript-eslint/naming-convention */

import { ModelBase } from '../model.base';
import { IBoxData } from './box-details.interface';

export interface IAddress {
  [key: string]: any;
  contact: IContact;
  differs: IDiffer[];
  tech: ITech;
  debit: IDebit[];
}

export interface IDebit {
  name: string;
  IBAN: string;
  BIC: string;
  instituteName: string;
}

export class DebitModel extends ModelBase {
  public name!: string;
  public IBAN!: string;
  public BIC!: string;
  public instituteName!: string;

  constructor(data: Partial<IDebit>) {
    super('DebitModel', ['name', 'IBAN', 'BIC', 'instituteName'], {
      ...data,
      name: data.name || '',
      IBAN: data.IBAN || '',
      BIC: data.BIC || '',
      instituteName: data.instituteName || ''
    });
  }
}

export interface IDiffer {
  company: string;
  firstName?: string;
  lastName?: string;
  street: string;
  houseNumber: string;
  plz: string;
  city: string;
  country: string;
}

export class DifferModel extends ModelBase {
  public company!: string;
  public firstName!: string;
  public lastName!: string;
  public street!: string;
  public houseNumber!: string;
  public plz!: string;
  public city!: string;
  public country!: string;

  constructor(data: Partial<IDiffer>) {
    super(
      'DifferModel',
      ['company', 'firstName', 'lastName', 'street', 'houseNumber', 'plz', 'city', 'country'],
      {
        company: data.company || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        street: data.street || '',
        houseNumber: data.houseNumber || '',
        plz: data.plz || '',
        city: data.city || '',
        country: data.country || ''
      }
    );
  }
}

export interface IContact extends IDiffer {
  landline: string;
  mobile: string;
  firstName: string;
  lastName: string;
}

export interface ITech extends IContact {
  email: string;
}

export interface IBicInstituteData {
  BIC: string;
  instituteName: string;
}

export interface ICustomer {
  address: IAddress;
  apiToken: string;
  boxes: string[] | Partial<IBoxData>[];
  customerNumber: string;
  discount: number;
  email: string;
  historie: ICustomerHistorie[];
  invoices: string[];
  notes: ICustomerNote[];
  out: boolean;
  reset: boolean;
  resetHash: string;
  source: string;
  tax: { number: number; description?: string };
  webPushDevices: {
    subscription: {
      keys: {
        p256dh: string;
        auth: string;
      };
      endpoint: string;
    };
    enabled: boolean;
    timestamp: {
      $date: string;
    };
  }[];
  webPushSubscriptions: {
    endpoint: string;
    keys: {
      auth: string;
      p256dh: string;
    };
  }[];
  __v: number;
  _id: string;
  _last_updated: string;
  stats: {
    boxes: {
      [key: string]: number;
      online: number;
      standby: number;
      offline: number;
      total: number;
    };
    invoices: {
      [key: string]: number;
      bruttoSaldo: number;
      nettoSaldo: number;
      totalNetto: number;
    };
  };
  socketMembers: {
    name: string;
    id: string;
    initials?: string;
  }[];
}

export interface ICustomerNoteR {
  event: string;
  text: string;
  timestamp: string;
  user: {
    _id: string;
    name: string;
    email: string;
  };
}

export interface ICustomerHistorie {
  timestamp: Date;
  userName: string;
  event: string;
}

export type ICustomerNote = ICustomerNoteR & {
  user: {
    pictureUrl: string;
  };
};

export interface IEmailAddressDiscount {
  email: string;
  address: IAddress;
  discount?: number;
}

import { BottomNavComponent } from '@angular-mdc/web/bottom-nav';
import { MdcDrawer } from '@angular-mdc/web/drawer';
import { MdcMenu } from '@angular-mdc/web/menu';
import { MdcSelect } from '@angular-mdc/web/select';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MDCList, MDCListActionEventDetail, MDCListFoundation, MDCListIndex } from '@material/list';
import { MDCRipple } from '@material/ripple/component';
import { Subscription } from 'rxjs';
import { MdcCheckbox } from '../checkbox';

@Component({
  selector: 'mdc-list-item, a[mdc-list-item]',
  template: `<span *ngIf="!disabledRipple" class="mdc-deprecated-list-item__ripple"></span
    ><ng-content></ng-content>`,
  styleUrls: ['./list.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcListItem {
  @HostBinding('class') class = 'mdc-deprecated-list-item';
  @HostBinding('attr.role') role = 'option';

  private _id = `${uniqueIdCounter++}`;

  @HostBinding('id')
  get id(): string {
    return this._id;
  }

  @HostBinding('attr.data-value')
  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
  }
  private _value!: string;

  @Input()
  get hidden(): boolean {
    return this._hidden;
  }
  set hidden(value: boolean) {
    this._hidden = coerceBooleanProperty(value);
    if (this._hidden) this.elementRef.nativeElement.classList.add('cd-layout--hidden');
    else this.elementRef.nativeElement.classList.remove('cd-layout--hidden');
  }
  private _hidden = false;

  @Input()
  get disabledRipple(): boolean {
    return this._disabledRipple;
  }
  set disabledRipple(value: boolean) {
    this._disabledRipple = coerceBooleanProperty(value);
  }
  private _disabledRipple = false;

  @HostBinding('class.mdc-deprecated-list-item--disabled')
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
  }
  private _disabled = false;

  //   /** Whether the list item is activated. */
  @HostBinding('class.mdc-deprecated-list-item--activated')
  @Input()
  get activated(): boolean {
    return this._activated;
  }
  set activated(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._activated) {
      this._activated = newValue;
      this.changeDetectorRef.markForCheck();
    }
  }
  private _activated = false;

  @ContentChild(MdcCheckbox) private _mdcCheckbox!: MdcCheckbox;

  public get mdcCheckbox(): any {
    return this._mdcCheckbox;
  }

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>,
    private _cdRef: ChangeDetectorRef
  ) {}
}
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mdcListItemMeta], mdc-list-item-meta',
  exportAs: 'mdcListItemMeta'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MdcListItemMeta {
  @HostBinding('class') class = 'mdc-deprecated-list-item__meta';
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mdcListGroupSubheader], mdc-list-group-subheader',
  exportAs: 'mdcListGroupSubheader'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MdcListGroupSubheader {
  @HostBinding('class') class = 'mdc-deprecated-list-group__subheader';
  constructor(public elementRef: ElementRef) {}
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mdcListItemSecondary], mdc-list-item-secondary',
  exportAs: 'mdcListItemSecondary'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MdcListItemSecondary {
  @HostBinding('class') class = 'mdc-deprecated-list-item__secondary-text';
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Component({
  selector: '[mdcListGroup], mdc-list-group',
  template: ` <h3 class="mdc-deprecated-list-group__subheader" *ngIf="subheader">
      {{ subheader }}
    </h3>
    <ng-content></ng-content>`,
  styleUrls: ['./list.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcListGroup {
  @HostBinding('class') class = 'mdc-deprecated-list-group';

  @Input() subheader?: string;

  constructor(public elementRef: ElementRef) {}
}

let uniqueIdCounter = 0;
@Component({
  selector: 'mdc-list',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./list.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcList implements AfterViewInit, OnDestroy {
  @HostBinding('class') class = 'mdc-deprecated-list';

  private _mdcList!: MDCList;

  @HostListener('click', ['$event'])
  private onClick($event: MouseEvent): void {
    $event.stopPropagation();
    if (this._menu?.checkBox) {
      const tempCheck: number[] = [];
      this._listItems.forEach((listEl, index) => {
        if (listEl.id === ($event?.target as any).id) {
          if (listEl.mdcCheckbox) listEl.mdcCheckbox.checked = !listEl.mdcCheckbox.checked;
        }
        if (listEl.mdcCheckbox.checked) tempCheck.push(index);
      });
      this.selectedCheckboxen.emit(tempCheck);
    }
  }

  // #region MdcList properties
  @Input()
  get selectedCheckBoxIndexes(): number[] {
    return this._selectedCheckBoxIndexes;
  }
  set selectedCheckBoxIndexes(values: number[]) {
    if (JSON.stringify(this._selectedCheckBoxIndexes) === JSON.stringify(values)) return;
    this._selectedCheckBoxIndexes = values;
    this._listItems.forEach((listEl, index) => {
      if (listEl.mdcCheckbox) listEl.mdcCheckbox.checked = values.includes(index);
    });
  }

  private _selectedCheckBoxIndexes: number[] = [];

  @Input()
  get selectedIndex(): MDCListIndex | null {
    return this._mdcList ? this._mdcList.selectedIndex : (this._selectedIndex as MDCListIndex);
  }
  set selectedIndex(select: MDCListIndex | null) {
    const newValue = select !== undefined ? coerceNumberProperty(select) : -1;
    if (this._mdcList && !this._drawer && !this.multi) this._mdcList.selectedIndex = newValue;
    else this._selectedIndex = newValue;
    this.changeDetectorRef.markForCheck();
  }

  private _selectedIndex!: MDCListIndex | null;

  get typeaheadInProgress(): boolean {
    return this._mdcList.typeaheadInProgress;
  }

  get listElements(): HTMLElement[] {
    return this._mdcList.listElements;
  }
  //#endregion

  @Input()
  set singleSelection(select: boolean) {
    this._singleSelection = coerceBooleanProperty(select);
  }
  private _singleSelection!: boolean;

  @Input()
  set wrapFocus(wrapFocus: boolean) {
    this._wrapFocus = coerceBooleanProperty(wrapFocus);
  }
  private _wrapFocus!: boolean;

  @Input()
  set vertical(vertical: boolean) {
    this._vertical = coerceBooleanProperty(vertical);
  }
  private _vertical = true;

  @Input()
  set hasTypeahead(value: boolean) {
    this._hasTypeahead = coerceBooleanProperty(value);
  }
  private _hasTypeahead!: boolean;

  @HostBinding('class.mdc-deprecated-list--avatar-list')
  @Input()
  get avatar(): boolean {
    return this._avatar;
  }
  set avatar(value: boolean) {
    this._avatar = coerceBooleanProperty(value);
  }
  private _avatar = false;

  @HostBinding('class.mdc-deprecated-list--dense')
  @Input()
  get dense(): boolean {
    return this._dense;
  }
  set dense(value: boolean) {
    this._dense = coerceBooleanProperty(value);
  }
  private _dense = false;

  @Input()
  get multi(): boolean {
    return this._multi;
  }
  set multi(value: boolean) {
    this._multi = coerceBooleanProperty(value);
  }
  private _multi = false;

  @HostBinding('class.mdc-deprecated-list--thumbnail-list')
  @Input()
  get thumbnail(): boolean {
    return this._thumbnail;
  }
  set thumbnail(value: boolean) {
    this._thumbnail = coerceBooleanProperty(value);
  }
  private _thumbnail = false;

  @HostBinding('class.mdc-deprecated-list--two-line')
  @Input()
  get twoLine(): boolean {
    return this._twoLine;
  }
  set twoLine(value: boolean) {
    this._twoLine = coerceBooleanProperty(value);
  }
  private _twoLine = false;

  @Output()
  readonly selectedItem: EventEmitter<MDCListActionEventDetail> =
    new EventEmitter<MDCListActionEventDetail>();

  @Output()
  readonly selectedCheckboxen: EventEmitter<number[]> = new EventEmitter<number[]>();

  @ContentChildren(MdcListItem)
  private _listItems!: QueryList<MdcListItem>;

  private _routerSub!: Subscription;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    public changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    @Optional() private _drawer: MdcDrawer,
    @Optional() private _select: MdcSelect,
    @Optional() private _menu: MdcMenu,
    @Optional() private _bottomNav: BottomNavComponent
  ) {
    if (!this._drawer) return;
    this._routerSub = this._router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this._listItems.forEach((listItem) => {
          listItem.elementRef.nativeElement.classList.remove('mdc-deprecated-list-item--selected');
        });
      }
    });
  }

  //#region Lifecycle
  ngAfterViewInit(): void {
    if (
      this._drawer?.modal ||
      this._drawer?.dismissible ||
      this._select ||
      (this._menu && !this._menu?.checkBox && !this._bottomNav)
    )
      return;
    else if (this._drawer && !this._drawer.modal && !this._drawer.dismissible) {
      this.wrapFocus = true;
      this._singleSelection = true;
    }
    this._mdcList = MDCList.attachTo(this._elementRef.nativeElement);
    this._mdcList.singleSelection = this._singleSelection;
    this._mdcList.vertical = this._vertical;
    this._mdcList.wrapFocus = this._wrapFocus;
    this._mdcList.hasTypeahead = this._hasTypeahead;
    if (this.selectedIndex && !this.multi && !this._drawer)
      this.selectedIndex = this._selectedIndex;

    if (this._menu?.checkBox && this.selectedCheckBoxIndexes) {
      setTimeout(() => {
        this._listItems.forEach((listEl, index) => {
          if (this.selectedCheckBoxIndexes.some((selectIndex) => selectIndex === index))
            if (listEl.mdcCheckbox) listEl.mdcCheckbox.checked = true;
        });
      }, 0);
    }

    const listItem = this.listElements.map((listItemEl) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      new MDCRipple(listItemEl);
    });
    this._mdcList.listen('MDCList:action', ($event: CustomEvent<MDCListActionEventDetail>) => {
      $event.stopPropagation();
      if (
        this.listElements[$event.detail.index]?.parentElement?.classList.contains(
          'mdc-menu-surface--anchor'
        )
      )
        return;
      if (!this.multi && !this._drawer) this.selectedIndex = $event.detail.index;
      if (this.multi) {
        const mdcCheckbox = this._listItems.get($event.detail.index)?.mdcCheckbox;
        if (mdcCheckbox) mdcCheckbox.checked = !mdcCheckbox.checked;
      }
      if (!this._drawer) {
        this._listItems.forEach((listEl, index) => {
          if (
            listEl.elementRef.nativeElement.classList.contains(
              'mdc-deprecated-list-item--activated'
            )
          )
            listEl.activated = true;
          else listEl.activated = false;
        });
      }
      this.selectedItem.emit($event.detail);
    });
    this._selectedIndex = null;
  }

  ngOnDestroy(): void {
    if (
      this._drawer?.modal ||
      this._drawer?.dismissible ||
      this._select ||
      (this._menu && !this._bottomNav)
    )
      return;
    this._mdcList.destroy();
  }
  //#endregion

  //#region MdcList methodes
  layout(): void {
    this._mdcList.layout();
  }

  getDefaultFoundation(): MDCListFoundation {
    return this._mdcList.getDefaultFoundation();
  }

  getPrimaryText(item: Element): string {
    return this._mdcList.getPrimaryText(item);
  }

  setEnabled(itemIndex: number, isEnabled: boolean): void {
    this._mdcList.setEnabled(itemIndex, isEnabled);
  }

  initializeListType(): void {
    this._mdcList.initializeListType();
  }
  //#endregion
}

@Component({
  selector: 'mdc-list-item-text, [mdc-list-item-text]',
  template: `
    <!-- <span [ngClass]="{ 'mdc-deprecated-list-item__primary-text': this.secondaryText }"> -->
    <ng-content></ng-content>
    <!-- </span> -->
    <span class="mdc-deprecated-list-item__secondary-text" *ngIf="this.secondaryText">{{
      this.secondaryText
    }}</span>
  `,
  styleUrls: ['./list.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcListItemText {
  @HostBinding('class') class = 'mdc-deprecated-list-item__text';

  @Input() get secondaryText(): string {
    return this._secondaryText;
  }
  set secondaryText(value: string) {
    this._secondaryText = value;
  }

  private _secondaryText!: string;
  constructor(elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mdcListItemGraphic], mdc-list-item-graphic',
  exportAs: 'mdcListItemGraphic'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class MdcListItemGraphic {
  @HostBinding('class') class = 'mdc-deprecated-list-item__graphic';
  @HostBinding('attr.aria-hidden') hidden = true;
  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

import { Action, createReducer, on } from '@ngrx/store';
import { ICustomColumn } from '.';
import * as fromTableActions from './table.actions';
import * as fromTableState from './table.state';

const initialState: fromTableState.ITableStoreState = { ...fromTableState.tableState };
const _tableReducer = createReducer(
  initialState,
  on(
    fromTableActions.setupTable,
    (state, { tableName, enabledColumns, enabledConditions, zoom, length }) => {
      if (Object.prototype.hasOwnProperty.call(state, tableName)) return state;

      const presetColumns: ICustomColumn[] = [
        { isDefault: true, enabledColumns: [], name: 'Auswahl leeren' },
        { isDefault: true, enabledColumns: [], name: 'Alles anzeigen' }
      ];

      if (enabledColumns.length > 0)
        presetColumns.push({ isDefault: true, enabledColumns, name: 'Standard' });

      return {
        ...state,
        [tableName]: {
          enabledColumns,
          enabledConditions,
          presetColumns,
          zoom,
          length
        }
      };
    }
  ),
  on(fromTableActions.updateEnabledColumns, (state, { columns, tableName }) => {
    const newState: fromTableState.ITableStoreState = JSON.parse(JSON.stringify(state));
    newState[tableName].enabledColumns = columns;
    return newState;
  }),
  on(fromTableActions.updateEnabledConditions, (state, { conditions, tableName }) => {
    const newState: fromTableState.ITableStoreState = JSON.parse(JSON.stringify(state));
    newState[tableName].enabledConditions = conditions;
    return newState;
  }),
  on(fromTableActions.applyPreset, (state, { index, tableName }) => {
    const newState: fromTableState.ITableStoreState = JSON.parse(JSON.stringify(state));

    newState[tableName].enabledColumns = newState[tableName].presetColumns[index].enabledColumns;

    return newState;
  }),
  on(fromTableActions.storeAsPreset, (state, { tableName, name }) => {
    const newState: fromTableState.ITableStoreState = JSON.parse(JSON.stringify(state));

    const currentIndex = newState[tableName].presetColumns.findIndex((p) => p.name === name);

    if (currentIndex === -1) {
      newState[tableName].presetColumns.push({
        name: name,
        isDefault: false,
        enabledColumns: newState[tableName].enabledColumns
      });
    } else if (!newState[tableName].presetColumns[currentIndex].isDefault) {
      newState[tableName].presetColumns[currentIndex].enabledColumns =
        newState[tableName].enabledColumns;
    }

    return newState;
  }),
  on(fromTableActions.deletePreset, (state, { tableName, index }) => {
    const newState: fromTableState.ITableStoreState = JSON.parse(JSON.stringify(state));

    newState[tableName].presetColumns.splice(index, 1);

    return newState;
  }),
  on(fromTableActions.updateZoom, (state, { zoom, tableName }) => {
    const newState: fromTableState.ITableStoreState = JSON.parse(JSON.stringify(state));

    newState[tableName].zoom = zoom;

    return newState;
  }),
  on(fromTableActions.updateLength, (state, { length, tableName }) => {
    const newState: fromTableState.ITableStoreState = JSON.parse(JSON.stringify(state));

    newState[tableName].length = length;

    return newState;
  })
);

export function tableReducer(
  state: fromTableState.ITableStoreState,
  action: Action
): fromTableState.ITableStoreState {
  return _tableReducer(state, action);
}

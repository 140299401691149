import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { selectTokenData } from 'src/app/auth/store/auth.selectors';
import { IAuthState } from 'src/app/auth/store/auth.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public socket!: Socket;
  public socketConnected$ = new BehaviorSubject<boolean>(false);

  private _socketListeners: { [key: string]: string[] } = {};
  private _mirrorDocumentIds: { [key: string]: string[] } = {};
  private _query = {
    userId: '',
    token: '',
    userName: ''
  };

  constructor(private _storeAuth: Store<IAuthState>) {
    this.init();
  }

  private init(): void {
    this._storeAuth.select(selectTokenData).subscribe((tokenData) => {
      if (!tokenData) {
        // Disconnect as the user drops his tokenData (logout)
        if (this.socket) this.socket.disconnect();

        return;
      }
      this._query.userId = tokenData?.id;
      this._query.token = tokenData?.access_token;
      this._query.userName = tokenData?.username;

      if (!this.socket || !this.socket.connected) {
        this.socket = io(environment.API_URL, {
          query: {
            userId: tokenData?.id,
            token: tokenData?.access_token,
            userName: tokenData?.username
          }
        });
        this.socket.on('connect', () => {
          this.socketConnected$.next(true);
          if (Object.keys(this._mirrorDocumentIds).length) {
            this.socket.emit('reconnect', this._mirrorDocumentIds);
          }
        });

        this.socket.on('disconnect', () => {
          this.socketConnected$.next(false);
        });

        this.socket.on('mirror documentIds', (mirrorDocumentIds) => {
          this._mirrorDocumentIds = mirrorDocumentIds;
        });
      }
    });
  }

  public addCollectionListener<T>(
    collectionName: string,
    callback?: (data: IListenerCallback<T>) => void
  ): void {
    const listernerkey = 'collections';
    if (!this._socketListeners[listernerkey]) this._socketListeners[listernerkey] = [];
    if (this._socketListeners[listernerkey].includes(collectionName)) return;
    this._socketListeners[listernerkey].push(collectionName);
    this.socket.on(
      `collection-${collectionName}`,
      callback ||
        ((
          data: IListenerCallback<T>,
          id: string,
          users: { userName: string; socketId: string }[]
        ) => {
          console.log(`[socketService] - ${collectionName}`, data, id, users);
        })
    );
  }

  public removeCollectionListener(collectionName: string): void {
    const sockListerner = this._socketListeners['collections'];
    if (!sockListerner) return;
    this.socket.off(`collection-${collectionName}`);
    this._socketListeners['collections'] = sockListerner.filter((id) => id !== collectionName);
    if (sockListerner.length === 0) {
      delete this._socketListeners['collections'];
    }
    this.socket.emit('remove collection', collectionName);
  }

  public addListeners<T>(
    identifier: string,
    ids: string[],
    callback?: (
      data: IListenerCallback<T>,
      id: string,
      users: { userName: string; socketId: string }[]
    ) => void
  ): void {
    if (!this._socketListeners[identifier]) this._socketListeners[identifier] = [];
    const newIds = ids.filter((id) => !this._socketListeners[identifier].includes(id));
    if (!newIds.length) return;
    this._socketListeners[identifier].push(...newIds);
    newIds.forEach((id) => {
      this.socket.on(
        `updateDocument-${id}`,
        callback ||
          ((
            data: IListenerCallback<T>,
            id: string,
            users: { userName: string; socketId: string }[]
          ) => {
            console.log('[socketService] - updateDocument', data, id, users);
          })
      );
    });
  }

  public removeListeners(identifier: string, removeIds: string[]): void {
    if (!this._socketListeners[identifier]) return;
    this._socketListeners[identifier] = this._socketListeners[identifier].filter(
      (id) => !removeIds.includes(id)
    );
    if (this._socketListeners[identifier].length === 0) {
      delete this._socketListeners[identifier];
    }
    removeIds.forEach((id) => {
      this.socket.off(`updateDocument-${id}`);
    });
    this.socket.emit('remove ids', identifier, removeIds);
  }
}

export interface IListenerCallback<T> {
  updatedFields: Partial<T>;
  removedFields?: string[];
  operationType: 'insert' | 'delete' | 'update' | 'replace';
}

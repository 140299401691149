import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector
} from '@ngrx/store';
import { cloneDeep } from 'lodash';
import * as fromProfileState from './profile.state';

const userFeatureState = createFeatureSelector<fromProfileState.IProfileState>(
  fromProfileState.profileStateName
);

export const selectProfileData = createSelector(userFeatureState, (state) => state);

export const selectProfileId = createSelector(userFeatureState, (state) => state._id);

export const selectProfileRole = createSelector(userFeatureState, (state) => state.role);

export const selectCanEditInvoice = createSelector(userFeatureState, (state) => {
  return state.role === 'superadmin' || state._id === '552be38b5d95d3a53b88a5e3';
});

export const selectProfileChatLayout = createSelector(
  userFeatureState,
  (state) => state.chatLayout
);

export const selectProfileFabSize = createSelector(
  userFeatureState,
  (state) => state.fabSize || { h: 0, w: 0 }
);
export const selectProfileFabOpen = createSelector(
  userFeatureState,
  (state) => state.fabOpen || false
);

export const selectThemeNameToken = createSelector(
  userFeatureState,
  (state: fromProfileState.IProfileState) => {
    return state.selectedTheme;
  }
);

export const selectExpansionPanel = (
  id: string
): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(userFeatureState, (state) => {
    return state.expansionPanels ? state.expansionPanels[id] : false;
  });

export const selectMenuCollapse = createSelector(userFeatureState, (state) =>
  state.menuCollapse !== undefined ? state.menuCollapse : false
);

export const selectThemeOptions = createSelector(userFeatureState, (s) => {
  const state = cloneDeep(s);
  if (!state.themeOptions) {
    return {
      disableBorderRadius: false,
      disableBoxShadow: false
    };
  }
  if (state.themeOptions.disableBorderRadius === undefined) {
    state.themeOptions.disableBorderRadius = false;
  }
  if (state.themeOptions.disableBoxShadow === undefined) {
    state.themeOptions.disableBoxShadow = false;
  }
  return state.themeOptions;
});

import { MdcBadgeModule } from '@angular-mdc/web/badge';
import { MdcBottomNavModule } from '@angular-mdc/web/bottom-nav';
import { MdcButtonModule } from '@angular-mdc/web/button';
import { MdcDialogModule } from '@angular-mdc/web/dialog';
import { MdcIconModule } from '@angular-mdc/web/icon';
import { MdcListModule } from '@angular-mdc/web/list';
import { MdcMenuModule } from '@angular-mdc/web/menu';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/core-common.module';
import { FabViewModule } from 'src/app/main/apps/chat/views/fab/fab-view.module';
// import { RoleDirectivesModule } from 'src/app/main/directives';
import { BottomMenuComponent } from './bottomMenu.component';

@NgModule({
  imports: [
    CoreCommonModule,
    FabViewModule,
    MdcBadgeModule,
    MdcBottomNavModule,
    MdcButtonModule,
    MdcDialogModule,
    MdcIconModule,
    MdcListModule,
    MdcMenuModule,
    RouterModule
    // RoleDirectivesModule
  ],
  declarations: [BottomMenuComponent],
  exports: [BottomMenuComponent]
})
export class BottomMenuModule {}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private _router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          return event;
        },
        (error: HttpErrorResponse) => {
          console.log('ResponseInterceptor error', error);
          switch (error.status) {
            case 302:
              this._router.navigate([error.error]);
              break;
            case 401:
              this._router.navigate(['/account/logout']);
              break;
          }
        }
      )
    );
  }
}

import { ThemeService } from '@angular-mdc/web/theme';
import { ApplicationRef, Inject, Injectable, InjectionToken } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { ICoreConfig, ICoreConfigOpts } from '@core/interfaces/config.interface';
import { Store } from '@ngrx/store';
import { cloneDeep, isEqual, merge } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { IProfileStore, selectThemeNameToken } from 'src/app/main/store/profile';

export const CORE_CUSTOM_CONFIG = new InjectionToken('coreCustomConfig');

@Injectable()
export class CoreConfigService {
  public onSearch = new BehaviorSubject<string>('');
  public get config(): Observable<ICoreConfig> {
    return this._configSubject.asObservable();
  }
  private _configSubject!: BehaviorSubject<ICoreConfig>;

  private _defaultConfig!: ICoreConfig;

  constructor(
    private _router: Router,
    private _appRef: ApplicationRef,
    private _themeService: ThemeService,
    private _profileStore: Store<IProfileStore>,
    @Inject(CORE_CUSTOM_CONFIG) private _config: ICoreConfig
  ) {
    this._defaultConfig = this._config;
    this.initConfig();
  }

  private initConfig(): void {
    const defaultConfig = cloneDeep(this._defaultConfig);
    if (this._router.url.indexOf('accounts/login')) {
      defaultConfig.layout.menu.hidden = true;
      defaultConfig.layout.search.hidden = true;
      defaultConfig.layout.content.maxWidth = '100%';
    }
    this._configSubject = new BehaviorSubject(defaultConfig);

    this._router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe((event) => {
      this._profileStore
        .select(selectThemeNameToken)
        .pipe(take(1))
        .subscribe((storedTheme) => {
          const themeType =
            storedTheme === 'system'
              ? this._themeService.isDarkMode
                ? 'dark'
                : 'light'
              : storedTheme;
          if (event instanceof ResolveEnd && event.url.indexOf('/kunde/') > -1) return;
          this._themeService.setActiveTheme(`${themeType}Sorglos`, true);
        });
      const defaultConfig = cloneDeep(this._defaultConfig);
      if (!isEqual(this._configSubject.getValue().layout, defaultConfig.layout)) {
        const config = cloneDeep(this._configSubject.getValue());
        config.layout = cloneDeep(defaultConfig.layout);
        this._configSubject.next(config);
        this._appRef.tick();
      }
    });
  }

  public setConfig(config: ICoreConfigOpts): void {
    this._configSubject.next(merge({}, this._configSubject.getValue(), config));
  }
}

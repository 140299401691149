export class Validations {
  // TODO: these don't need to be methods, they can be static properties
  public static plzTextfield(): { regEx: string; text: string } {
    return {
      regEx: '^[0-9]{4,}$',
      text: 'Min. 4 Zeichen, Nur Zahlen, [0-9]'
    };
  }

  public static phoneTextfield(): { regEx: string; text: string } {
    return {
      regEx: '^[\\+0-9\\s]{0,}$',
      text: 'Nur Zahlen, [0-9] und Leerzeichen'
    };
  }

  public static nameTextfield(): { regEx: string; text: string } {
    return {
      regEx: '^([a-zA-Z0-9ßäöüÄÖÜ\\/.\\-\\s]{0,})$',
      text: 'Erlaubt: [a-z, A-Z, 0-9, /-.]'
    };
  }

  public static streetTextfield(): { regEx: string; text: string } {
    return {
      regEx: '^([a-zA-Z0-9ßäöüÄÖÜ.\\-\\s]{0,})$',
      text: 'Erlaubte: [a-z, A-Z, 0-9, -]'
    };
  }

  public static ibanTextfield(): { regEx: string; text: string } {
    return {
      regEx: '^[DE|de|AT|at]{2}(?:[ ]?[0-9]){20}$',
      text: 'Bitte trage eine gültige IBAN ein'
    };
  }
  public static emailTextfield(): { regEx: string; text: string } {
    return {
      regEx: '^[\\w\\-\\.]+@([\\w\\-]+\\.)+[\\w\\-]{2,}$',
      text: 'Bitte trage eine gültige Email ein'
    };
  }

  public static ssidTextfield(): { regEx: string; text: string } {
    return {
      regEx: '^[a-zA-Z0-9äÄöÖüÜ_!"#$§%&\\(\\)*+,\\-.\\/:;<=>?@`\\{\\|\\}~ ]{0,}$',
      text: '[a-z, A-Z, 0-9, _! "#$§%&()*+,-./:;<=>?@`{|}~]'
    };
  }

  public static keyTextfield(): { regEx: string; text: string } {
    return {
      regEx: '^[a-zA-Z0-9_!"#$%&\\(\\)*+,\\-.\\/:;<=>?@\\{\\|\\}~]{8,}$',
      text: 'Min. 8 Zeichen, [a-z, A-Z, 0-9, _!"#$%&()*+,-./:;<=>?@{|}~]'
    };
  }

  public static startTextfield(): { regEx: string } {
    return {
      regEx: '^[0-9]{0,}$'
    };
  }

  public static limitTextfield(): { regEx: string } {
    return {
      regEx: '^[0-9]{0,}$'
    };
  }

  public static lanIpTextfield(): { regEx: string } {
    return {
      regEx:
        '(^192.168.([0-9]|[1-9][0-9]|[1-2][0-5][0-5]).([0-9]|[1-9][0-9]|[1-2][0-5][0-5])$)|(^172.([1][6-9]|[2][0-9]|[3][0-1]).([0-9]|[1-9][0-9]|[1-2][0-5][0-5]).([0-9]|[1-9][0-9]|[1-2][0-5][0-5])$)|(^10.([0-9]|[1-9][0-9]|[1-2][0-5][0-5]).([0-9]|[1-9][0-9]|[1-2][0-5][0-5]).([0-9]|[1-9][0-9]|[1-2][0-5][0-5])$)'
    };
  }

  public static ipTextField: { regEx: string; text: (val: string) => string } = {
    regEx: '^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$',
    text: (val: string) => {
      return `Keine gültige ${val}`;
    }
  };

  public static maskMacNumber(value: string): string {
    if (!value || value === null) return '';
    const regexStr = '^(x{1,2}(:x{0,2})?(:[0-9]{0,3})?)?$|^[0-9A-F]{0,12}$';
    if (!new RegExp(regexStr).test(value)) {
      return value.toUpperCase().replace(/[^A-F0-9]/g, '');
    }
    return value;
  }
  public static keyMacNumber(event: KeyboardEvent): void {
    const regexStr = '^[A-FX:0-9]*$';
    if (!new RegExp(regexStr).test(event.key.toUpperCase())) {
      event.preventDefault();
    }
  }
}

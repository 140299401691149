import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[mdcDrawerTitle]',
  exportAs: 'mdcDrawerTitle'
})
export class MdcDrawerTitle {
  @HostBinding('class') class = 'mdc-drawer__title';
}

@Directive({
  selector: '[mdcDrawerSubtitle]',
  exportAs: 'mdcDrawerSubtitle'
})
export class MdcDrawerSubtitle {
  @HostBinding('class') class = 'mdc-drawer__subtitle';
}

@Directive({
  selector: 'mdc-drawer-content, [mdcDrawerContent]',
  exportAs: 'mdcDrawerContent'
})
export class MdcDrawerContent {
  @HostBinding('class') class = 'mdc-drawer__content';
}

@Directive({
  selector: 'mdc-drawer-app-content, [mdcDrawerAppContent]',
  exportAs: 'mdcDrawerAppContent'
})
export class MdcDrawerAppContent {
  @HostBinding('class') class = 'mdc-drawer-app-content';
}

import { MdcFloatingLabelModule } from '@angular-mdc/web/floating-label';
import { MdcFormFieldModule } from '@angular-mdc/web/form-field';
import { MdcLineRippleModule } from '@angular-mdc/web/line-ripple';
import { MdcMenuModule } from '@angular-mdc/web/menu';
import { MdcNotchedOutlineModule } from '@angular-mdc/web/notched-outline';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MdcSelect } from './select';
import { MdcSelectHelperText, MdcSelectIcon } from './select-icon';

@NgModule({
  imports: [
    CommonModule,
    MdcMenuModule,
    MdcFormFieldModule,
    MdcFloatingLabelModule,
    MdcNotchedOutlineModule,
    MdcLineRippleModule
  ],
  exports: [MdcMenuModule, MdcFormFieldModule, MdcSelect, MdcSelectIcon, MdcSelectHelperText],
  declarations: [MdcSelect, MdcSelectIcon, MdcSelectHelperText]
})
export class MdcSelectModule {}

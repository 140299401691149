import { MdcElevationModule } from '@angular-mdc/web/elevation';
import { MdcIconButtonModule } from '@angular-mdc/web/icon-button';
import { MdcListModule } from '@angular-mdc/web/list';
import { MdcMenuModule } from '@angular-mdc/web/menu';
import { MdcTabBarModule } from '@angular-mdc/web/tab-bar';
import { MdcTopAppBarModule } from '@angular-mdc/web/top-app-bar';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/core-common.module';
import { NavbarComponent } from './navbar.component';

@NgModule({
  imports: [
    CoreCommonModule,
    MdcElevationModule,
    MdcIconButtonModule,
    MdcMenuModule,
    MdcListModule,
    MdcTabBarModule,
    MdcTopAppBarModule,
    RouterModule
  ],
  declarations: [NavbarComponent],
  exports: [NavbarComponent]
})
export class NavbarModule {}

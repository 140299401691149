import { ChangeDetectorRef, inject } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';

export class ControlValueBase<ValueType> implements ControlValueAccessor, Validator {
  public onTouched: () => void = () => {};
  public onChange: (v: ValueType) => void = () => {};
  public set value(v: ValueType) {}
  public get value(): ValueType {
    return null as unknown as ValueType;
  }
  public cdRef = inject(ChangeDetectorRef);

  private _validators: { [key: string]: ValidatorFn } = {};

  constructor() {}

  //#region ControlValueAccessor
  public writeValue(value: ValueType): void {
    if (this.value !== value) {
      if (value === null || value === undefined) {
        value = (typeof this.value === 'boolean' ? false : '') as unknown as ValueType;
      }

      this.value = value;
      this.onChange(value);
      this.cdRef.detectChanges();
    }
  }
  public registerOnChange(fn: (v: ValueType) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  //#endregion

  //#region Validator
  public validate(control: AbstractControl): ValidationErrors | null {
    const validators: ValidatorFn[] = Object.values(this._validators);
    const validatorFn = Validators.compose(validators);
    if (!validatorFn) return null;
    return validatorFn(control);
    // return null;
  }

  //#region Custom Validator Helper
  public addValidator(validator: ValidatorFn, name: string): boolean {
    if (this._validators[name]) return false;
    this._validators[name] = validator;
    return true;
  }

  public removeValidator(name: string): boolean {
    if (!this._validators[name]) return false;
    delete this._validators[name];
    return true;
  }
  //#endregion
  //#endregion

  public static getProvider(component: any): any[] {
    return [NG_VALUE_ACCESSOR, NG_VALIDATORS].map((p) => ({
      provide: p,
      useExisting: component,
      multi: true
    }));
  }
}

import { MdcElevationModule } from '@angular-mdc/web/elevation';
import { MdcFabModule } from '@angular-mdc/web/fab';
import { NgModule } from '@angular/core';
import { CoreCommonModule } from '@core/core-common.module';
import { FabViewComponent } from './fab-view.component';

@NgModule({
  imports: [CoreCommonModule, MdcElevationModule, MdcFabModule],
  declarations: [FabViewComponent],
  exports: [FabViewComponent]
})
export class FabViewModule {}

import { PipesModule } from '@angular-mdc/web/pipes';
import { NgModule } from '@angular/core';
import { ClickToCopyComponent, ClickToCopyDirective } from './clickToCopy.directive';
import { HighlightUpdateDirective } from './highlightUpdate.directive';

@NgModule({
  imports: [PipesModule],
  declarations: [ClickToCopyDirective, ClickToCopyComponent, HighlightUpdateDirective],
  exports: [ClickToCopyDirective, ClickToCopyComponent, HighlightUpdateDirective]
})
export class DirectivesModule {}

import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class CoreLoadingScreenService {
  private _loadingScreenEl!: HTMLElement;
  private _animationPlayer!: AnimationPlayer;

  private _mainLoaderHidden = false;

  public showNavigationLoader = false;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router,
    private _animationBuilder: AnimationBuilder
  ) {
    this.init();
  }

  private init(): void {
    this._loadingScreenEl = this._document.body.querySelector('.loading-background') as HTMLElement;

    this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (!this._mainLoaderHidden)
        setTimeout(() => {
          this._mainLoaderHidden = true;
          this.hide();
        });
    });
  }

  public hide(): void {
    this._animationPlayer = this._animationBuilder
      .build([
        style({ opacity: '1' }),
        animate(
          '200ms cubic-bezier(0.4, 0, 0.2, 1)',
          style({
            opacity: '0',
            zIndex: '-10'
          })
        )
      ])
      .create(this._loadingScreenEl);

    // setTimeout 250 prevents renderblocking elements skipping the fadeout animation
    setTimeout(() => {
      this._animationPlayer.play();
    }, 250);
  }
}

import { AfterViewInit, ElementRef, Injectable, OnDestroy } from '@angular/core';
import { MDCRipple } from '@material/ripple';

export interface MDCRippleCapableSurface {
  readonly root: Element;
  unbounded?: boolean;
  disabled?: boolean;
}

@Injectable()
export class MdcRipple implements AfterViewInit, OnDestroy {
  private _mdcRipple!: MDCRipple;
  initialized: boolean = false;

  constructor(public element: ElementRef<HTMLElement>) {}

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngAfterViewInit(): void {
    this.init();
  }

  init(): void {
    this._mdcRipple = MDCRipple.attachTo(this.element.nativeElement);
    this.initialized = true;
  }

  destroy(): void {
    if (this.initialized) {
      this.initialized = false;
      this._mdcRipple?.destroy();
    }
  }

  layout(): void {
    this._mdcRipple?.layout();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  activateRipple(): void {
    this._mdcRipple.activate();
  }

  deactivateRipple(): void {
    this._mdcRipple.deactivate();
  }
}

<div
  class="mdc-tab-scroller"
  [ngClass]="{
    'mdc-tab-scroller--align-start': align === 'start',
    'mdc-tab-scroller--align-center': align === 'center',
    'mdc-tab-scroller--align-end': align === 'end'
  }"
>
  <div #ScrollerArea class="mdc-tab-scroller__scroll-area">
    <div #Scroller class="mdc-tab-scroller__scroll-content">
      <ng-container *ngIf="list">
        <button
          #mdcTab
          class="mdc-tab"
          *ngFor="let item of list; let index = index"
          [ngClass]="{
            'mdc-tab--stacked': stacked,
            'mdc-tab--min-width': align || fixed,
            'cd-layout--none': item.hidden
          }"
          [routerLink]="item.route"
          (click)="selectTab(index)"
        >
          <span class="mdc-tab__content">
            <span
              class="cd-icons mdc-tab__icon material-icons"
              [ngClass]="{ 'cd-icons': item.customIcon }"
              aria-hidden="true"
              *ngIf="item.icon"
            >
              {{ item.icon }}
            </span>
            <ng-container *ngIf="item.icons">
              <span
                class="cd-icons mdc-tab__icon material-icons"
                [ngClass]="{ 'cd-icons': item.customIcon, 'cd-layout--margin-left-16': i > 0 }"
                aria-hidden="true"
                *ngFor="let icon of item.icons; let i = index"
              >
                {{ icon }}
              </span>
            </ng-container>
            <span
              class="mdc-tab__text-label"
              *ngIf="item.label"
              [ngClass]="{ 'cd-layout--none': onlyIcon }"
              >{{ item.label }}</span
            >
          </span>
          <span class="mdc-tab-indicator" [ngClass]="{ 'mdc-tab-indicator--fade': fade }">
            <span
              class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"
              *ngIf="!showIconIndicator"
            ></span>
            <span
              class="mdc-tab-indicator__content 'mdc-tab-indicator__content mdc-tab-indicator__content--icon material-icons"
              *ngIf="showIconIndicator"
              >{{ iconIndicator }}</span
            >
          </span>
          <span class="mdc-tab__ripple"></span>
        </button>
      </ng-container>
    </div>
  </div>
</div>

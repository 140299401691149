export function enumToArray<T>(enumType: T): (string | T)[] {
  return Object.values<T>(enumType as unknown as ArrayLike<T>).filter(
    (value) => typeof value === 'string'
  );
}

export enum EBox5G {
  'box5G',
  'boxPro5G',
  'appliance2Wifi'
}

export enum EAllBoxes {
  'box',
  'boxPro',
  'box5G',
  'boxPro5G',
  'appliance',
  'appliance1Wifi',
  'appliance2Wifi',
  'rack'
}

export enum EBoxPro {
  'boxPro',
  'boxPro5G'
}

export enum EBox1Wifi {
  'box',
  'boxPro',
  'appliance1Wifi'
}

export enum EBox2Wifi {
  'box5G',
  'boxPro5G',
  'appliance2Wifi'
}

export enum EBoxAppliance {
  'appliance',
  'appliance1Wifi',
  'appliance2Wifi',
  'rack'
}

export enum EBoxNoWifi {
  'appliance',
  'rack'
}

import { MdcBannerModule } from '@angular-mdc/web/banner';
import { MdcButtonModule } from '@angular-mdc/web/button';
import { MdcDialogModule } from '@angular-mdc/web/dialog';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/core-common.module';
import { BottomMenuModule } from './components/bottomMenu/bottomMenu.module';
import { MenuModule } from './components/menu/menu.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { LayoutComponent } from './layout.component';

@NgModule({
  imports: [
    BottomMenuModule,
    CoreCommonModule,
    MdcBannerModule,
    MdcButtonModule,
    MdcDialogModule,
    MenuModule,
    NavbarModule,
    RouterModule
  ],
  declarations: [LayoutComponent],
  exports: [LayoutComponent]
})
export class LayoutModule {}

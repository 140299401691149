import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';
import { breakpoint } from 'src/app/app-config';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  public resizeObserver: Subject<any> = new Subject();
  public windowWidth!: number;
  public windowHeight!: number;
  public location!: string;
  public breakpoint = breakpoint;

  public get isMobile(): boolean {
    return (
      /iPhone|iPod|Android/i.test(navigator.userAgent) &&
      (this.windowWidth < this.breakpoint.mobile || this.windowHeight < this.breakpoint.mobile)
    );
  }
  public get isTablet(): boolean {
    return (
      (/iPad|Android/i.test(navigator.userAgent) &&
        (this.windowWidth < this.breakpoint.tablet ||
          this.windowHeight < this.breakpoint.tablet)) ||
      (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints > 2)
    );
  }

  public get isTouch(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  public get isLandscape(): boolean {
    return document.documentElement.clientHeight < document.documentElement.clientWidth;
  }

  constructor(private _rf2: RendererFactory2, @Inject(PLATFORM_ID) private _platformId: Object) {
    if (!isPlatformBrowser(this._platformId)) return;
    this.windowWidth = document.documentElement.clientWidth;
    this.windowHeight = document.documentElement.clientHeight;
    this.location = window.location.pathname;
    const renderer = this._rf2.createRenderer(null, null);
    this.createResizeObservable(renderer);
  }

  private createResizeObservable(renderer: Renderer2): void {
    renderer.listen('window', 'resize', ($event) => {
      const newWidth = $event.target.innerWidth;
      if (this.windowWidth !== newWidth) this.windowWidth = newWidth;

      const newHeight = $event.target.innerHeight;
      if (this.windowHeight !== newHeight) this.windowHeight = newHeight;
      this.resizeObserver.next($event);
    });
  }
}

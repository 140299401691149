import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { RoleGuard } from './auth';

const routes: Routes = [
  { path: '', redirectTo: 'uebersicht', pathMatch: 'full' },
  {
    path: 'uebersicht',
    loadChildren: () =>
      import('./main/pages/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./main/pages/authentication/account.module').then((m) => m.AccountModule)
  },
  {
    path: 'aktionscode',
    canActivate: [],
    loadChildren: () =>
      import('./main/pages/actioncode/actioncode.module').then((m) => m.ActioncodeModule)
  },
  {
    path: 'kunde/:id',
    loadChildren: () =>
      import('./main/pages/customer-layout/customer-layout.module').then(
        (m) => m.CustomerLayoutModule
      )
  },
  {
    path: 'kunden',
    canActivate: [],
    loadChildren: () =>
      import('./main/pages/customers/customers.module').then((m) => m.CustomersModule)
  },
  // {
  //   path: 'customers/gs',
  //   canActivate: [],
  //   loadChildren: () =>
  //     import('./main/pages/customers/customers.module').then((m) => m.CustomersModule),
  //   data: {
  //     customerType: 'gs'
  //   }
  // },
  {
    path: 'rechnungen',
    canActivate: [],
    loadChildren: () =>
      import('./main/pages/invoices/invoices.module').then((m) => m.InvoicesModule)
  },
  {
    path: 'monitoring',
    canActivate: [],
    loadChildren: () =>
      import('./main/pages/monitoring/monitoring.module').then((m) => m.MonitoringModule)
  },
  {
    path: 'logistik',
    canActivate: [],
    loadChildren: () =>
      import('./main/pages/logistics/logistics.module').then((m) => m.LogisticsModule)
  },
  {
    path: 'server',
    canActivate: [],
    loadChildren: () => import('./main/pages/servers/servers.module').then((m) => m.ServersModule)
  },
  {
    path: 'kosten',
    canActivate: [RoleGuard],
    loadChildren: () => import('./main/pages/costs/costs.module').then((m) => m.CostsModule)
  },
  {
    path: 'chat/:id',
    canActivate: [],
    loadChildren: () =>
      import('./main/pages/chat-window/chat-window.module').then((m) => m.ChatWindowModule)
  },
  // {
  //   path: 'bestellen/paket',
  //   canActivate: [RoleGuard],
  //   loadChildren: () => import('./main/pages/extend/extend.module').then((m) => m.ExtendModule)
  // },
  // {
  //   path: 'bestellen/box',
  //   canActivate: [RoleGuard],
  //   loadChildren: () => import('./main/pages/order/order.module').then((m) => m.OrderModule)
  // },
  // {
  //   path: 'bestellen/kasse',
  //   canActivate: [RoleGuard],
  //   loadChildren: () =>
  //     import('./main/pages/check-out/check-out.module').then((m) => m.CheckOutModule)
  // },
  // {
  //   path: 'bestellen/abschluss',
  //   canActivate: [RoleGuard],
  //   loadChildren: () =>
  //     import('./main/pages/check-out-complete/check-out-complete.module').then(
  //       (m) => m.CheckOutCompleteModule
  //     )
  // },
  {
    path: 'profil',
    // canActivate: [RoleGuard],
    loadChildren: () => import('./main/pages/profile/profile.module').then((m) => m.ProfileModule)
  },
  {
    path: 'dev',
    canActivate: [RoleGuard],
    loadChildren: () => import('./main/pages/dev').then((m) => m.DEV_ROUTES)
  },
  // {
  //   path: 'splash/uebersicht',
  //   canActivate: [RoleGuard],
  //   loadChildren: () =>
  //     import('./main/pages/splash-overview/splash.module').then((m) => m.SplashModule)
  // },
  // {
  //   path: 'splash/editor',
  //   canActivate: [RoleGuard],
  //   loadChildren: () =>
  //     import('./main/pages/splash-editor/splash-editor.module').then((m) => m.SplashEditorModule)
  // },
  {
    path: '**',
    redirectTo: 'uebersicht'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: NoPreloading
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

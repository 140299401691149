import { MdcIcon } from '@angular-mdc/web/icon';
import { MdcRipple, MDCRippleCapableSurface } from '@angular-mdc/web/ripple';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { MDCIconButtonToggle } from '@material/icon-button';
import { Subscription } from 'rxjs';
import { MdcTopAppBar } from '../top-app-bar';

/** Change event object emitted by MdcIconButton. */
export class MdcIconButtonChange {
  constructor(public source: MdcIconButton, public value: any) {}
}

let nextUniqueId = 0;
const problemCounter = 0;

@Directive({
  selector: '[mdcIconOn]',
  exportAs: 'mdcIconOn'
})
export class MdcIconOn {
  @HostBinding('class') class = 'mdc-icon-button__icon--on';
}

@Component({
  selector: 'button[mdc-icon-button], a[mdc-icon-button], button[mdcIconButton], a[mdcIconButton]',
  templateUrl: 'icon-button.html',
  styleUrls: ['icon-button.scss'],
  providers: [MdcRipple],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MdcIconButton implements AfterViewInit, OnDestroy, MDCRippleCapableSurface {
  private _mdcIconButtonToggle!: MDCIconButtonToggle;
  private _uniqueId: string = `mdc-icon-button-${++nextUniqueId}`;

  @HostBinding('class') class = 'mdc-icon-button';
  @HostBinding('attr.aria-pressed') pressed!: boolean;

  @HostListener('click') click(): void {
    if (this.disabled) return;
    this.handleClick();
  }

  public root!: Element;

  @HostBinding('id')
  @Input()
  id: string = this._uniqueId;
  get inputId(): string {
    return `${this.id || this._uniqueId}`;
  }

  @Input()
  public set icon(v: string | undefined) {
    this._icon = v;
    this._changeDetectorRef.markForCheck();
  }
  public get icon(): string | undefined {
    return this._icon;
  }
  private _icon: string | undefined = undefined;

  @Input()
  public set iconClass(v: string) {
    this._iconClass = v;
    this._changeDetectorRef.markForCheck();
  }
  public get iconClass(): string {
    return this._iconClass;
  }
  private _iconClass!: string;

  /* Set aria label on state. */
  @HostBinding('attr.data-aria-label-on')
  @Input()
  labelOn?: string = undefined;

  /* Set aria label off state. */
  @HostBinding('attr.data-aria-label-off')
  @Input()
  labelOff?: string = undefined;

  @HostBinding('class.mdc-top-app-bar__action-item')
  hasTopAppBar = false;

  @Input() onIcon?: string | undefined = undefined;
  @Input() offIcon?: string | undefined = undefined;

  @HostBinding('class.mdc-icon-button--on')
  @Input()
  get on(): boolean {
    return this._on;
  }
  set on(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcIconButtonToggle) {
      this._on = newValue;
      this._changeDetectorRef.markForCheck();
    } else this._on = newValue;
  }
  private _on: boolean = false;

  @HostBinding('class.mdc-icon-button--display-flex')
  @Input()
  get flex(): boolean {
    return this._flex;
  }
  set flex(value: boolean) {
    this._flex = coerceBooleanProperty(value);
  }
  private _flex: boolean = false;

  @HostBinding('class.mdc-button--density-small')
  @Input()
  get small(): boolean {
    return this._small;
  }
  set small(value: boolean) {
    this._small = coerceBooleanProperty(value);
  }
  private _small: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    this.setDisabled(newValue);
  }
  private _disabled: boolean = false;

  @Input()
  get custom(): boolean {
    return this._custom;
  }
  set custom(value: boolean) {
    this._custom = coerceBooleanProperty(value);
  }
  private _custom: boolean = false;

  @Input()
  get outlined(): boolean {
    return this._outlined;
  }
  set outlined(value: boolean) {
    this._outlined = coerceBooleanProperty(value);
  }
  private _outlined: boolean = false;

  @Input()
  get highlight(): boolean {
    return this._highlight;
  }
  set highlight(value: boolean) {
    this._highlight = coerceBooleanProperty(value);
  }
  private _highlight = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly change: EventEmitter<{ isOn: boolean }> = new EventEmitter<any>();

  @ContentChildren(MdcIcon, { descendants: true }) public icons!: QueryList<MdcIcon>;

  private _dialogSubscription!: Subscription;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>,
    @Optional() private _topAppBar: MdcTopAppBar
  ) {
    this.root = this.elementRef.nativeElement;
    if (this._topAppBar) this.hasTopAppBar = true;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._mdcIconButtonToggle = MDCIconButtonToggle.attachTo(this.elementRef.nativeElement);
      this._mdcIconButtonToggle.listen('MDCIconButtonToggle:change', ($event: CustomEvent) => {
        if (this.highlight) this.updateHighlight();
        this.change.emit($event.detail);
      });
    }, 0);

    setTimeout(() => {
      this.layoutRipple();
    }, 250);
  }

  ngOnDestroy(): void {
    this._mdcIconButtonToggle?.destroy();
    if (this._dialogSubscription) this._dialogSubscription.unsubscribe();
  }

  /** Sets the button disabled state */
  setDisabled(disabled: boolean): void {
    this._disabled = coerceBooleanProperty(disabled);
    this.disabled ? this.root.setAttribute('disabled', '') : this.root.removeAttribute('disabled');
    this._changeDetectorRef.markForCheck();
  }

  handleClick(): void {
    if (this.icons?.length === 1) return;
    this.on = !this.on;
    this.elementRef.nativeElement.blur();
  }

  layoutRipple(): void {
    if (this._mdcIconButtonToggle) this._mdcIconButtonToggle.ripple.layout();
  }

  private updateHighlight(): void {
    document
      .querySelectorAll('.mdc-button--highlight')
      .forEach((el) => el.classList.remove('mdc-button--highlight'));

    this.elementRef.nativeElement.classList.add('mdc-button--highlight');
  }
}

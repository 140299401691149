import { ThemeService } from '@angular-mdc/web/theme';
import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { APP_NAME } from './app-config';
import { IProfileState, selectThemeOptions } from './main/store/profile';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public APP_NAME = APP_NAME;

  constructor(
    public themeService: ThemeService,
    private _profileStore: Store<IProfileState>,
    private _swUpdate: SwUpdate
  ) {
    this._profileStore.select(selectThemeOptions).subscribe((themeOptions) => {
      if (themeOptions.disableBorderRadius) document.body.classList.add('border-radius');
      else document.body.classList.remove('border-radius');
      if (themeOptions.disableBoxShadow) document.body.classList.add('box-shadow');
      else document.body.classList.remove('box-shadow');
    });

    if (!this._swUpdate.isEnabled) return;
    this._swUpdate.checkForUpdate();
    setInterval(() => this._swUpdate.checkForUpdate(), 1000 * 60 * 30);
  }
}

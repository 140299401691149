import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MdcButtonModule } from '../button';
import { MdcIconButtonModule } from '../icon-button';
import { MdcSnackbarComponent } from './snackbar.component';

@NgModule({
  imports: [CommonModule, RouterModule, MdcButtonModule, MdcIconButtonModule],
  exports: [MdcSnackbarComponent],
  declarations: [MdcSnackbarComponent]
})
export class MdcSnackbarModule {}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { MDCFormField, MDCFormFieldFoundation, MDCFormFieldInput } from '@material/form-field';
import { MdcFormFieldControl } from './form-field-control';

@Component({
  selector: 'mdc-form-field',
  styleUrls: ['form-field.scss'],
  templateUrl: 'form-field.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcFormField implements AfterContentInit, AfterViewInit, OnDestroy {
  private _mdcFormField!: MDCFormField;

  public label?: HTMLElement;

  public get input(): MDCFormFieldInput {
    return this._mdcFormField.input!;
  }
  public set input(v: MDCFormFieldInput) {
    this._mdcFormField.input = v;
  }

  @HostBinding('class.mdc-form-field') isHostClass = true;

  @HostBinding('class.mdc-form-field--fluid')
  @Input()
  get fluid(): boolean {
    return this._fluid;
  }
  set fluid(value: boolean) {
    this._fluid = coerceBooleanProperty(value);
  }
  private _fluid = false;

  @HostBinding('class.mdc-form-field--align-end')
  @Input()
  get alignEnd(): boolean {
    return this._alignEnd;
  }
  set alignEnd(value: boolean) {
    this._alignEnd = coerceBooleanProperty(value);
  }
  private _alignEnd = false;

  @HostBinding('class.mdc-form-field--nowrap')
  @Input()
  get nowrap(): boolean {
    return this._nowrap;
  }
  set nowrap(value: boolean) {
    this._nowrap = coerceBooleanProperty(value);
  }
  private _nowrap = false;

  @HostBinding('class.mdc-form-field--space-between')
  @Input()
  get spaceBetween(): boolean {
    return this._spaceBetween;
  }
  set spaceBetween(value: boolean) {
    this._spaceBetween = coerceBooleanProperty(value);
  }
  private _spaceBetween = false;

  @ContentChild(MdcFormFieldControl, { static: false })
  private _control!: MdcFormFieldControl<any>;

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  ngAfterContentInit(): void {
    if (!this._control) return;
    const control = this._control.elementRef.nativeElement;

    if (control?.nextElementSibling?.tagName === 'LABEL') {
      this.label = control.nextElementSibling;
      if (this.label && this._control.inputId)
        this.label!.setAttribute('for', this._control.inputId);
    }
  }

  ngAfterViewInit(): void {
    this._mdcFormField = MDCFormField.attachTo(this.elementRef.nativeElement);
    this.input = this._control as unknown as MDCFormFieldInput;
  }

  ngOnDestroy(): void {
    this.getDefaultFoundation().destroy();
  }

  getDefaultFoundation(): MDCFormFieldFoundation {
    return this._mdcFormField.getDefaultFoundation();
  }
}

import { MdcMenu } from '@angular-mdc/web/menu';
import { MdcTopAppBar } from '@angular-mdc/web/top-app-bar';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { CoreConfigService } from '@core/config/config.service';
import { ICoreConfig } from '@core/interfaces';
import { ResizeService } from '@core/services';
import { Store } from '@ngrx/store';
import { Observable, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { NavbarService } from 'src/app/main/services/navbar.service';
import {
  IProfileState,
  IProfileStore,
  getProfileData,
  selectMenuCollapse,
  selectProfileData
} from 'src/app/main/store/profile';
import * as fromProfileStore from 'src/app/main/store/profile/store';
import { Destinations } from '../../models/destinations.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  public config!: ICoreConfig;

  public themes = [
    { label: 'Hell', value: 'light', icon: 'light_mode' },
    { label: 'Dunkel', value: 'dark', icon: 'dark_mode' },
    { label: 'System', value: 'system', icon: 'brightness_4' }
  ];

  public userIconMenu = Destinations.userIconMenu;

  public profileData$!: Observable<IProfileState>;
  public selectedTheme$!: Observable<'light' | 'dark' | 'system'>;

  public get allowedFloating(): boolean {
    return (
      this.resizeService.windowWidth > this.resizeService.breakpoint.mobile &&
      this.resizeService.windowHeight > this.resizeService.breakpoint.mobile
    );
  }

  private _sub!: Subscription;

  @ViewChild('searchInputModel')
  private _searchInputModel!: NgModel;

  @ViewChild('menuSurface')
  private _menuSurface!: MdcMenu;

  @ViewChild('searchInput')
  private _searchInput!: ElementRef<HTMLInputElement>;

  @ViewChild('navTopAppBar')
  private _navTopAppBar!: MdcTopAppBar;

  private _menuCollapse$ = this._profileStore.select(selectMenuCollapse);
  private _menuCollapseSubscription!: Subscription;
  public menuCollapse = false;

  @Input()
  public isBannerOpen = false;

  constructor(
    public navbarService: NavbarService,
    public resizeService: ResizeService,
    public elementRef: ElementRef<HTMLElement>,
    private _profileStore: Store<IProfileStore>,
    private _coreConfigService: CoreConfigService,
    private _cdRef: ChangeDetectorRef
  ) {
    this.profileData$ = this._profileStore.select(selectProfileData);
    this._profileStore.dispatch(getProfileData());

    this.selectedTheme$ = this._profileStore.select(fromProfileStore.selectThemeNameToken);
    this._sub = this._coreConfigService.config.subscribe((config) => {
      this.config = config;
      if (this._searchInput) {
        setTimeout(() => {
          this._searchInput.nativeElement.focus();
        }, 250);
      }
    });

    this._sub.add(
      this._coreConfigService.onSearch.subscribe((value) => {
        if (this._searchInputModel) this._searchInputModel.control.setValue(value);
      })
    );
  }

  ngOnInit(): void {
    this._menuCollapseSubscription = this._menuCollapse$.subscribe((collapse) => {
      this.menuCollapse = collapse;
      this._cdRef.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    const resizeObserver = new ResizeObserver(() => {
      const widht = this.resizeService.isMobile
        ? this.resizeService.windowWidth
        : this.elementRef.nativeElement.offsetWidth;
      this._navTopAppBar.elementRef.nativeElement.style.width = widht + 'px';
    });

    resizeObserver.observe(this.elementRef.nativeElement);

    this._sub.add(
      this._searchInputModel.control.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((value: string) => {
          this._coreConfigService.onSearch.next(value);
          if (this._menuSurface && this.config.layout.search.allowedMenu)
            this._menuSurface.open = true;
        })
    );
  }

  public setTheme(themeType: 'light' | 'dark' | 'system'): void {
    this._profileStore.dispatch(fromProfileStore.setSelectedTheme({ theme: themeType }));
  }

  public selectMenu(value: string): void {
    this._searchInput.nativeElement.value = value;
    this._searchInputModel.control.setValue(value);
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    if (this._menuCollapseSubscription) this._menuCollapseSubscription.unsubscribe();
  }
}

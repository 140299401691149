import { ThemeService } from '@angular-mdc/web/theme';
import { ITheme } from '@angular-mdc/web/theme/theme.service';
import { PortalModule } from '@angular/cdk/portal';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { injectApxConfig } from '@sorglosinternet/apx-toolbar';
import { DIRECTION_ALL } from 'hammerjs';
import moment from 'moment';
import { authReducer } from 'src/app/auth/store';
import { AuthEffects } from 'src/app/auth/store/auth.effects';
import { storageMetaReducer } from 'src/app/main/store/metareducers';
import * as fromProfileStore from 'src/app/main/store/profile/store';
import { StoreModule as NrgxStoreModule } from 'src/app/main/store/store.module';
import { environment } from 'src/environments/environment';
import { APP_NAME, coreConfig } from './app-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth';
import { LayoutModule } from './layout/layout.module';
import { FindCustomerDialogService } from './main/apps/find-customer/find-customer-dialog.service';

registerLocaleData(localeDe);

@Injectable()
export class HammerCustomConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: DIRECTION_ALL,
      threshold: 0
    }
  };
  options = {
    cssProps: {
      userSelect: 'auto'
    }
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    LayoutModule,
    PortalModule,
    BrowserAnimationsModule,
    BrowserModule,
    EffectsModule.forRoot([AuthEffects]),
    HammerModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    CoreModule.forRoot(coreConfig),
    AuthModule,
    StoreModule.forRoot(
      { auth: authReducer },
      {
        metaReducers: [storageMetaReducer as any]
      }
    ),
    StoreDevtoolsModule.instrument({
      name: 'mein ' + APP_NAME === 'gs' ? 'godspot' : 'sorglos',
      maxAge: 25,
      logOnly: false // Read from ENV or set to true when deploying.
    }),
    NrgxStoreModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerCustomConfig
    },
    FindCustomerDialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public selectedTheme$ = this._storeProfile.select(fromProfileStore.selectThemeNameToken);
  private _tempKind!: string;

  constructor(
    private _themeService: ThemeService,
    private _storeProfile: Store<fromProfileStore.IProfileStore>
  ) {
    moment.locale('de');
    injectApxConfig();

    this.selectedTheme$.subscribe((kind) => {
      this._tempKind = kind;
      this._themeService.setActiveTheme(
        ((kind === 'system'
          ? this._themeService.isDarkMode
            ? 'dark'
            : 'light'
          : kind === 'dark'
          ? 'dark'
          : 'light') +
          (this._themeService.activeTheme?.includes('Gs')
            ? 'Godspot'
            : 'Sorglos')) as ITheme['name'],
        true
      );
    });
    this._themeService.change.subscribe((kind) => {
      if (this._tempKind === 'system') {
        this._themeService.setActiveTheme(
          ((kind === 'dark' ? 'dark' : 'light') +
            (this._themeService.activeTheme?.includes('Gs')
              ? 'Godspot'
              : 'Sorglos')) as ITheme['name'],
          true
        );
      }
    });

    if (Element.prototype.getBoundingClientRect) {
      const DefaultGetBoundingClientRect = Element.prototype.getBoundingClientRect;

      Element.prototype.getBoundingClientRect = function () {
        if (
          !this.classList.contains('mdc-menu-surface--anchor') &&
          !this.classList.contains('cdk-drag') &&
          !this.classList.contains('cdk-drop-list') &&
          !this.classList.contains('cdk-virtual-scroll-viewport') &&
          !this.classList.contains('cdk-drag-placeholder') &&
          !this.classList.contains('cd-layout--copy-wrapper')
        ) {
          return DefaultGetBoundingClientRect.apply(this, arguments as any);
        }
        // eslint-disable-next-line prefer-const
        let { bottom, height, left, right, toJSON, top, width, x, y } =
          DefaultGetBoundingClientRect.apply(this, arguments as any);
        let parent = this.parentElement;
        let zoom = 1;

        if (((this as HTMLElement)?.style as any).zoom) {
          zoom = parseInt(((this as HTMLElement)?.style as any).zoom) / 100;
        } else {
          while (parent) {
            if ((parent.style as any).zoom) {
              zoom = parseInt((parent.style as any).zoom) / 100;
              break;
            }
            parent = parent.parentElement;
          }
        }

        bottom *= zoom;
        height *= zoom;
        left *= zoom;
        right *= zoom;
        top *= zoom;
        width *= zoom;
        x *= zoom;
        y *= zoom;

        return { bottom, height, left, right, toJSON, top, width, x, y };
      };
    }
  }
}

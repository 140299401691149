<mdc-drawer
  [collapsed]="isTablet ? isTablet : menuCollapse"
  [allowCollapseDesktop]="!isTablet"
  (desktopCollapseChange)="setMenuCollapse($event)"
  [collapseDesktop]="menuCollapse"
  *ngIf="resize.windowWidth > resize.breakpoint.mobile"
>
  <mdc-drawer-header class="brand-color-bg">
    <img class="cd-drawer__logo-icon" src="assets/logos/logo_blank.svg" alt="sorglosintern logo" />
    <img class="cd-drawer__logo-text" src="assets/logos/text_intern.svg" alt="sorglosintern text" />
  </mdc-drawer-header>
  <mdc-drawer-content *ngIf="role$ | async as role">
    <mdc-list class="cd-layout--padding-top-0 cd-layout--margin-top-8">
      <ng-container *ngFor="let destination of destinations">
        <li mdcListDivider *ngIf="destination.label === 'Dev'"></li>
        <ng-container *ngIf="!destination.hasChildren">
          <a
            mdc-list-item
            routerLink="{{ destination.href }}"
            routerLinkActive="mdc-deprecated-list-item--activated"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngIf="
              destination.label !== 'Dev' || (destination.label === 'Dev' && role === 'superadmin')
            "
          >
            <!-- [appUserPermissions]="destination.perm" -->
            <mdc-icon
              mdcListItemGraphic
              *ngIf="destination.icon"
              [custom]="destination.custom ?? false"
            >
              {{ destination.icon }}
            </mdc-icon>
            <mdc-list-item-text>{{ destination.label }}</mdc-list-item-text>
          </a>
        </ng-container>
        <ng-container *ngIf="destination.hasChildren">
          <li mdcListDivider></li>
          <!-- [appUserPermissions]="destination.perm" -->
          <h6 mdcListGroupSubheader class="cd-layout--height">
            <!-- [appUserPermissions]="destination.perm" -->
            {{ destination.label }}
          </h6>
          <ng-container *ngFor="let child of destination.children">
            <a
              mdc-list-item
              routerLink="{{ child.href }}"
              routerLinkActive="mdc-deprecated-list-item--activated"
              [routerLinkActiveOptions]="{ exact: true }"
              *ngIf="
                child.label !== 'Kosten' || (child.label === 'Kosten' && role.includes('admin'))
              "
            >
              <!-- [appUserPermissions]="destination.perm" -->
              <span
                size="small"
                class="cd-layout--contents"
                *ngIf="badge[child.badge?.variable] > 0 && child.badge"
                [badge]="badge[child.badge?.variable]"
              >
                <mdc-icon mdcListItemGraphic *ngIf="child.icon" [custom]="child.custom ?? false">
                  {{ child.icon }}
                </mdc-icon>
              </span>
              <mdc-icon
                mdcListItemGraphic
                *ngIf="child.icon && (!child.badge || badge[child.badge?.variable] === 0)"
                [custom]="child.icon.indexOf('customer') > -1 || (child.custom ?? false)"
              >
                {{
                  child.icon.indexOf('customer') > -1
                    ? child.icon.replace('customer_', '')
                    : child.icon
                }}
              </mdc-icon>
              <mdc-list-item-text>{{ child.label }}</mdc-list-item-text>
            </a>
          </ng-container>
          <li mdcListDivider class="cd-layout--margin-top-0"></li>
        </ng-container>
      </ng-container>
      <li mdcListDivider></li>
      <p
        mdcCaption
        class="mdc-deprecated-list-group__subheader cd-color-sub-text--on-surface cd-typography--text-center"
      >
        Version: {{ version }}
      </p>
      <p
        mdcCaption
        class="mdc-deprecated-list-group__subheader cd-color-sub-text--on-surface cd-typography--text-center"
      >
        API: {{ versionApi$ | async }}
      </p>
      <p
        mdcCaption
        class="mdc-deprecated-list-group__subheader cd-color-sub-text--on-surface cd-typography--text-center"
      >
        Lib: {{ versionLib }}
      </p>
    </mdc-list>
  </mdc-drawer-content>
</mdc-drawer>

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuthState from './auth.state';

const getAuthFeatureState = createFeatureSelector<fromAuthState.IAuthState>(
  fromAuthState.authStateName
);

export const selectIsAuthenticated = createSelector(getAuthFeatureState, (state) => {
  return coerceBooleanProperty(state.isAuthenticated);
});
export const selectIsReset = createSelector(getAuthFeatureState, (state) => {
  return coerceBooleanProperty(state.isReset);
});
export const selectIsResetMailSent = createSelector(getAuthFeatureState, (state) => {
  return coerceBooleanProperty(state.isResetMailSent);
});

export const selectTokenData = createSelector(getAuthFeatureState, (state) => {
  return state.tokenData;
});

export const selectUsername = createSelector(getAuthFeatureState, (state) => {
  return state.tokenData?.username as string;
});

export const selectRole = createSelector(getAuthFeatureState, (state) => {
  return state.tokenData?.role_type as string;
});

export const selectPermissionsObject = createSelector(getAuthFeatureState, (state) => {
  if (!state.tokenData?.permissions) return null;
  const obj = JSON.parse(atob(state.tokenData?.permissions));

  const menu = obj.dashboard.menu;
  obj.dashboard.permMenu = menu.rename || menu.statistic || menu.notes;
  return obj as fromAuthState.IPermission;
});

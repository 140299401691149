import { MdcFormFieldControl } from '@angular-mdc/web/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MDCCheckbox, MDCCheckboxFoundation } from '@material/checkbox';
import { MDCRipple } from '@material/ripple';
import { ControlValueBase } from '../base';

let nextUniqueId = 0;

@Component({
  selector: 'mdc-checkbox',
  templateUrl: 'checkbox.html',
  styleUrls: ['checkbox.scss'],
  providers: [
    { provide: MdcFormFieldControl, useExisting: MdcCheckbox },
    ControlValueBase.getProvider(MdcCheckbox)
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcCheckbox extends ControlValueBase<boolean> implements AfterViewInit, OnDestroy {
  @HostBinding('class') class = 'mdc-checkbox';

  private _mdcCheckbox!: MDCCheckbox;

  private _uniqueId: string = `mdc-checkbox-${++nextUniqueId}`;

  get inputId(): string {
    return `${this._uniqueId}-input`;
  }

  //#region MDCComponent properties
  get ripple(): MDCRipple {
    return this._mdcCheckbox.ripple;
  }

  @Input()
  get checked(): boolean {
    return this._mdcCheckbox ? this._mdcCheckbox.checked : coerceBooleanProperty(this._checked);
  }
  set checked(checked: boolean) {
    if (this._mdcCheckbox) {
      this._mdcCheckbox.checked = checked;
      this.onInteraction();
    } else {
      this._checked = coerceBooleanProperty(checked);
    }
    this.writeValue(checked);
  }
  private _checked!: boolean | null;

  @Input()
  get indeterminate(): boolean {
    return this._mdcCheckbox
      ? this._mdcCheckbox.indeterminate
      : coerceBooleanProperty(this._indeterminate);
  }
  set indeterminate(indeterminate: boolean) {
    if (this._mdcCheckbox) {
      this._mdcCheckbox.indeterminate = indeterminate;
    } else {
      this._indeterminate = coerceBooleanProperty(indeterminate);
    }
  }
  private _indeterminate!: boolean | null;

  @Input()
  get disabled(): boolean {
    return this._mdcCheckbox ? this._mdcCheckbox.disabled : (this._disabled as boolean);
  }
  set disabled(disabled: boolean) {
    if (this._mdcCheckbox) {
      this._mdcCheckbox.disabled = disabled;
    } else {
      this._disabled = coerceBooleanProperty(disabled);
    }
  }
  private _disabled!: boolean | null;
  //#endregion

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly change: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  //#region Lifecycle

  ngAfterViewInit(): void {
    this._mdcCheckbox = MDCCheckbox.attachTo(this.elementRef.nativeElement);
    this._checked = this._disabled = this._indeterminate = null;

    setTimeout(() => {
      this._mdcCheckbox?.ripple?.layout();
      this.elementRef.nativeElement.blur();
    }, 250);
  }

  ngOnDestroy(): void {
    this._mdcCheckbox?.destroy();
  }
  //#endregion
  //#region MDCComponent methodes

  getDefaultFoundation(): MDCCheckboxFoundation {
    return this._mdcCheckbox.getDefaultFoundation();
  }
  //#endregion

  onInteraction($event?: Event): void {
    $event?.stopPropagation();
    this.change.emit(this.checked);
    if (this.onChange) this.onChange(false);
    this.changeDetectorRef.markForCheck();
  }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromTableStore from './store';

@NgModule({
  imports: [
    EffectsModule.forFeature([]),
    StoreModule.forFeature(fromTableStore.tableStateName, fromTableStore.tableReducer)
  ],
  declarations: [],
  providers: []
})
export class TableStoreModule {}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MDCDismissibleDrawerFoundation, MDCDrawer } from '@material/drawer';
import { MDCList } from '@material/list';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mdc-drawer-header',
  template: ` <ng-content></ng-content>
    <h3 class="mdc-drawer__title" *ngIf="title">{{ title }}</h3>
    <h6 class="mdc-drawer__subtitle" *ngIf="subtitle">{{ subtitle }}</h6>`,
  styleUrls: ['./drawer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MdcDrawerHeader {
  @Input() title?: string;
  @Input() subtitle?: string;

  @HostBinding('class') class = 'mdc-drawer__header';

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Component({
  selector: 'mdc-drawer',
  templateUrl: './drawer.html',
  styleUrls: ['./drawer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MdcDrawer implements AfterViewInit, OnDestroy {
  private _mdcDrawer!: MDCDrawer;

  private _scrimElement: HTMLElement | null = null;

  @HostBinding('class.mdc-drawer') drawer!: boolean;
  // #region MDCDrawer properties

  @Input()
  get open(): boolean | null {
    return this._mdcDrawer ? this._mdcDrawer.open : this._open;
  }
  set open(value: boolean | null) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcDrawer) {
      this._mdcDrawer.open = newValue;
    }
    this._open = newValue;
  }
  private _open: boolean | null = false;
  //#endregion
  @HostBinding('class.mdc-drawer--modal')
  @Input()
  get modal(): boolean {
    return this._modal;
  }
  set modal(modal: boolean) {
    this._modal = coerceBooleanProperty(modal);
    this.drawer = true;
  }
  private _modal: boolean = false;

  @HostBinding('class.mdc-drawer--dismissible')
  @Input()
  get dismissible(): boolean {
    return this._dismissible;
  }
  set dismissible(dismissible: boolean) {
    this._dismissible = coerceBooleanProperty(dismissible);
    this.drawer = true;
  }
  private _dismissible: boolean = false;

  @Input()
  get collapsed(): boolean {
    return this._collapsed;
  }
  set collapsed(collapsed: boolean) {
    this._collapsed = coerceBooleanProperty(collapsed);
  }
  private _collapsed: boolean = false;

  @Input()
  get allowCollapseDesktop(): boolean {
    return this._allowCollapseDesktop;
  }
  set allowCollapseDesktop(allowCollapseDesktop: boolean) {
    this._allowCollapseDesktop = coerceBooleanProperty(allowCollapseDesktop);
  }
  private _allowCollapseDesktop: boolean = false;

  @Input()
  get collapseDesktop(): boolean {
    return this._collapseDesktop;
  }
  set collapseDesktop(collapseDesktop: boolean) {
    this._collapseDesktop = coerceBooleanProperty(collapseDesktop);
  }
  private _collapseDesktop: boolean = false;

  public get root(): Element {
    return this._mdcDrawer.root;
  }

  public get list(): MDCList | undefined {
    return this._mdcDrawer.list;
  }

  public touchSupport!: boolean;

  @Output()
  readonly opened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  readonly closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Output()
  readonly desktopCollapseChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public collapseOpen = false;

  private _routerSub!: Subscription;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private _platform: Platform,
    private _cdRef: ChangeDetectorRef,
    private _router: Router
  ) {
    this.touchSupport = 'ontouchstart' in window || navigator.maxTouchPoints ? true : false;

    this._routerSub = this._router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (this.collapseOpen) {
          this.collapseOpen = false;
          this._cdRef.markForCheck();
        }
      }
    });
  }

  //#region Lifecycle

  ngAfterViewInit(): void {
    if ((!this.modal && !this.dismissible) || this._mdcDrawer) {
      return;
    }
    if (this.modal) {
      this.createScrim();
      this.open = false;
    }

    this._mdcDrawer = MDCDrawer.attachTo(this.elementRef.nativeElement);
    this.open = this._open;

    if (this.modal) {
      this._mdcDrawer.list?.listen('click', ($event: MouseEvent) => {
        if (this.modal) {
          this.open = false;
          this.click.emit($event);
        }
      });
    }

    this._mdcDrawer.listen('MDCDrawer:closed', () => {
      this.closed.emit();
    });
    this._mdcDrawer.listen('MDCDrawer:opened', () => {
      this.opened.emit();
    });
    this._open = null;
  }

  ngOnDestroy(): void {
    if (!this.modal && !this.dismissible) {
      return;
    }
    this._mdcDrawer.destroy();
    if (this._scrimElement) this._scrimElement.remove();

    if (this._routerSub) this._routerSub.unsubscribe();
  }
  //#endregion

  //#region MDCDrawer methodes

  getDefaultFoundation(): MDCDismissibleDrawerFoundation {
    return this._mdcDrawer.getDefaultFoundation();
  }
  //#endregion

  private createScrim(): void {
    if (this._platform.isBrowser) {
      this._scrimElement = document.createElement('div');
      this._scrimElement.classList.add('mdc-drawer-scrim');
      this.elementRef.nativeElement.insertAdjacentElement('afterend', this._scrimElement);
    }
  }
}

export enum BoxEnum {
  // getAdvancedBoxDetails = './api/box/details/advanced',
  getBoxes = './api/box/boxes',
  boxDetails = './api/intern/box/:id',
  boxPreset = './api/intern/box/preset/:mac/:kind',
  getHistory = './api/intern/box/history/:id',
  postBoxDatatable = './api/intern/box/datatable',
  getPackages = './api/intern/box/packages/:id',
  deletePackage = './api/intern/box/packages/delete/:id',
  movePackage = './api/intern/box/packages/move/:id',
  moveBox = './api/intern/box/move',
  // getNormalBoxDetails = './api/box/details/normal',
  getStats = './api/statistic/stats',
  // setBoxConfig = './api/box/details',
  syncConfig = './api/intern/box/sync/:_id',
  boxModel = './api/intern/boxSsh/model/:ip',
  copyConfig = './api/intern/box/copyConfig/:_id',
  applyItemToBox = './api/intern/box/applyItemToBox',
  getCustomerTypes = './api/intern/box/customerTypes'
}

export enum WebsocketEnum {
  getConnections = './api/intern/websocket/connections'
}

export enum MonitoringEnum {
  collectionStats = './api/intern/monitoring/collectionStats',
  boxVersions = './api/intern/monitoring/boxVersions',
  munin = './api/intern/monitoring/munin/:node'
}

export enum InvoiceEnum {
  postCustomerDatatable = './api/intern/invoice/datatable',
  getInvoiceTemplate = './api/intern/invoice/getInvoiceTemplate/:appName',
  getInvoice = './api/intern/invoice/getInvoice/:invoiceId',
  postInvoicePreview = './api/intern/rechnung/preview',
  patchInvoice = './api/intern/invoice/patchInvoice/:invoiceId',
  postInvoice = './api/intern/invoice/postInvoice/:customerId',
  delInvoice = './api/intern/invoice/delInvoice',
  stornoInvoice = './api/intern/invoice/stornoInvoice',
  getSepaXml = './api/intern/invoice/getSepaXml?isPreview=:isPreview',
  patchDebitPaid = './api/intern/invoice/patchDebitPaid/:searchQuery',
  postInvoicesByCondition = './api/intern/invoice/invoicesByCondition',
  postSendInvoiceToCustomer = './api/sendInvoiceToCustomer',
  getSum = './api/intern/invoice/getSum',
  moveInvoice = './api/intern/invoice/move',
  postTicketing = './api/intern/invoice/postTicketing',
  getDemandProceeds = './api/intern/invoice/demandProceeds',
  getPRap = './api/intern/invoice/getPRap/:year',
  crawlInvoices = './api/intern/invoice/crawl/:year/:month'
}

export enum OrderEnum {}
// getDiscount = './api/order/discount',
// getTax = './api/order/tax',
// processOrder = './api/order/process',
// verifyOrder = './api/paypal/verifyOrder?token=ORDERID',
// processExtend = './api/order/process'

export enum CustomerEnum {
  getCustomer = './api/intern/customer/:id',
  deleteCustomer = './api/intern/customer',
  getCustomerId = './api/intern/customer/customerId/:customerNumber',
  getCustomerNumbers = './api/intern/customer/customerNumbers/:customerNumber',
  // getApiToken = './api/customer/getApiToken',
  // setEnableApiToken = './api/customer/toggleApi',
  validateIBAN = './api/intern/customer/validateIBAN',
  // controlToken = './api/customer/controlToken',
  // getInvoices = './api/mein/invoice/getInvoices',
  // getInvoiceSession = './api/mein/invoice/getInvoiceSession/:invoiceNumber',
  downloadInvoicePdf = './api/intern/rechnung/:invoiceNumber.pdf',
  // publicApiURL = 'https://mein.sorglosinternet.de/api/getBoxStatus'
  getCustomerData = './api/intern/customer/:type',
  // putCreateCustomer = './api/intern/customer/create',
  // patchAddress = './api/customer/address',
  customer = './api/intern/customer',
  getNotes = './api/intern/customer/notes/:customerId',
  putNotes = './api/intern/customer/notes',
  removeNote = './api/intern/customer/notes',
  getGenerateCustomerNumber = './api/intern/customer/generateCustomerNumber/:id',
  getSendCustomerForgotMail = './api/intern/customer/sendCustomerForgotMail/:id',
  getDebitorCustomer = './api/intern/customer/getDebitorCustomer'
}

// export enum CustomerEnum {
// }

export enum NotificationEnum {}
// webPush = './api/webPush'

export enum ProductEnum {
  getProducts = './api/products/:state'
}

export enum SplashEnum {
  // getPreviewImage = './api/splashpage/previews/:template/:encodedName',
  // splash = './api/splashpage/splash',
  getSplashPages = './api/intern/splashpage/splash/:id'
  // splashRename = './api/splashpage/splash/rename',
  // splashDuplicate = './api/splashpage/splash/duplicate'
}

export enum SplashEditorEnum {}
// getSplashData = './api/editor/data',
// getSplashImage = './api/editor/image/:userId/:customerNumber/:imageName',
// save = './api/splashpage/editor/save/:encodedName',
// gallery = './api/editor/gallery',
// removeImage = './api/splashpage/editor/removeImage/:imageName',
// uploadImage = './api/splashpage/editor/uploadImage'

export enum BaseEnum {
  // getVersionMein = './api/version/mein',
  getVersionApi = './api/version/api'
}

export enum AuthEnum {
  login = './api/auth/login',
  forgot = './api/auth/forgot',
  reset = './api/auth/reset',
  loginUser = './api/auth/login/user',
  forgotUser = './api/auth/forgot/user',
  updatePassword = './api/auth/updatePassword'
}

export enum UserEnum {
  getUserData = './api/intern/user/getUserData',
  putUserData = './api/intern/user/putUserData',
  imageURL = 'api/intern/user/image/'
}

export enum DataTableEnum {
  postDatatable = './api/datatable',
  getRebuildBoxCustomers = './api/datatable/rebuildBoxCustomers'
}
export enum StatisticEnum {
  getTotalCount = './api/intern/statistic/count'
}

export enum ActionCodeEnum {
  actionCode = './api/intern/actioncode'
}

export enum ServerEnum {
  server = './api/intern/server'
}

export enum LogisticsEnum {
  postLogistics = './api/intern/logistics',
  patchUpdateMacNumber = './api/intern/logistics/updateMacNumber'
}

export enum DashboardEnum {
  getNewBoxesByRange = './api/intern/dashboard/newBoxes/:start/:end/:groupBy',
  getBoxesByOnlineStatus = './api/intern/dashboard/boxesByOnlineStatus',
  getBoxesBySuspend = './api/intern/dashboard/boxesBySuspend',
  getNewCustomersByRange = './api/intern/dashboard/newCustomers/:start/:end/:groupBy',
  getInvoiceRevenueByRange = './api/intern/dashboard/invoiceRevenue/:start/:end/:groupBy',
  getSalesNettoRange = './api/intern/dashboard/salesNetto/:start/:end/:groupBy',
  getUnpaidInvoices = './api/intern/dashboard/unpaidInvoices',
  getProfitByRange = './api/intern/dashboard/profit/:start/:end',
  getBoxGrowthByRange = './api/intern/dashboard/boxGrowth/:start/:end/:groupBy',
  getCustomersGrowthByRange = './api/intern/dashboard/customersGrowth/:start/:end/:groupBy'
}

export enum CostsEnum {
  putPatchCosts = './api/intern/costs'
}

export enum DevEnum {
  socket = './api/intern/dev/socket',
  diffTarget = './api/intern/dev/diffTarget'
}

<mdc-bottom-nav *ngIf="role$ | async as role">
  <mdc-list-group class="cd-layout--flex">
    <mdc-list>
      <ng-container *ngFor="let destination of destinations">
        <ng-container *ngIf="!destination.isMenu">
          <ng-container
            *ngTemplateOutlet="areaButton; context: { data: destination }"
          ></ng-container>
        </ng-container>
      </ng-container>
    </mdc-list>
    <ng-container *ngFor="let destination of destinations">
      <ng-container *ngIf="destination.isMenu">
        <div
          #surface
          mdcMenuSurfaceAnchor
          (click)="!menuSurface.open ? openMenu() : ''; menuSurface.open = !menuSurface.open"
        >
          <mdc-list-item [activated]="isMenuSelected()">
            <span
              class="cd-layout--menu-badge cd-layout--contents"
              size="small"
              [badge]="badge.all"
              [showBadge]="badge.all === 0 ? false : true"
            >
              <mdc-icon
                mdcMenuIcon
                *ngIf="destination.icon"
                [custom]="destination.custom ?? false"
                >{{ destination.icon }}</mdc-icon
              >
            </span>
            <mdc-list-item-text>{{ destination.label }}</mdc-list-item-text>
          </mdc-list-item>
          <mdc-menu
            #menuSurface
            [anchorElement]="surface"
            [anchorCorner]="'TOP_START'"
            [anchorMargin]="{ top: 0, bottom: -10, left: 0, right: 10 }"
          >
            <mdc-list #menuList (selectedItem)="setMeuIndex($event)">
              <ng-container *ngFor="let child of destination.children">
                <ng-container
                  *ngIf="
                    child.label !== 'Kosten' || (child.label === 'Kosten' && role.includes('admin'))
                  "
                >
                  <ng-container
                    *ngTemplateOutlet="
                      areaButton;
                      context: { data: child, menuSurface: menuSurface }
                    "
                  ></ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="version">
                <li mdcListDivider></li>
                <mdc-list-item
                  disabled
                  style="width: auto"
                  class="cd-color-sub-text--on-surface cd-layout--font-size-12 cd-layout--height-25"
                >
                  <p class="cd-layout--version-menu">Version: {{ version }}</p>
                </mdc-list-item>
              </ng-container>
            </mdc-list>
          </mdc-menu>
        </div>
      </ng-container>
    </ng-container>
  </mdc-list-group>
</mdc-bottom-nav>

<ng-template #areaButton let-data="data" let-menuSurface="menuSurface">
  <mdc-list-item
    routerLink="{{ data.href }}"
    routerLinkActive="mdc-deprecated-list-item--selected"
    [routerLinkActiveOptions]="{ exact: true }"
    [ngStyle]="{
      display: data.label !== 'Kasse' || (data.label === 'Kasse' && badge.all > 0) ? '' : 'none'
    }"
    (click)="menuSurface ? (menuSurface.open = false) : ''"
  >
    <span
      [badge]="badge[data.badge.variable]"
      size="small"
      class="cd-layout--contents"
      *ngIf="data.badge && badge[data.badge.variable] > 0"
    >
      <mdc-icon
        mdcMenuIcon
        *ngIf="data.icon"
        [custom]="data.icon.indexOf('customer') > -1 || (data.custom ?? false)"
        >{{ data.icon.replace('customer_', '') }}</mdc-icon
      >
    </span>
    <mdc-icon
      mdcMenuIcon
      *ngIf="data.icon && (!data.badge || badge[data.badge.variable] === 0)"
      [custom]="data.icon.indexOf('customer') > -1 || (data.custom ?? false)"
      >{{ data.icon.replace('customer_', '') }}</mdc-icon
    >
    <mdc-list-item-text>{{ data.label }}</mdc-list-item-text>
  </mdc-list-item>
</ng-template>

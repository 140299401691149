import { ICoreConfig } from '@core/interfaces/config.interface';

export const coreConfigSorglos: ICoreConfig = {
  productsRegular: ['box', 'boxPro5G'],
  productsAppliance: ['appliance', 'appliance1Wifi', 'appliance2Wifi', 'rack'],
  hardwareNames: {
    si: {
      box: 'sorglosbox',
      box5G: 'sorglosbox 5GHz',
      boxPro: 'sorglosbox Pro',
      boxPro5G: 'sorglosbox Pro 5GHz',
      appliance: 'sorglos Appliance',
      appliance1Wifi: 'sorglos Appliance 2.4GHz',
      appliance2Wifi: 'sorglos Appliance 2.4GHz + 5GHz',
      rack: 'sorglos Appliance Rack'
    },
    gs: {
      box: 'godspot',
      box5G: 'godspot 5GHz',
      boxPro: 'godspot Pro',
      boxPro5G: 'godspot Pro 5GHz',
      appliance: 'Appliance godspot',
      appliance1Wifi: 'Appliance godspot 2.4GHz',
      appliance2Wifi: 'Appliance godspot 2.4GHz + 5GHz',
      rack: 'Appliance godspot Rack'
    }
  },
  app: {
    appName: 'sorglosintern',
    appTitle: 'sorglosintern | ',
    //TODO: implements appLogoImage
    appLogoImage: 'TODO'
  },
  layout: {
    menu: {
      hidden: false,
      collapsed: false
    },
    content: {
      maxWidth: '100%',
      minWidth: '320px'
    },
    search: {
      hidden: false,
      allowed: false,
      placeholder: 'suchen nach'
    }
  },
  queries: {
    isMobile: /\b(iPhone|Android)\b/i.test(window.navigator.userAgent),
    isIphone: /\b(iPhone)\b/i.test(window.navigator.userAgent),
    isTablet: /\b(iPad|Tablet)\b/i.test(window.navigator.userAgent)
  },
  availableThemes: { light: 'lightSorglos', dark: 'darkSorglos' },
  assets: 'assets/si'
};

import { MdcRippleModule } from '@angular-mdc/web/ripple';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MdcButton, MdcButtonLabel } from './button';

@NgModule({
  imports: [CommonModule, MdcRippleModule],
  exports: [MdcButton, MdcButtonLabel],
  declarations: [MdcButton, MdcButtonLabel]
})
export class MdcButtonModule {}

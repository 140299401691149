import { MdcBadgeModule } from '@angular-mdc/web/badge';
import { MdcDrawerModule } from '@angular-mdc/web/drawer';
import { MdcIconModule } from '@angular-mdc/web/icon';
import { MdcListModule } from '@angular-mdc/web/list';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/core-common.module';
// import { RoleDirectivesModule } from 'src/app/main/directives';
import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CoreCommonModule,
    RouterModule,
    MdcIconModule,
    MdcDrawerModule,
    MdcListModule,
    MdcBadgeModule
    // RoleDirectivesModule
  ],
  exports: [MenuComponent]
})
export class MenuModule {}

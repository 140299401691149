import { MdcMenu } from '@angular-mdc/web/menu';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'mdc-bottom-nav',
  exportAs: 'MDCBottomNavigationBar',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./bottom-nav.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MdcMenu],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomNavComponent implements AfterViewInit {
  @HostBinding('class') class = 'mdc-bottom-nav mdc-elevation--z4 mdc-theme--surface-bg';

  @Input()
  public set iosDevice(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    const children = this.getHostElement().children[0];
    if (newValue) {
      children.classList.add('mdc-deprecated-list-iosDevice-spacer');
      this.getHostElement().classList.add('mdc-bottom-nav-iosDevice');
    } else {
      children.classList.remove('mdc-deprecated-list-iosDevice-spacer');
      this.getHostElement().classList.remove('mdc-bottom-nav-iosDevice');
    }
    this._iosDevice = newValue;
    this._cDRef.markForCheck();
  }

  public get iosDevice(): boolean {
    return this._iosDevice;
  }

  private _iosDevice = false;

  @Input()
  public set centerEnable(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    this._centerEnable = newValue;

    const children = this.getHostElement().children[0];
    if (newValue) {
      children.classList.add('cd-bottom-nav-center');
      children.classList.remove('cd-bottom-nav-space');
    } else {
      children.classList.remove('cd-bottom-nav-center');
      children.classList.add('cd-bottom-nav-space');
    }
  }

  public get centerEnable(): boolean {
    return this._centerEnable;
  }

  private _centerEnable: boolean = true;

  @HostBinding('class.mdc-bottom-nav-color')
  @Input()
  public set colorEnable(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    this._colorEnable = newValue;
  }

  public get colorEnable(): boolean {
    return this._colorEnable;
  }

  private _colorEnable: boolean = false;

  private _query = window.matchMedia('(orientation: landscape)');

  constructor(private _elementRef: ElementRef<HTMLElement>, private _cDRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.centerEnable = this.centerEnable;
    const mdcList = this._elementRef.nativeElement.querySelector('mdc-deprecated-list');
    if (mdcList) {
      if (mdcList.children.length < 3) console.error('Please use three or more destinations');
      if (mdcList.children.length > 5) {
        console.error('Don’t use more than five destinations');
        for (let i = mdcList.children.length - 1; i === 5; i--) {
          mdcList.children[i].remove();
        }
      }
    }

    const isMobile = /\b(iPhone|Android)\b/i.test(window.navigator.userAgent);
    this.iosDevice = /\b(iPhone|iPad)\b/i.test(window.navigator.userAgent);
    if (isMobile) this.screenMode();

    // INFO: Went back to deprecated "addListener", because "addEventListener" is not compatible with iOS<14
    this._query.addListener((orientation: MediaQueryListEvent) => {
      this.screenMode();
    });
  }

  public screenMode(): void {
    const matchMedia = window.matchMedia('(orientation: landscape)').matches;
    if (matchMedia) {
      if (this.centerEnable) {
        this.getHostElement()
          .querySelector('.cd-bottom-nav-isMobile')
          ?.classList.add('cd-bottom-nav-center');
      } else {
        this.getHostElement()
          .querySelector('.cd-bottom-nav-isMobile')
          ?.classList.add('cd-bottom-nav-space');
      }
      this.getHostElement()
        .querySelector('.cd-bottom-nav-isMobile')
        ?.classList.remove('cd-bottom-nav-isMobile');
    } else {
      if (this.centerEnable) {
        this.getHostElement()
          .querySelector('.cd-bottom-nav-center')
          ?.classList.add('cd-bottom-nav-isMobile');
        this.getHostElement()
          .querySelector('.cd-bottom-nav-center')
          ?.classList.remove('cd-bottom-nav-center');
      } else {
        this.getHostElement()
          .querySelector('.cd-bottom-nav-space')
          ?.classList.add('cd-bottom-nav-isMobile');
        this.getHostElement()
          .querySelector('.cd-bottom-nav-space')
          ?.classList.remove('cd-bottom-nav-space');
      }
    }
  }

  private getHostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as fromAuthSelectors from 'src/app/auth/store/auth.selectors';
import * as fromAuthState from 'src/app/auth/store/auth.state';
import { SocketService } from 'src/app/main/services/socket.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private _store: Store<fromAuthState.IAuthState>,
    private _socketService: SocketService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._store.select(fromAuthSelectors.selectTokenData).pipe(
      mergeMap((tokenData) => {
        if (tokenData?.access_token) {
          let headers = req.headers.set('Authorization', 'Bearer ' + tokenData.access_token);

          if (this._socketService?.socket?.id) {
            headers = headers.set('socket-id', this._socketService?.socket?.id);
          }
          req = req.clone({
            headers: headers,
            withCredentials: true
          });
        }
        return next.handle(req);
      })
    );
  }
}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { TBadgePositions, TBadgeSizes, TBadgeVariants } from './constants';

@Directive({
  selector: '[badge]'
})
export class BadgeDirective implements OnChanges, OnDestroy {
  @Input() public badge: string | null = null;
  @Input()
  public set size(value: TBadgeSizes) {
    this._size = value;
    if (this._badgeElement) {
      this._render.removeClass(this._badgeElement, 'small');
      this._render.removeClass(this._badgeElement, 'medium');
      this._render.removeClass(this._badgeElement, 'large');
      this._render.addClass(this._badgeElement, this.size);
    }
  }
  public get size(): TBadgeSizes {
    return this._size;
  }
  private _size: TBadgeSizes = 'medium';
  @Input() public gravity: TBadgePositions = 'top-right';
  @Input() public customBadgeClasses: string | null = null;
  @Input() public variant: TBadgeVariants = 'error';
  @Input() public set dot(value: boolean) {
    this._dot = coerceBooleanProperty(value);
  }
  public get dot(): boolean {
    return this._dot;
  }
  private _dot = false;

  @Input() public set showBadge(value: boolean) {
    this._showBadge = coerceBooleanProperty(value);
  }
  public get showBadge(): boolean {
    return this._showBadge;
  }
  private _showBadge = true;

  private _badgeElement: HTMLElement | null = null;

  constructor(private _render: Renderer2, private _elRef: ElementRef<HTMLElement>) {}
  ngOnChanges(changes: SimpleChanges): void {
    if ('badge' in changes) {
      const value = `${changes.badge.currentValue}`.trim();
      if (value?.length > 0) {
        this.updateBadgeText(value);
      }
    }
    if ('dot' in changes) {
      const value = `${changes.badge.currentValue}`.trim();
      this.updateBadgeText(value);
    }
    if ('showBadge' in changes) {
      if (this.showBadge) this._render.removeClass(this._badgeElement, 'hidden');
      else this._render.addClass(this._badgeElement, 'hidden');
    }
  }

  ngOnDestroy(): void {
    if (this._badgeElement && this._render.destroyNode) {
      this._render.destroyNode(this._badgeElement);
    }
  }

  private updateBadgeText(value?: string): void {
    if (!this._badgeElement) this._badgeElement = this.createBadge();
    if (!this.dot && value) {
      this._badgeElement.textContent = value;
      this._render.removeClass(this._badgeElement, 'dot');
    } else {
      this._badgeElement.textContent = null;
      this._render.addClass(this._badgeElement, 'dot');
    }
  }

  private createBadge(): HTMLElement {
    const badgeElement = this._render.createElement('span');
    this.addClasses(badgeElement);
    this._render.addClass(this._elRef.nativeElement, 'mdc-badge-container');
    this._render.appendChild(this._elRef.nativeElement, badgeElement);
    return badgeElement;
  }

  private addClasses(badgeElement: HTMLElement): void {
    const [vPos, hPos] = this.gravity.split('-');
    this._render.addClass(badgeElement, 'mdc-badge');
    this._render.addClass(badgeElement, vPos);
    this._render.addClass(badgeElement, hPos);
    if (this.customBadgeClasses) {
      const customClasses = this.customBadgeClasses.split(' ');
      customClasses.forEach((customClass) => {
        this._render.addClass(badgeElement, customClass);
      });
    }
    this._render.addClass(
      badgeElement,
      (this.variant === 'primary' ? 'mdc-theme--' : 'cd-layout-theme--') + this.variant + '-bg'
    );
    this._render.addClass(
      badgeElement,
      (this.variant === 'primary' ? 'mdc-theme--' : 'cd-layout-theme--') + this.variant
    );
    this._render.addClass(badgeElement, this.size);
  }
}

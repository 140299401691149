import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[mdcMenuSelectionGroup], mdc-menu-selection-group',
  exportAs: 'mdcMenuSelectionGroup'
})
export class MdcMenuSelectionGroup {
  @HostBinding('class') class = 'mdc-menu__selection-group';

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: '[mdcMenuSelectionGroupIcon], mdc-menu-selection-group-icon',
  exportAs: 'mdcMenuSelectionGroupIcon'
})
export class MdcMenuSelectionGroupIcon {
  @HostBinding('class') class = 'mdc-menu__selection-group-icon';

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: '[mdcMenuIcon], mdc-menu-icon',
  exportAs: 'mdcMenuIcon'
})
export class MDCMenuIcon {
  @HostBinding('class') class = 'mdc-menu__icon mdc-deprecated-list-item__graphic';
  @HostBinding('attr.role') role = 'presentation';
  @HostBinding('attr.aria-hidden') hidden = true;

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

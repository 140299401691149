import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MDCSelect, MDCSelectEventDetail, MDCSelectFoundation } from '@material/select';
import { Subscription } from 'rxjs';
import { ControlValueBase } from '../base';
import { MdcMenu } from '../menu';
import { MdcSelectIcon } from './select-icon';

let nextUniqueId = 0;

@Component({
  selector: 'mdc-select',
  templateUrl: 'select.html',
  styleUrls: ['select.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: ControlValueBase.getProvider(MdcSelect)
})
export class MdcSelect extends ControlValueBase<string> implements AfterViewInit, OnDestroy {
  @HostBinding('class') class = 'mdc-select';
  @HostBinding('class.mdc-select--no-label') noLabel!: boolean;

  @HostListener('focusout') focusout(): void {
    this.focus();
  }

  public isOpen = false;

  private _mdcSelect!: MDCSelect;

  @HostBinding('class.mdc-select--with-leading-icon')
  @ContentChild(MdcSelectIcon, { static: false })
  public leadingIcon?: MdcSelectIcon;

  private _helperLineElement?: HTMLElement;

  private _uniqueId: string = `mdc-select-${++nextUniqueId}`;

  @HostBinding('id')
  @Input()
  id: string = this._uniqueId;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    if (!value) this.noLabel = true;
  }
  private _placeholder!: string;

  @HostBinding('class.mdc-select--fullwidth')
  @Input()
  get fullwidth(): boolean {
    return this._fullwidth;
  }

  set fullwidth(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._fullwidth) this._fullwidth = newValue;
  }
  private _fullwidth = false;

  @Input()
  get helperText(): string {
    return this._helperText;
  }
  set helperText(value: string) {
    this._helperText = value;
    if (value) this.createHelperElement();
    else if (this._helperLineElement) this._helperLineElement.remove();
  }
  private _helperText!: string;

  @Input()
  get small(): boolean {
    return this._small;
  }
  set small(small: boolean) {
    this._small = coerceBooleanProperty(small);
  }

  private _small: boolean = false;

  // #region MdcSelect properties
  @HostBinding('class.mdc-select--outlined')
  @Input()
  get outlined(): boolean {
    return this._outlined;
  }
  set outlined(value: boolean) {
    this._outlined = coerceBooleanProperty(value);
    if (this._mdcSelect) this.layout();
  }
  private _outlined = true;

  @HostBinding('class.mdc-select--disabled')
  @Input()
  get disabled(): boolean {
    return this._mdcSelect ? this._mdcSelect.disabled : this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcSelect) this._mdcSelect.disabled = newValue;
    else this._disabled = newValue;
  }
  private _disabled = false;

  @HostBinding('class.mdc-select--required')
  @Input()
  get required(): boolean {
    return this._mdcSelect ? this._mdcSelect.required : this._required;
  }
  set required(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcSelect) {
      this._mdcSelect.required = newValue;
      this.layout();
    } else this._required = newValue;
    if (newValue) this.addValidator(Validators.required, 'required');
    else this.removeValidator('required');
  }
  private _required = false;

  @Input()
  get useDefaultValidation(): boolean {
    return this._mdcSelect ? this._mdcSelect.useDefaultValidation : this._useDefaultValidation;
  }
  set useDefaultValidation(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcSelect) this._mdcSelect.useDefaultValidation = newValue;
    else this._useDefaultValidation = newValue;
  }
  private _useDefaultValidation = false;

  @Input()
  get valid(): boolean {
    return this._mdcSelect ? this._mdcSelect.valid : this._valid;
  }
  set valid(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (this._mdcSelect) this._mdcSelect.valid = newValue;
    else this._valid = newValue;
  }
  private _valid = false;

  @Input()
  get value(): string {
    return this._mdcSelect ? this._mdcSelect.value : this._value;
  }
  set value(value: string) {
    if (this._mdcSelect) this._mdcSelect.value = value;
    else this._value = value;
  }
  private _value!: string;

  @Input()
  set helperTextContent(value: string) {
    if (this._mdcSelect) this._mdcSelect.helperTextContent = value;
    else this._helperTextContent = value;
  }
  private _helperTextContent!: string;

  @Input()
  set leadingIconAriaLabel(value: string) {
    if (this._mdcSelect) this._mdcSelect.leadingIconAriaLabel = value;
    else this._leadingIconAriaLabel = value;
  }
  private _leadingIconAriaLabel!: string;

  @Input()
  set leadingIconContent(value: string) {
    if (this._mdcSelect) this._mdcSelect.leadingIconContent = value;
    else this._leadingIconContent = value;
  }
  private _leadingIconContent!: string;

  @Input()
  get selectedIndex(): number {
    return this._mdcSelect ? this._mdcSelect.selectedIndex : this._selectedIndex;
  }
  set selectedIndex(value: number) {
    const newValue = coerceNumberProperty(value);
    if (this._mdcSelect) this._mdcSelect.selectedIndex = newValue;
    else this._selectedIndex = newValue;
  }
  private _selectedIndex!: number;

  public get root(): Element {
    return this._mdcSelect.root;
  }
  //#endregion

  private _sub!: Subscription;

  @ContentChild(MdcMenu, { static: false })
  private _menu!: MdcMenu;

  @HostListener('click') private customClick(): void {
    const positionSelf = this._elementRef.nativeElement.getBoundingClientRect();

    const top = positionSelf.top + positionSelf.height;
    const left = positionSelf.left;
    this._menu.root.classList.add('cd-layout--none');
    setTimeout(() => {
      this._menu.root.style.top = `${top}px`;
      this._menu.root.style.left = `${left}px`;
      this._menu.root.style.zIndex = '1000';
      this._menu.root.style.position = 'fixed';
      this._menu.root.classList.remove('cd-layout--none');
      document.body.appendChild(this._menu.root);
    }, 20);
  }

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly change: EventEmitter<MDCSelectEventDetail> = new EventEmitter<MDCSelectEventDetail>();
  constructor(private _elementRef: ElementRef<HTMLElement>) {
    super();
  }

  //#region Lifecycle
  ngAfterViewInit(): void {
    if (this.outlined === false) this._elementRef.nativeElement.classList.add('mdc-select--filled');

    this._mdcSelect = MDCSelect.attachTo(this._elementRef.nativeElement);

    this.required = this._required;
    if (this._selectedIndex !== undefined) this.selectedIndex = this._selectedIndex;
    this._mdcSelect.listen('MDCSelect:change', ($event: CustomEvent<MDCSelectEventDetail>) => {
      if ($event?.detail?.value?.length >= 0) {
        this.change.emit($event.detail);
        this.onChange($event.detail.value);
      }
    });

    if (this._menu) {
      this._sub = this._menu.surfaceOpened.subscribe(() => {
        this.isOpen = true;
      });
      this._sub.add(
        this._menu.surfaceClosed.subscribe(() => {
          this._elementRef.nativeElement.appendChild(this._menu.root);
          this.isOpen = false;
        })
      );
    }

    if (!this.helperText) return;
    this.createHelperElement();
    this.focus();
  }

  ngOnDestroy(): void {
    this._mdcSelect.destroy();
    if (this._sub) this._sub.unsubscribe();
  }
  //#endregion

  //#region MdcSelect methodes
  layout(): void {
    this._mdcSelect.layout();
  }

  layoutOptions(): void {
    this._mdcSelect.layoutOptions();
  }

  initialize(): void {
    this._mdcSelect.initialize();
  }

  getDefaultFoundation(): MDCSelectFoundation {
    return this._mdcSelect.getDefaultFoundation();
  }
  //#endregion

  private focus(): void {
    if (!this._helperLineElement) return;

    if (this.valid)
      this._helperLineElement.classList.remove('mdc-select-helper-text--validation-msg');
    else this._helperLineElement.classList.add('mdc-select-helper-text--validation-msg');
  }

  private createHelperElement(): void {
    if (!this._helperLineElement) {
      this._helperLineElement = document.createElement('p');
      this._helperLineElement.className =
        'mdc-select-helper-text mdc-select-helper-text--validation-msg-persistent';
    }
    this._helperLineElement.innerText = this.helperText;
    this.appendHelperElement();
  }

  /**
   * Appends the helper element to the select.
   */
  private appendHelperElement(): void {
    if (!this._helperLineElement)
      throw Error('Helper element has not been created and cannot be appended');

    this._elementRef.nativeElement.insertAdjacentElement('afterend', this._helperLineElement);
  }
}

import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[mdcMenuSurfaceAnchor], mdc-menu-surface-anchor',
  exportAs: 'mdcMenuSurfaceAnchor'
})
export class MdcMenuSurfaceAnchor {
  @HostBinding('class') class = 'mdc-menu-surface--anchor';

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}

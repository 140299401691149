import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProfileService } from './profile.service';
import * as fromProfileStore from './store';
import { ProfileEffects } from './store/profile.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([ProfileEffects]),
    StoreModule.forFeature(fromProfileStore.profileStateName, fromProfileStore.profileReducer)
  ],
  declarations: [],
  providers: [ProfileService]
})
export class ProfileStoreModule {}

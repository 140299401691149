import { Action, ActionReducer } from '@ngrx/store';
import { Buffer } from 'buffer';
import { merge, pick } from 'lodash';
import { environment } from 'src/environments/environment';

function setSavedState(state: any, localStorageKey: string): void {
  localStorage.setItem(localStorageKey, Buffer.from(JSON.stringify(state)).toString('base64'));
}
function getSavedState(localStorageKey: string): any {
  try {
    return JSON.parse(
      Buffer.from(localStorage.getItem(localStorageKey) || '', 'base64').toString()
    );
  } catch (error) {
    window.location.href = 'account/logout';
  }
}

// the keys from state which we'd like to save.
const stateKeys = ['auth', 'user', 'profile'];

if (environment.production) {
  stateKeys.push('table');
}

// the key for the local storage.
const localStorageKey = 'INTERN_STORE';

export function storageMetaReducer<S, A extends Action = Action>(
  reducer: ActionReducer<S, A>
): (state: S, action: A) => S {
  let onInit = true; // after load/refresh…
  return function (state: S, action: A): S {
    // reduce the nextState.
    const nextState = reducer(state, action);
    // init the application state.
    if (onInit) {
      onInit = false;
      const savedState = getSavedState(localStorageKey);
      return merge(nextState, savedState);
    }
    // save the next state to the application storage.
    const stateToSave = pick(nextState, stateKeys);
    setSavedState(stateToSave, localStorageKey);
    return nextState;
  };
}

import { MdcSnackbar } from '@angular-mdc/web/snackbar';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, take } from 'rxjs';
import { getProfileData, getProfileDataComplete } from '.';
import { ProfileService } from '../profile.service';
import { putProfileData } from './profile.actions';

@Injectable()
export class ProfileEffects {
  constructor(
    private _actions$: Actions,
    private _profileService: ProfileService,
    private _snackbar: MdcSnackbar
  ) {}

  public getProfileData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getProfileData),
      switchMap(() =>
        this._profileService.getUserData().pipe(
          take(1),
          map((res) => {
            return getProfileDataComplete(res);
          })
        )
      )
    )
  );

  public putProfileData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(putProfileData),
      switchMap((data) =>
        this._profileService.putProfileData(data).pipe(
          take(1),
          map((res) => {
            this._snackbar.open(res.message);
            return getProfileDataComplete(data);
          })
        )
      )
    )
  );
}

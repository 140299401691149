import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CoreCommonModule } from '@core/core-common.module';
import { AuthenticationGuard } from './guards/authentication.guard';
import { RoleGuard } from './guards/role.guard';
import {
  AuthorizationInterceptor,
  ResponseInterceptor,
  StandardHeaderInterceptor
} from './interceptors';

@NgModule({
  imports: [CoreCommonModule],
  declarations: [],
  providers: [
    AuthenticationGuard,
    RoleGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StandardHeaderInterceptor,
      multi: true
    }
  ],
  exports: []
})
export class AuthModule {}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AuthEnum } from 'src/app/main/utils';
import { ITokenDataLogin } from './interfaces/token.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private _http: HttpClient) {}

  public loginUser(
    username: string,
    password: string,
    email?: string
  ): Observable<ITokenDataLogin> {
    const data = {
      username: username,
      email: email,
      // btoa deprecation warning is only for NodeJS, not for browser
      password: window.btoa(password)
    };

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    return this._http.post<ITokenDataLogin>(
      !email ? AuthEnum.login : AuthEnum.loginUser,
      data,
      options
    );
  }

  public forgotUser(email: string, username?: string): Observable<any> {
    const data = {
      email: email,
      username: username
    };

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    return new Observable((observer: Observer<any>) => {
      this._http
        .post<any>(!username ? AuthEnum.forgot : AuthEnum.forgotUser, data, options)
        .subscribe(
          (responseData: any) => {
            observer.next(responseData);
          },
          (error) => {
            observer.error(error);
          },
          () => observer.complete()
        );
    });
  }

  public resetPassword(password: string, passwordRepeat: string, token: string): Observable<any> {
    const data = {
      password: password,
      passwordRepeat: passwordRepeat,
      token: token
    };

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    return this._http.post<any>(AuthEnum.reset, data, options);
  }
}

import { MdcTabModule } from '@angular-mdc/web/tab';
import { MdcTabIndicatorModule } from '@angular-mdc/web/tab-indicator';
import { MdcTabScrollerModule } from '@angular-mdc/web/tab-scroller';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MdcTabBar, MdcTabRowContainer } from './tab-bar';

@NgModule({
  imports: [CommonModule, RouterModule, MdcTabIndicatorModule, MdcTabModule, MdcTabScrollerModule],
  exports: [
    MdcTabBar,
    MdcTabIndicatorModule,
    MdcTabModule,
    MdcTabScrollerModule,
    MdcTabRowContainer
  ],
  declarations: [MdcTabBar, MdcTabRowContainer]
})
export class MdcTabBarModule {}

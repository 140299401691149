import { MdcIcon } from '@angular-mdc/web/icon';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MDCRipple } from '@material/ripple';

@Directive({
  selector: 'mdc-button-label, [mdcButtonLabel]',
  exportAs: 'mdcButtonLabel'
})
export class MdcButtonLabel {
  @HostBinding('class') class = 'mdc-button__label';
}

@Component({
  selector: 'button[mdc-button], a[mdc-button]',
  templateUrl: 'button.html',
  styleUrls: ['./button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcButton implements OnInit, AfterViewInit {
  @HostBinding('class') class = 'mdc-button';
  @HostBinding('attr.tabIndex') tabIndex = 0;

  @HostBinding('attr.type')
  @Input()
  public type = 'button';

  @HostListener('click', ['$event']) click($event: MouseEvent): void {
    this.onClick($event);
  }

  private _root!: HTMLElement;

  @HostBinding('class.mdc-button--raised')
  @Input()
  get raised(): boolean {
    return this._raised;
  }
  set raised(value: boolean) {
    this._raised = coerceBooleanProperty(value);
  }
  private _raised: boolean = false;

  @HostBinding('class.cd-layout--customText')
  @Input()
  get customText(): boolean {
    return this._customText;
  }
  set customText(value: boolean) {
    this._customText = coerceBooleanProperty(value);
  }
  private _customText: boolean = false;

  @HostBinding('class.mdc-button--unelevated')
  @Input()
  get unelevated(): boolean {
    return this._unelevated;
  }
  set unelevated(value: boolean) {
    this._unelevated = coerceBooleanProperty(value);
  }
  private _unelevated: boolean = false;

  @HostBinding('class.mdc-button--outlined')
  @Input()
  get outlined(): boolean {
    return this._outlined;
  }
  set outlined(value: boolean) {
    this._outlined = coerceBooleanProperty(value);
  }
  private _outlined: boolean = false;

  @HostBinding('class.mdc-button--touch')
  @Input()
  get touch(): boolean {
    return this._touch;
  }
  set touch(value: boolean) {
    this._touch = coerceBooleanProperty(value);
  }
  private _touch: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.setDisabled(this._disabled);
  }
  private _disabled: boolean = false;

  @HostBinding('class.cd-layout--smallSize')
  @Input()
  get small(): boolean {
    return this._small;
  }
  set small(value: boolean) {
    this._small = coerceBooleanProperty(value);
  }
  private _small: boolean = false;

  @Input()
  public set label(v: string) {
    this._label = v;
    this._cDRef.markForCheck();
  }

  public get label(): string {
    return this._label;
  }

  private _label!: string;

  @ContentChild(MdcIcon, { static: true }) private _icon!: MdcIcon;

  constructor(public elementRef: ElementRef<HTMLElement>, private _cDRef: ChangeDetectorRef) {
    this._root = elementRef.nativeElement;
  }

  ngOnInit(): void {
    if (this._icon) this._icon.elementRef.nativeElement.classList.add('mdc-button__icon');
  }

  ngAfterViewInit(): void {
    const buttonRipple = new MDCRipple(this._root);
  }

  setDisabled(disabled: boolean): void {
    if (disabled) {
      this._root.setAttribute('disabled', 'true');
      this._root.setAttribute('aria-disabled', 'true');
    } else {
      this._root.removeAttribute('disabled');
      this._root.removeAttribute('aria-disabled');
    }
  }

  /** Focuses the button. */
  focus(): void {
    this._root.focus();
  }

  onClick(event: MouseEvent): void {
    // A disabled button shouldn't apply any actions
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  public getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { FindCustomerDialogComponent } from './find-customer-dialog.component';

@Injectable()
export class FindCustomerDialogService {
  constructor(public dialog: MatDialog) {}

  public async openDialog(title?: string): Promise<{ status: 'success' | 'error'; id: string }> {
    const dialogRef = this.dialog.open(FindCustomerDialogComponent, {
      data: { title }
    });

    const data = await firstValueFrom(dialogRef.afterClosed());

    return { status: data ? 'success' : 'error', id: data };
  }
}

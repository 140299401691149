// import * as fromCartStore from 'src/app/main/store/cart/store';

export class MenuBase {
  public badge: DefaultBadge = new DefaultBadge();

  constructor() {
    // const itemSub = this._cartStore
    //   .select(fromCartStore.selectCartByType, {
    //     cartType: 'ALL'
    //   })
    //   .subscribe((items) => {
    //     this.badge = new DefaultBadge();
    //     items.forEach((item) => {
    //       if (item.id.startsWith('ORDER')) this.badge.order++;
    //       if (item.id.startsWith('EXTEND')) this.badge.extend++;
    //     });
    //     this.badge.all = this.badge.order + this.badge.extend;
    //   });
  }
}

class DefaultBadge {
  [key: string]: number;
  public extend = 0;
  public order = 0;
  public all = 0;
}

import { MdcElevationModule } from '@angular-mdc/web/elevation';
import { MdcMenuModule } from '@angular-mdc/web/menu';
import { MdcTopAppBarModule } from '@angular-mdc/web/top-app-bar';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MdcIconButtonModule } from '../icon-button';
import { MdcDialog } from './dialog';
import {
  MdcDialogAction,
  MdcDialogActions,
  MdcDialogContent,
  MdcDialogMenuList,
  MdcDialogSubtitle,
  MdcDialogTitle
} from './dialog.helpers';

@NgModule({
  imports: [
    CommonModule,
    MdcElevationModule,
    MdcTopAppBarModule,
    MdcMenuModule,
    MdcIconButtonModule
  ],
  declarations: [
    MdcDialog,
    MdcDialogTitle,
    MdcDialogSubtitle,
    MdcDialogContent,
    MdcDialogActions,
    MdcDialogAction,
    MdcDialogMenuList
  ],
  exports: [
    MdcDialog,
    MdcDialogTitle,
    MdcDialogSubtitle,
    MdcDialogContent,
    MdcDialogActions,
    MdcDialogAction,
    MdcDialogMenuList
  ],
  providers: []
})
export class MdcDialogModule {}

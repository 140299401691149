/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTableState from './table.state';

const getTableFeatureState = createFeatureSelector<fromTableState.ITableStoreState>(
  fromTableState.tableStateName
);

export const selectEnabledColumns = (tableName: string) =>
  createSelector(getTableFeatureState, (state) => state[tableName]?.enabledColumns ?? []);
export const selectEnabledConditions = (tableName: string) =>
  createSelector(getTableFeatureState, (state) => state[tableName]?.enabledConditions ?? []);

export const selectAvailablePresets = (tableName: string) =>
  createSelector(getTableFeatureState, (state) => state[tableName]?.presetColumns ?? []);

export const selectInitProps = (tableName: string) =>
  createSelector(getTableFeatureState, (state) => {
    return {
      zoom: state[tableName]?.zoom ?? 100,
      length: state[tableName]?.length ?? 5
    };
  });

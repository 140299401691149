import { Injectable } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { ResizeService } from '@core/services';
import { BehaviorSubject, filter } from 'rxjs';
import { Navbar } from '../utils/interfaces/navbar.interface';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public wrapper = document.querySelector('.main-wrapper') as HTMLElement;

  public get tabs(): Navbar.ITab[] | null {
    return this._tabs;
  }
  public set tabs(value: Navbar.ITab[] | null) {
    this._tabs = value;
    this.activeTabIndex = 0;
    this.updateWrapper();
  }
  private _tabs!: Navbar.ITab[] | null;

  public searchMenu: { count: number; list: string[] } = { count: 0, list: [] };

  public set activeTabIndex(value: number) {
    this.activeTabObserver.next(value);
  }
  public get activeTabIndex(): number {
    return this.activeTabObserver.getValue();
  }
  public activeTabObserver: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private _router: Router, private _resizeService: ResizeService) {
    this._router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe(() => {
      this.tabs = null;
    });

    this._resizeService.resizeObserver.subscribe(() => {
      this.updateWrapper();
    });
  }

  private updateWrapper(): void {
    if (this._resizeService.isMobile && this.tabs) {
      this.wrapper.classList.add('height-navbar');
    } else {
      this.wrapper.classList.remove('height-navbar');
    }
  }
}

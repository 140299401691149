<ng-container *ngIf="!modal && !dismissible; else nonCollapse">
  <div
    class="cd-layout__drawer-spacer"
    [ngClass]="{
      'cd-layout__drawer-spacer--collapsed': collapsed
    }"
  ></div>
  <div
    class="mdc-drawer"
    role="navigation"
    [ngClass]="{
      'mdc-drawer--collapsed': collapsed || collapseDesktop,
      'mdc-drawer--collapsed-open': collapseOpen,
      'mdc-drawer--touch': touchSupport || collapseDesktop,
      'mdc-drawer--collapsable': !modal && !dismissible
    }"
    (click)="collapseOpen = collapsed ? !collapseOpen : false; $event.stopPropagation()"
  >
    <div class="mdc-drawer__content">
      <span (click)="$event.stopPropagation()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </span>
      <ng-container
        *ngIf="touchSupport && !modal && !dismissible && collapsed && !allowCollapseDesktop"
      >
        <span class="mdc-drawer--button">
          <button
            mdcIconButton
            [icon]="!collapseOpen ? 'menu' : 'menu_open'"
            (click)="collapseOpen = !collapseOpen; $event.stopPropagation()"
          ></button>
        </span>
      </ng-container>

      <ng-container *ngIf="allowCollapseDesktop">
        <span class="mdc-drawer--button">
          <button
            small
            outlined
            mdcIconButton
            [icon]="!collapseDesktop ? 'radio_button_unchecked' : 'radio_button_checked'"
            (click)="
              collapseDesktop = !collapseDesktop;
              $event.stopPropagation();
              desktopCollapseChange.emit(collapseDesktop)
            "
          ></button>
        </span>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-template #nonCollapse>
  <div class="mdc-drawer__content">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<div
  class="mdc-drawer--scrim"
  [ngClass]="{
    'mdc-drawer--scrim-open': collapseOpen
  }"
  (click)="collapseOpen = false; $event.stopPropagation()"
  *ngIf="collapsed"
></div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MdcCheckbox } from './checkbox';

@NgModule({
  imports: [CommonModule],
  exports: [MdcCheckbox],
  declarations: [MdcCheckbox]
})
export class MdcCheckboxModule {}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {
  MDCSnackbar,
  MDCSnackbarCloseEventDetail,
  MDCSnackbarFoundation
} from '@material/snackbar';
import { merge } from 'lodash';

@Component({
  selector: 'mdc-snackbar',
  templateUrl: 'snackbar.html',
  styleUrls: ['./snackbar.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LiveAnnouncer]
})
export class MdcSnackbarComponent implements AfterViewInit, OnDestroy {
  private _mdcSnackbar!: MDCSnackbar;

  @HostBinding('class') class = 'mdc-snackbar';

  // #region MDCSnackbar properties
  public get root(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  public get isOpen(): boolean {
    return this._mdcSnackbar.isOpen;
  }
  //#endregion

  private _appRoot: HTMLElement = document.querySelector('app-root') as HTMLElement;

  public afterOpening: EventEmitter<void> = new EventEmitter();
  public afterOpened: EventEmitter<void> = new EventEmitter();
  public afterClosing: EventEmitter<string> = new EventEmitter();
  public afterClosed: EventEmitter<string> = new EventEmitter();

  public get config(): ISnackbarConfig {
    return this._config;
  }

  public set config(value: ISnackbarConfig) {
    this._config = merge({}, this._config, value);
  }

  private _config: ISnackbarConfig = {
    action: '',
    actions: [],
    message: '',
    timeoutMs: 5000,
    stacked: false,
    leading: false,
    trailing: false,
    direction: 'ltr',
    dismiss: false,
    closeOnEscape: false,
    customAction: false,
    mobilePaddingAllowed: true
  };

  constructor(public elementRef: ElementRef<HTMLElement>, private _cdRef: ChangeDetectorRef) {}

  //#region Lifecycle
  ngAfterViewInit(): void {
    if (this.config.stacked) this.root.classList.add('mdc-snackbar--stacked');
    if (this.config.leading) this.root.classList.add('mdc-snackbar--leading');
    if (this.config.trailing) this.root.classList.add('cd-layout-snackbar--trailing');
    this.root.setAttribute('dir', this.config.direction);

    if (!this._appRoot.classList.contains('fullscreen-open')) {
      const isAndroid = /\b(Android)\b/i.test(window.navigator.userAgent);
      const iosDevice = /\b(iPhone)\b/i.test(window.navigator.userAgent);
      const isMobile =
        document.documentElement.clientHeight < 480 || document.documentElement.clientWidth < 480
          ? true
          : false;

      if (isAndroid && isMobile && this.config.mobilePaddingAllowed)
        this.root.classList.add('cd-position-mobile');
      if (iosDevice && isMobile && this.config.mobilePaddingAllowed)
        this.root.classList.add('cd-position-mobile-ios');
    }
    this._mdcSnackbar = MDCSnackbar.attachTo(this.root);

    if (this.config.timeoutMs !== 5000) this._mdcSnackbar.timeoutMs = this.config.timeoutMs;
    if (this.config.closeOnEscape) this._mdcSnackbar.closeOnEscape = this.config.closeOnEscape;

    this._mdcSnackbar.listen('MDCSnackbar:opening', () => {
      this.afterOpening.emit();
    });
    this._mdcSnackbar.listen('MDCSnackbar:opened', () => {
      this.afterOpened.emit();
    });
    this._mdcSnackbar.listen(
      'MDCSnackbar:closing',
      (reason: CustomEvent<MDCSnackbarCloseEventDetail>) => {
        this.afterClosing.emit(reason.detail.reason);
      }
    );
    this._mdcSnackbar.listen(
      'MDCSnackbar:closed',
      (reason: CustomEvent<MDCSnackbarCloseEventDetail>) => {
        this.afterClosed.emit(reason.detail.reason);
      }
    );
    this.open();
  }
  //#endregion

  //#region MDCSnackbar methodes

  public onActionClick(evt: MouseEvent): void {
    this.getDefaultFoundation().handleActionButtonClick(evt);
  }

  public onActionIconClick(evt: MouseEvent): void {
    this.getDefaultFoundation().handleActionIconClick(evt);
  }

  ngOnDestroy(): void {
    this._mdcSnackbar?.destroy();
  }

  getDefaultFoundation(): MDCSnackbarFoundation {
    return this._mdcSnackbar.getDefaultFoundation();
  }

  public open(): void {
    this._mdcSnackbar?.open();
  }

  public close(reason?: string): void {
    this._mdcSnackbar?.close(reason);
  }
  //#endregion
}

export interface ISnackbarConfig {
  action: string;
  actions: ISnackbarAction[];
  message: string;
  timeoutMs: number;
  stacked: boolean;
  leading: boolean;
  trailing: boolean;
  direction: string;
  dismiss: boolean;
  closeOnEscape: boolean;
  customAction: boolean;
  mobilePaddingAllowed: boolean;
  headline?: string;
  link?: { label: string; router: string };
}

interface ISnackbarAction {
  label: string;
  action: () => void;
}

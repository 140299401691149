<input
  type="checkbox"
  class="mdc-checkbox__native-control"
  [id]="inputId"
  [disabled]="disabled"
  [checked]="checked"
  [attr.value]="value"
  [attr.data-indeterminate]="indeterminate"
  (change)="onInteraction($event)"
/>
<div class="mdc-checkbox__background">
  <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
    <path class="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59" />
  </svg>
  <div class="mdc-checkbox__mixedmark"></div>
</div>
<div class="mdc-checkbox__ripple"></div>

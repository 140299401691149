import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'li[mdcListDivider], li[mdc-list-divider]',
  template: '',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MdcListDivider {
  @HostBinding('class') class = 'mdc-deprecated-list-divider';

  @HostBinding('attr.role') role = 'separator';

  @HostBinding('class.mdc-deprecated-list-divider--inset-leading')
  @Input()
  get insetLeading(): boolean {
    return this._insetLeading;
  }
  set insetLeading(value: boolean) {
    this._insetLeading = coerceBooleanProperty(value);
  }
  private _insetLeading = false;

  @HostBinding('class.mdc-deprecated-list-divider--inset-trailing')
  @Input()
  get insetTrailing(): boolean {
    return this._insetTrailing;
  }
  set insetTrailing(value: boolean) {
    this._insetTrailing = coerceBooleanProperty(value);
  }
  private _insetTrailing = false;

  @Input()
  get insetPadding(): boolean {
    return this._insetPadding;
  }
  set insetPadding(value: boolean) {
    this._insetPadding = coerceBooleanProperty(value);
  }
  private _insetPadding = false;

  @HostBinding('class.mdc-deprecated-list-divider--inset')
  @Input()
  get inset(): boolean {
    return this._inset;
  }
  set inset(value: boolean) {
    this._inset = coerceBooleanProperty(value);
  }
  private _inset = false;

  @HostBinding('class.mdc-deprecated-list-divider--padded')
  @Input()
  get padded(): boolean {
    return this._padded;
  }
  set padded(value: boolean) {
    this._padded = coerceBooleanProperty(value);
  }
  private _padded = false;

  constructor(public elementRef: ElementRef) {}
}
